import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { RiDragMove2Line } from 'react-icons/ri';

const DragAndDrop = function( { cb, data } ){
  
  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) =>{
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(
      data,
      result.source.index,
      result.destination.index
    );
    return cb(items);
  };

  return <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId="droppable">
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {data.map((item, index) => {

            return <Draggable key={item.id} draggableId={`item-${item.id}`} index={index}>
              {(provided, snapshot) => (
                <div className={`draggable`}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <div className="draggable-inline-col draggable-icon">
                    <RiDragMove2Line />
                  </div>
                  <div className="draggable-inline-col draggable-index">
                    { index + 1 }
                  </div>
                  <div className="draggable-inline-col draggable-description">
                    { item.description }
                  </div>
                </div>
              )}
            </Draggable>
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </DragDropContext>
};

export default DragAndDrop;