import React, { useState } from 'react';
// LIBRARIES
import PropTypes from 'prop-types';
// COMPONENTS
import DialogTemplate from '../../../Dialog/DialogTemplate';
import DragAndDropComponent from './DragAndDropComponent';

const Feedback = function({modalOpen, setModalOpen, handleModalAction}){

    function handleModalSave(){
      handleModalAction({type : 'ranking-survey', title : 'Feature Ranking', data : ranking });
      setModalOpen(false);
    }

    const featuresData = [
      {id:1, description: 'Viewable Hero profiles - see how others are spending points and their activities'}, 
      {id:2, description: 'New hero titles and color tints based on Elemental power' },
      {id:3, description: 'New awards and titles for reaching weekly goals based on fitness activities'},
      {id:4, description: 'In game messaging system'},
      {id:5, description: 'Improved descriptions of how attributes, battles, and upgrading works'},
      {id:6, description: 'Team formation within HeroFit with a unique leaderboard and cooperative battles against foes.'},
      {id:7, description: 'Account page to make changes to your profile'}
    ];
    const [ranking, setRanking] = useState(featuresData);
    const [rankingHasChanged, setRankingHasChanged] = useState(false);
  
    function handleNewOrder(items){
      setRanking(items);
      setRankingHasChanged(true);
    };

  return  <div>

    <DialogTemplate 
      type={'marketing'}
      identifier={'feature-request'}
      title={'Which Features Would You Like To See?'}
      text={'Rank by most wanted at the top, drag and drop to reorder.'}
      content={<DragAndDropComponent cb={handleNewOrder} data={ranking} />} 
      cancelButton={true} 
      confirmButton="Submit" 
      isOpen={modalOpen} 
      handleSave={handleModalSave} 
      handleClose={() => setModalOpen(false)} 
      disableButton={!rankingHasChanged}
      disableBackdropClick={false} 
    /> 

  </div>

}

export default Feedback;

Feedback.propTypes = {
  modalOpen : PropTypes.bool.isRequired,
  setModalOpen : PropTypes.func.isRequired,
  handleModalAction : PropTypes.func.isRequired,
};