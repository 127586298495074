import React, { useState, useEffect } from 'react';
// LIBRARIES
import PropTypes from 'prop-types';
// COMPONENTS
import DialogTemplate from '../../../Dialog/DialogTemplate';
import { BsStarFill } from 'react-icons/bs';
import './BattleReport.scss';


const BattleReportDetail = function({ modalOpen, setModalOpen, battleReport }){
  const [scenario, setScenario] = useState(null);
  const [scenarioDescription, setScenarioDescription] = useState(null);
  const [isOldReport, setIsOldReport] = useState(false);

  function renderFoePrep(){
    return(
      <p>
        <strong>{battleReport.brf.ability ? battleReport.brf.ability.name : 'No ability'}</strong><br />
        <span>{battleReport.brf.ability ? battleReport.brf.ability.effect : null}</span>
      </p>
    )
  }

  function renderRoundRows(){

    function determineHealthRemaining(round, party){
      const evaded = round.elementalProcs?.air?.evaded;
      const waterHealAmount = round.elementalProcs?.water?.amountHealed || 0;
      const thornsDamageAmount = round.elementalProcs?.earth?.thornsDamageToAttacker || 0;
      const healedMoreThanThorns = waterHealAmount > thornsDamageAmount ? true : waterHealAmount < thornsDamageAmount ? false : null;
      if(party === 'aggressor'){
        if(round.aggressorHealthLeft === undefined){
          return 'unavailable';
        }else if(healedMoreThanThorns === true && !evaded){
          return <strong className="water-highlight">{Math.floor(round.aggressorHealthLeft)}</strong>
        }else if(healedMoreThanThorns === false && !evaded){
          return <strong className="earth-highlight">{Math.floor(round.aggressorHealthLeft)}</strong>
        }

        return Math.floor(round.aggressorHealthLeft);
      }else{
        if(round.defenderHealthLeft === undefined){
          return 'unavailable';
        }

        return Math.floor(round.defenderHealthLeft);
      }
    }

    function formatValues(val, isBold){
      if(val === 0){
        return null;
      }else if(isBold){
        return <strong style={ { fontSize: '1.1em' } }>+{val}</strong>;
      }
      return `+${val}`;
    }

    function renderRoundDamage(round){
      if(isOldReport){
        return 'unavailable';
      }

      if(round.elementalProcs?.air?.evaded){
        return <span><em>Missed!</em></span>
      }

      let fireCritHappened = false;
      if(round.elementalProcs?.fire?.critDamage > 0){
        fireCritHappened = true;
      }

      return <div>
        <span className="physical-damage">{round.physicalDamageDealt}</span>
        <span className="elemental-damage fire-highlight">{formatValues(round.elementalDamageDealt?.fire || 0, fireCritHappened)}</span>
        <span className="elemental-damage earth-highlight">{formatValues(round.elementalDamageDealt?.earth || 0)}</span>
        <span className="elemental-damage water-highlight">{formatValues(round.elementalDamageDealt?.water || 0)}</span>
        <span className="elemental-damage air-highlight">{formatValues(round.elementalDamageDealt?.air || 0)}</span>
      </div>
    }

    function renderRoundReduction(round){
      if(isOldReport){
        return 'unavailable';
      }
      if(round.elementalProcs?.air?.evaded){
        return <span><em className="air-highlight">Evaded</em></span>
      }

      return <div>
        <span className="physical-damage">{round.physicalReduction || 0}</span>
        <span className="elemental-damage fire-highlight">{formatValues(round.elementalReduction?.fire || 0)}</span>
        <span className="elemental-damage earth-highlight">{formatValues(round.elementalReduction?.earth || 0)}</span>
        <span className="elemental-damage water-highlight">{formatValues(round.elementalReduction?.water || 0)}</span>
        <span className="elemental-damage air-highlight">{formatValues(round.elementalReduction?.air || 0)}</span>
      </div>
    }

    if(battleReport.roundBreakdown){

      return battleReport.roundBreakdown.map((round, i) =>{ 

        return (
          round.aggressor === 'Avatar' ?
            <tr key={i}>
              <td className="background-highlight"><strong>{round.turn + 1}</strong> - Hero</td>
              {/* AVATAR DAMAGE */}
              <td className="background-highlight">
                { renderRoundDamage(round) }
              </td>
              {/* FOE REDUCTION */}
              <td>
                { renderRoundReduction(round) }
              </td>
              <td className="health-remaining-td">
                <div className="health-remaining-container">
                  <span className="background-highlight">
                    { determineHealthRemaining(round, 'aggressor') }
                  </span> 
                  <span>
                    { determineHealthRemaining(round, 'defender') }
                  </span>
                </div>
              </td>
            </tr>
          :
            <tr key={i}>
              <td className=""><strong>{round.turn + 1}</strong> - Foe</td>
              {/* FOE DAMAGE */}
              <td>
                { renderRoundDamage(round) }
              </td>
              {/* AVATAR REDUCTION */}
              <td className="background-highlight">
                { renderRoundReduction(round) }
              </td>
              <td className="health-remaining-td">
    
              <div className="health-remaining-container">
                  <span className="background-highlight">
                    { determineHealthRemaining(round, 'defender') }
                  </span> 
                  <span>
                    { determineHealthRemaining(round, 'aggressor') }
                  </span>
                </div>
              </td>
            </tr>
        )
      });
    }else{
      return null;
    }

  }

  function renderBattleEffects(){
    const unknownIfProc = ['Frisky Mischief', 'Eight-Legged Ingenuity', 'Lethal Surprise', 'Bad Luck', 'Ill Omen'];
    
    const effects = battleReport.effects?.map((effect, i) => {
      const unknown = unknownIfProc.includes(effect);
      if(unknown){
        return <tr key={i}>
          <td colSpan="5" className="muted"><em>{ effect } (chance)</em></td>
        </tr>
      }
      return <tr key={i}><td colSpan="5">{ effect }</td></tr>;
    });
    return effects ? effects : null;
  }


  useEffect(() =>{
    let sType;
    let sDescription;

    switch(battleReport.scenario){
      case 0:
        sType = 'Hero attacked unprepared foe';
        sDescription = "Bonus power to hero, health reduction to foe.";
      break;
      case 1:
        sType = 'Hero attacked defending foe';
        sDescription = "Bonus health to foe.";
      break;
      case 2:
        sType = 'Hero and Foe met on open field';
        sDescription = "Slight power bonus to hero & foe.";
      break;
      case 3:
        sType = 'Foe attacked unprepared hero';
        sDescription = "Bonus power to foe, health reduction to Hero.";
      break;
      case 4:
        sType = 'Foe attacked defending hero';
        sDescription = "Bonus health to Hero";
      break;
      case 5:
        sType = 'Both hero and foe fought defensively';
        sDescription = "Slight bonus health to hero & foe.";
      break;
      default:
      break;
    }

    setScenario(sType);
    setScenarioDescription(sDescription);

   
  }, [battleReport]);

  useEffect(() =>{
    if(!battleReport.roundBreakdown || battleReport?.roundBreakdown[0].damageDealt){
      return setIsOldReport(true);
    }
    return setIsOldReport(false);
  }, [battleReport]);


  const content = <div>
    <div id="bdRow1">
      <div id="bdScenario">
        <div className="bd-section">
          <BsStarFill />
          <div>
            <strong className="title">Scenario</strong>
            <p>
              <strong>{scenario}</strong><br />
              <span>{scenarioDescription}</span>
            </p>
          </div>
        </div>
      </div>
      <div id="bdBattlePrep">
        <div className="bd-section">
          <BsStarFill />
          <div>
            <strong className="title">Foe Battle Prep</strong>
            {renderFoePrep()}
          </div>
        </div>
      </div>
    </div>
    <div id="bdRow2">
      <div id="bdRoundByROund">
        <div className="bd-section">
          <BsStarFill />
          <div>
            <strong className="title">Base & Battle-Time Stats</strong>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th colSpan="2">{ battleReport.avatar.name }</th>
                  <th colSpan="2">{ battleReport.foe.name }</th>
                </tr>
                <tr>
                  <th></th>
                  <th>Base</th>
                  <th className="background-highlight">Battle</th>
                  <th className="background-highlight">Battle</th>
                  <th>Base</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><strong>Power</strong></td>
                  <td>{ battleReport.avatar.power }</td>
                  <td className="background-highlight">{ Math.floor(battleReport.bra.power) }</td>
                  <td className="background-highlight">{ Math.floor(battleReport.brf.power) }</td>
                  <td>{ battleReport.foe.power }</td>
                </tr>
                <tr>
                  <td><strong>Health</strong></td>
                  <td>{ battleReport.avatar.health }</td>
                  <td className="background-highlight">{ Math.floor(battleReport.bra.health) }</td>
                  <td className="background-highlight">{ Math.floor(battleReport.brf.health) }</td>
                  <td>{ battleReport.foe.health }</td>
                </tr>
                <tr>
                  <td><strong>Armor</strong></td>
                  <td>{ battleReport.avatar.armor }</td>
                  <td className="background-highlight">{ battleReport.bra.armor }</td>
                  <td className="background-highlight">{ battleReport.brf.armor }</td>
                  <td>{ battleReport.foe.armor }</td>
                </tr>
                <tr className={ battleReport.seasonalBonusElement?.element === 'Fire' ? 'fire-highlight' : '' }>
                  <td><strong>Fire</strong></td>
                  <td>{ Math.floor(battleReport.avatar.fire) }</td>
                  <td className="background-highlight">{ Math.floor(battleReport.bra.fire) }</td>
                  <td className="background-highlight">{ Math.floor(battleReport.brf.fire) }</td>
                  <td>{ Math.floor(battleReport.foe.fire) }</td>
                </tr>
                <tr className={ battleReport.seasonalBonusElement?.element === 'Earth' ? 'earth-highlight' : '' }>
                  <td><strong>Earth</strong></td>
                  <td>{ Math.floor(battleReport.avatar.earth) }</td>
                  <td className="background-highlight">{ Math.floor(battleReport.bra.earth) }</td>
                  <td className="background-highlight">{ Math.floor(battleReport.brf.earth) }</td>
                  <td>{ Math.floor(battleReport.foe.earth) }</td>
                </tr>
                <tr className={ battleReport.seasonalBonusElement?.element === 'Water' ? 'water-highlight' : '' }>
                  <td><strong>Water</strong></td>
                  <td>{ Math.floor(battleReport.avatar.water) }</td>
                  <td className="background-highlight">{ Math.floor(battleReport.bra.water) }</td>
                  <td className="background-highlight">{ Math.floor(battleReport.brf.water) }</td>
                  <td>{ Math.floor(battleReport.foe.water) }</td>
                </tr>
                <tr className={ battleReport.seasonalBonusElement?.element === 'Air' ? 'air-highlight' : '' }>
                  <td><strong>Air</strong></td>
                  <td>{ Math.floor(battleReport.avatar.air) }</td>
                  <td className="background-highlight">{ Math.floor(battleReport.bra.air) }</td>
                  <td className="background-highlight">{ Math.floor(battleReport.brf.air) }</td>
                  <td>{ Math.floor(battleReport.foe.air) }</td>
                </tr>
                {
                  battleReport.seasonalBonusElement ? 
                  <tr>
                    <td colSpan="5" className={ battleReport.seasonalBonusElement.element === 'Air' ? 'air-highlight' : battleReport.seasonalBonusElement.element === 'Water' ?  'water-highlight' : battleReport.seasonalBonusElement.element === 'Earth' ? 'earth-highlight' : battleReport.seasonalBonusElement.element === 'Fire' ? 'fire-highlight' : '' }>
                        * 25% Boost to { battleReport.seasonalBonusElement.element } for { battleReport.seasonalBonusElement.sign } Season
                    </td>
                    
                  </tr>
                  : null
                }
                { renderBattleEffects() }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div id="bdRow3">
      <div id="bdStats">
        <div className="bd-section">
          <BsStarFill />
          <div>
            <strong className="title">Round - By - Round</strong>
            <table>
              <thead>
                <tr>
                  <th>
                    Round<br />
                    <div className="th-subtext">attacker</div>
                  </th>
                  <th>
                    Damage Dealt < br/>
                    <div className="th-subtext">by attacker</div>
                  </th>
                  <th>
                    Damage Prevented<br />
                    <div className="th-subtext">by defender</div>
                  </th>
                  <th>
                    Health Remaining<br />
                    <div id="heroFoeSubtext"  className="th-subtext"><span>Hero</span> / <span>Foe</span></div>
                  </th>
                </tr>
              </thead>
              <tbody>
                { renderRoundRows() }
                <tr>
                  <td colSpan="4">
                  <div>*<span>PHYSICAL</span> * <span className="fire-highlight" >FIRE</span> * <span className="earth-highlight">EARTH</span> * <span className="water-highlight">WATER</span> * <span className="air-highlight">AIR</span></div>
                  </td>
                </tr>

                <tr>
                  <td colSpan="4">
                    <div>*<strong className="fire-highlight" >CRIT</strong> * <strong className="earth-highlight">Thorns</strong> * <strong className="water-highlight">Vampiric Touch</strong> * <em className="air-highlight">Evasion</em></div>
                  </td>
                </tr>

                <tr>
                  <td colSpan="4">
                    <div>* In the event of <strong className="earth-highlight">Thorns</strong> & <strong className="water-highlight">Vampiric Touch</strong>, both are applied but the greater of the two  is highlighted</div>
                  </td>
                </tr>
              </tbody>

            </table>
          </div>
        </div>
    </div>
    </div>
  </div>

  return  <div>

    <DialogTemplate 
      type={'headless'}
      identifier={`battle-report-detail ${battleReport.outcome === 'Avatar Wins' ? 'battle-report-win' : 'battle-report-loss'}`}
      title={null}
      content={content} 
      cancelButton={true} 
      isOpen={modalOpen} 
      handleClose={() => setModalOpen(false)} 
      disableBackdropClick={false} 
    /> 

  </div>

}

export default BattleReportDetail;

BattleReportDetail.propTypes = {
  modalOpen : PropTypes.bool.isRequired,
  setModalOpen : PropTypes.func.isRequired,
  battleReport : PropTypes.object.isRequired
};