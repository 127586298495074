import React, { useState } from 'react';
// LIBRARIES
import PropTypes from 'prop-types';
// COMPONENTS
import DialogTemplate from '../../Dialog/DialogTemplate';
import { Link } from 'react-router-dom';
import { MdLinearScale as PageBreak } from 'react-icons/md';
import { FaSkull } from 'react-icons/fa';
import { GiPillDrop } from 'react-icons/gi';
import { BsExclamationDiamondFill } from 'react-icons/bs';

const GoToBattle = function({ modalOpen, setModalOpen, handleModalAction, avatarName, avatarRecovery, avatarRested = true, avatarRestedTarget, isLoading, verifiedAccount }){
  let goToBattleOwlAdvice, goToBattleActionText, goToBattleText;

  if(!verifiedAccount){
    goToBattleOwlAdvice = `Hooo! You may think you're ready, but you must do something for me first!`;
    goToBattleActionText = <div><BsExclamationDiamondFill  style={ { fontSize:'1.5em', color: '#ff6700' }} /> <strong>Action Required!    Verify your account.</strong></div>;
    goToBattleText = <div id="battleModalContent">
      <p>Before you can go to battle, you must verify your account by clicking the link sent to your email.</p>
    </div>
  }else if(avatarRested){
    goToBattleOwlAdvice = `The Dark Forces are ruthless adversaries with powerful abilities... attacking the mind and flesh! But I've trained you well, go get em!`;
    goToBattleActionText = <div><FaSkull  style={ { fontSize:'1.2em', color: 'black' }} /> <strong>Battles happen automatically at 2 AM MST <br />(for Europeans, that's 10 am CET)</strong></div>;
    goToBattleText = <div id="battleModalContent">Earn XP depending on the outcome. Losing will 'Knock Out' <strong className="brand-highlight">{avatarName}</strong> and force a night off.</div>;
  }else{
    goToBattleOwlAdvice = `You are in no condition to battle the Dark Forces. Give your wounds time to heal, my young student.`;
    goToBattleActionText = <div><BsExclamationDiamondFill  style={ { fontSize:'1.5em', color: '#356735' }} /> <strong>Health is too low!</strong></div>;
    goToBattleText = <div id="battleModalContent">{ avatarName } must have at least <strong className="brand-highlight">{avatarRestedTarget} Health</strong> (80% recovered) before going to go to battle. Your current recovery rate is <strong className="brand-highlight">{avatarRecovery}</strong> health per hour. Heal faster with more Quantum Points in 'Recovery', or <Link to={'/inventory'}>Buy a Health Potion</Link>.</div>;
  }

  function handleModalSave(){
    handleModalAction();
  }

  const content = <div>
    <div className="page-break"><PageBreak /></div>
    <div className="action-to-take">
      { goToBattleActionText }
    </div>
    {
      verifiedAccount ? <div className="more-content">
        { goToBattleText }
        <Link to="/battles">Learn more about battles</Link>
      </div>
      : <div className="more-content">{ goToBattleText }</div>
        
    }


  </div>

  return  <div>

    <DialogTemplate 
      type={'speech'}
      identifier={'go-to-battle'}
      character={'owl'} 
      title={null}
      text={goToBattleOwlAdvice}
      content={content} 
      cancelButton={true} 
      confirmButton="Go To Battle" 
      isOpen={modalOpen} 
      handleSave={handleModalSave} 
      handleClose={() => setModalOpen(false)} 
      disableButton={avatarRested && verifiedAccount ? false : true} 
      disableBackdropClick={false} 
      isLoading={isLoading}
      closeOnSave={false}
    /> 

  </div>

}

export default GoToBattle;

GoToBattle.propTypes = {
  modalOpen : PropTypes.bool.isRequired,
  setModalOpen : PropTypes.func.isRequired,
  handleModalAction : PropTypes.func.isRequired,
  avatarName : PropTypes.string.isRequired,
  avatarRecovery : PropTypes.number.isRequired,
  avatarRested : PropTypes.bool,
  verifiedAccount : PropTypes.bool.isRequired
};