import React, { useState, useEffect } from 'react';
// Libraries
import PropTypes from 'prop-types';
// COMPONENTS
import CircularProgress from '@material-ui/core/CircularProgress';
// STYLES
import './LoadingOverlay.scss';

const LoadingOverlay = function({loading, loggedIn}){
  const messages = ['powering up...', 'one last squat...', 'power walking to the server...','climbing another hill...', '"only five more miles"...', 'communing with the elements...'];
  const [ message, setMessage ] = useState('powering up...');


  // A loading message that cycles through the messages array above. Only used for initial load.
  useEffect(() => {
    if(!loggedIn){
        const messageInt = setInterval(() =>{
          messages.push(messages.shift());
          setMessage(messages[0]);
        },3000);
        return () => clearInterval(messageInt);
    }
  }, []);



  return(
    <div id="loadingOverlay" className={loading ? '' : 'hide'}>
      <div id="loadingIndicatorContainer">
        <div id="loadingIndicatorWrapper">
          <CircularProgress style={{width:'100%', height: '100%'}} />
        </div>
        <div id="loadingMessageContainer">
          { message }
        </div>
      </div>
    </div>
  )
}

export default LoadingOverlay;

LoadingOverlay.propTypes = {
  loading : PropTypes.bool.isRequired,
  loggedIn : PropTypes.bool.isRequired
}
