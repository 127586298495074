import React from 'react';
// LIBRARIES
import PropTypes from 'prop-types';
// COMMON
import history from '../../../common/history';
// COMPONENTS
import DialogTemplate from '../../Dialog/DialogTemplate';
import { MdLinearScale as PageBreak } from 'react-icons/md';
import { TiArrowRightOutline as ArrowRight } from 'react-icons/ti';
import { Link } from '@material-ui/core';

const TheHerosPath = function({modalOpen, setModalOpen, avatarName}){
  // No Save, just close modal
  function handleModalSave(){
    setModalOpen(false);
  }

  // Link for About Page
  function navigateToAboutPage(e){
    e.preventDefault();
    setModalOpen(false);
    setTimeout(() =>{
      history.push('/about');
    },250);
  }

  const content = <div>
    <div className="page-break"><PageBreak /></div>
    <div className="action-to-take">
      <ArrowRight /> <strong id="secondaryMessage">Perform heroic activities such as running, riding, or walking and use them to empower <span className="brand-highlight">{avatarName}</span>!</strong>
    </div>
    <div className="more-content">
      <section id="onboardSteps">
        <div>
          <strong className="cta-initiation-title brand-highlight">The Hero's Path</strong><br />
          <ol>
            <li>Do a fitness activity and record it with Strava</li>
            <li>Log into HeroFit to apply your training to your Hero</li>
            <li>Send your hero to battle today & check the outcome the following morning</li>
          </ol>
        </div>
      </section>
      <strong id="linkToAbout">See how your <Link onClick={ (e) =>{navigateToAboutPage(e)} }>heroic activities empower {avatarName}.</Link></strong>
    </div>
  </div>

  return  <div>

    <DialogTemplate 
      type={'speech'}
      identifier={'the-heros-path'}
      character={'owl'} 
      title={null}
      text={`The Hero's Path is not for the faint of heart, my lil droppling. Now go train! And don't forget to track your progress.`}
      content={content} 
      cancelButton={true} 
      secondaryButton="OK" 
      isOpen={modalOpen} 
      handleSave={handleModalSave} 
      handleClose={() => setModalOpen(false)} 
      disableButton={false} 
      disableBackdropClick={false} 
    /> 

  </div>

}

export default TheHerosPath;

TheHerosPath.propTypes = {
  modalOpen : PropTypes.bool.isRequired,
  setModalOpen : PropTypes.func.isRequired,
  avatarName : PropTypes.string.isRequired,
};