import React from 'react';
// LIBRARIES
import PropTypes from 'prop-types';
// COMPONENTS
import DialogTemplate from '../../Dialog/DialogTemplate';
import { IoMdCheckmarkCircleOutline as Check } from 'react-icons/io';
import { MdLinearScale as PageBreak } from 'react-icons/md';
import { TiArrowRightOutline as ArrowRight } from 'react-icons/ti';

const CreateAccount = function({modalOpen, setModalOpen, handleModalAction}){

    function handleModalSave(){
      handleModalAction();
      setModalOpen(false);
    }


  const content = <div>
    <div className="page-break"><PageBreak /></div>
    <div className="action-to-take">
      <ArrowRight /> <strong>Sign up for a FREE HeroFit account to begin training under Master Sensei Owl!</strong>
    </div>

    <section id="onboardSteps">
      <div>
        <strong className="cta-initiation-title brand-highlight">The Hero's Initiation</strong><br />
        <ol>
          <li className="muted"><span className="checked"><Check /></span>Choose your Hero</li>
          <li className="bold"><strong>Create a HeroFit Account</strong></li>
          <li>Connect/Create your Strava Account</li>
        </ol>
      </div>
    </section>
  </div>;

  return  <div>

    <DialogTemplate 
      type={'speech'}
      identifier={'create-account'}
      character={'owl'} 
      title={null}
      text="What's this? a new new student? hmmm... I'll consider it. Tell me about yourself, drifter." 
      content={content} 
      cancelButton={true} 
      confirmButton="Sign Up" 
      isOpen={modalOpen} 
      handleSave={handleModalSave} 
      handleClose={() => setModalOpen(false)} 
      disableButton={false} 
      disableBackdropClick={false} 
    /> 

  </div>

}

export default CreateAccount;

CreateAccount.propTypes = {
  modalOpen : PropTypes.bool.isRequired,
  setModalOpen : PropTypes.func.isRequired,
  handleModalAction : PropTypes.func.isRequired,
};