import { fetchAllDefaultItems } from '../services/inventoryService';
import { debugErrors } from './errorHandler';
import { getLsWithExpiry, getUrlParamsAfterHash, convertItemIdsToFullItems } from './helperFunctions';
import { validateAdmin } from '../services/mockService';
import history from './history';

function cookieConfirmPrompt(updateState){
  const cookiesAllowed = localStorage.getItem('herofit-cookiePermission');
  if(!cookiesAllowed){
    updateState({ alerts : [{ type : 'warning', message : "HeroFit uses cookies and similar technology for accessing and playing the game. By continuing, you consent to HeroFit persisting data on your device.", persist : true, identifier:"Cookie Permission", confirm : "AGREE" }] });
  }
}

// Checks if there is a valid local JWT, returns it or null;
function checkLocalJwt(updateState,admin){
  let jwtToken = getLsWithExpiry('herofit-jwt');
  if(!jwtToken){
    debugErrors("No JWT while trying to access private route", admin);
    updateState({ appIsLoading : false });
    return history.push('/welcome');
  }
  updateState({ admin : Object.assign({}, admin, { jwtToken }) });
  return jwtToken;
}

// Returns the Strava code if there is one along with the route to eventually send users to
function stravaFirstTimeUsers(updateState, pathname){
  // Get URL params to tell if user is being redirected from Strava
  const { scope, code } = getUrlParamsAfterHash();
  const permissionReadAll = scope?.includes(',activity:read_all');
  if(scope){
    if(!permissionReadAll){
      debugErrors("User connected Strava, but unchecked 'View data about your private activities'");
      updateState({appIsLoading : false, alerts : [{type : 'error', message :`HeroFit needs access to your Strava private activities, please try again.`}]});
    }
    updateState({ stravaAuthCode : code });
  }
  
  const navigateTo = code ? '/' : pathname;
  return { code, navigateTo };
}

// Attaches the key binding to open the admin panel
function adminPanelBinding(updateState, jwt){
  let keysPressed = {};
  document.addEventListener('keydown', (event) => {
    keysPressed[event.key] = true;
    if (event.ctrlKey && event.key === '2') {
        if(process.env.REACT_APP_HOST_ENV === 'development'){
          return updateState({ adminPanelOpen : true });
        }else{
          keysPressed = {};
          let password = prompt('Enter Admin Password');
          
          validateAdmin({ password }, jwt)
          .then((data) =>{
            if(data.error){
              if(data.error.status === 403){
                return debugErrors(data.error);
              }
              return updateState({ alerts : [{ type : 'warning', message : 'Incorrect Password' }] });
            }
            return updateState({ adminPanelOpen : true });
          });
        }
    }
  });
  
  document.addEventListener('keyup', (event) => {
    delete keysPressed[event.key];
  });
}

function fetchAllGameItems(updateState){
  // Fetch all default game items, to be used elsewhere in application
  // For public routes, needed for Profile page & Ranking page
  return fetchAllDefaultItems(null)
  .then((data) =>{
    if(data.error){
      return debugErrors(data.error);
    }
    const { items } = data;
    return updateState({ defaultItems : items }, () =>{});
  });
}

function equipItemsOnAvatar(avatar, items, updateState){
  // Combine item instances with default items
  avatar.equipped = convertItemIdsToFullItems(avatar.equipped, items)
  return updateState({ avatar });
}
    

export { cookieConfirmPrompt, checkLocalJwt, stravaFirstTimeUsers, adminPanelBinding, fetchAllGameItems, equipItemsOnAvatar };