import _axios from 'axios';
import axiosRetry from 'axios-retry';
import { axiosOptions } from '../common/axiosDefaults';
import { handleHttpError } from '../common/errorHandler';
const endpoint = process.env.REACT_APP_HF_ENDPOINT;

let axios = _axios.create();
axiosRetry(axios, {retries: 2, retryDelay: (retryCount) => retryCount * 1000});

const goToBattle = function(body){
  return axios.post(`${endpoint}battle/insert-battle`, body, axiosOptions())
  .then(({ data }) => {
    return data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  })
};

const fetchBattleReport = function(body){
  return axios.post(`${endpoint}battle/fetch-battle-report`, body, axiosOptions())
  .then(({ data }) => {
    return data.data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};

const fetchBattleReportById = function(body){
  return axios.post(`${endpoint}battle/fetch-battle-report-by-id`, body, axiosOptions())
  .then(({ data }) => {
    return data.data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};

const updateBattleReportSeen = function(body){
  return axios.post(`${endpoint}battle/update-battle-report-seen`, body, axiosOptions())
  .then(({ data }) => {
    return data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};

const getVillainList = function(){
  return axios.get(`${endpoint}battle/villain-list`, axiosOptions())
  .then(({ data }) => {
    return data.data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};

const fetchBattlesWonOrDkoByAvatarID = function(body){
  return axios.post(`${endpoint}battle/fetch-battles-won-or-dko`, body, axiosOptions())
  .then(({ data }) => {
    return data.data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};

const fetchUpcomingFoeAndRewards = function(body){
  return axios.post(`${endpoint}battle/fetch-upcoming-foe-and-rewards`, body , axiosOptions())
  .then(({ data }) => {
    return data.data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};

const runSpecificBattle = function(body){
  return axios.post(`${endpoint}battle/run-specific-battle`, body, axiosOptions())
  .then(({ data }) => {
    return data.data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
}


export { goToBattle, fetchBattleReport, updateBattleReportSeen, getVillainList, fetchBattlesWonOrDkoByAvatarID, fetchUpcomingFoeAndRewards, fetchBattleReportById, runSpecificBattle };