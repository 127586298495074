import React from 'react';
// LIBRARIES
import PropTypes from 'prop-types';
// COMPONENTS
import { BsCheckCircle } from 'react-icons/bs';
// STYLES
import './ModalOverlay.scss';

const Success = function({ message }){

  function renderMessage(){
    if(message){
      return (
        <div id="successModalOverlayMessage">
          <strong>{ message }</strong>
        </div>
      )
    }
    return null;
  }

  return(
    <div id="successModalOverlay" className="modal-overlay fade-in">
      <div id="successModalContainer">
        <div id="successModalIconWrapper">
          <BsCheckCircle style={{width:'100%', height: '100%'}} />
        </div>
        { renderMessage() }
      </div>
    </div>
  )
}

export default Success;

Success.propTypes = {
  message : PropTypes.string
}