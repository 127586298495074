import React, { useState, useEffect } from 'react';
// LIBRARIES
import PropTypes from 'prop-types';
// COMPONENTS
import DialogTemplate from '../../../Dialog/DialogTemplate';
import './BattleReport.scss';

// COMMON
import HEROES from '../../../../common/includedHeroImages';
import VILLAINS from '../../../../common/includedVillainImages';
import { lowercaseUnderscore, determineFoeClass, rankingSuffix, determineSkinType, determineSkinName } from '../../../../common/helperFunctions';


const BattleReport = function({ modalOpen, setModalOpen, handleModalAction, battleReport, avatarEquip = null, handleItemClick, defaultItems }){

  const [ winner, setWinner ] = useState(null);
  const [ loser, setLoser ] = useState(null);
  const [ avatarClass, setAvatarClass ] = useState('');
  const [ foeClass, setFoeClass ] = useState('');
  
  function handleModalSave(){
    handleModalAction();
    setModalOpen(false);
  }

  function determineImage(type){
    const avatar = battleReport.avatar;
    const avatarSkin = avatar.equipped?.find(item => item.type === 'skin');
    
    // Return avatar
    if(type === avatar.character){
      return HEROES[determineSkinType(avatarSkin)][determineSkinName(avatarSkin, avatar.character)];

    // Return shadow self
    }else if(type === 'Shadow-Self'){
      return VILLAINS.spirits['shadow_'+lowercaseUnderscore(avatar.character)];

    // return any other foe
    }else if(type){
      return VILLAINS[determineFoeClass(type.toLowerCase())][lowercaseUnderscore(type)];
    }
    return null;
  }

  function renderHeadline(){
    if(winner && loser){
      if(battleReport.outcome === 'Draw'){
        return <div id="title">
          <div className="tie"> { winner.name } </div>
          <div id="outcome">- draw -</div>
          <div className="tie"> { loser.name } </div>
        </div>
  
      }else if(battleReport.outcome === 'Double KO'){
        return <div id="title">
          <div className="tie"> { winner.name } </div>
          <div id="outcome">- double KO -</div>
          <div className="tie"> { loser.name } </div>
        </div>
      }
  
      return <div id="title">
        <div id="winner"> { winner.name } </div>
        <div id="outcome">- defeats -</div>
        <div id="loser"> { loser.name } </div>
      </div>
    }else{
      return null;
    }
  }

  function renderBattleResult(){
 
      if(battleReport.outcome === "Foe Wins" || battleReport.outcome === "Avatar Wins" ){
        return <span id="outcomeStatement">{battleReport?.roundBreakdown.length}{rankingSuffix(battleReport.roundBreakdown.length)} Round KO</span>
      }else if(battleReport.outcome === "Draw"){
        return <span id="outcomeStatement">Draw after 12 Rounds</span>
      }else if(battleReport.outcome === "Double KO"){
        if(battleReport.roundBreakdown){
          return <span id="outcomeStatement">Both Hero & Foe KOed - Round {battleReport.roundBreakdown.length}</span>
        }
        return <span id="outcomeStatement">Both Hero & Foe KOed</span>
      }
  
 
  }

  function renderWinnerImage(){
    if(winner){
      return <img id="winnerImage"  className={`contender-image   ${battleReport.outcome === 'Foe Wins' ? foeClass : avatarClass} `} src={determineImage(winner.type)} alt="Winner display" />
    }
    return null;
  }

  function renderLoserImage(){
    if(loser){
      return <img id="loserImage" className={`contender-image  ${battleReport.outcome !== 'Foe Wins' ? foeClass : avatarClass} `} src={determineImage(loser.type)} alt="loser" />
    }
    return null;
  }

  function renderItemsAcquired(){
    const items = battleReport.itemsAcquired
    if(!items){
      return null;
    }

    return <div>
        { items.map((item, i) =>{
          item = defaultItems?.find((defaultItem) => defaultItem.name === item.name);
        return <span key={i} className="battle-gain battle-gain-item">NEW {item.type === 'skin' ? 'COSTUME' : item.type.toUpperCase()}:&nbsp;&nbsp;<strong onClick={() => handleItemClick(item)}>{item.name}</strong></span>
      }) }
    </div>

  }

  function renderSpecialStatus(){
    const status = battleReport.aStatus;
    if(status !== 'Knocked Out' || status !== 'Recovering'){
      if(status === 'Infected'){
        // Hard Coded 24hrs... too difficult to get actual value
        return <span className="post-battle-status">Status:&nbsp;&nbsp;<strong>{status} - 24 hrs</strong></span>
      }
      return null;
    }
    return null;
  }



  useEffect(() =>{
    const avatar = battleReport.avatar;
    avatar.type = battleReport.avatar.character;
    
    let avatarSkinClass =  '';
    if(avatarEquip){
      avatarSkinClass = avatarEquip?.find(item => item.type === 'skin')?.class;
    }
    const foe = battleReport.foe;

    switch(battleReport.outcome){
      case 'Avatar Wins':
        setWinner(avatar);
        setLoser(foe);

        let aClasses = `${avatarSkinClass} contender-image-recovering`;
        if(battleReport.aStatus === 'Infected'){
          aClasses += ' infected';
        }
        setAvatarClass(aClasses);
        break;
      case 'Foe Wins':
        setWinner(foe);
        setLoser(avatar);
        setAvatarClass(`${avatarSkinClass} contender-image-knocked-out`);
        break;
      case 'Draw':
        setWinner(avatar);
        setLoser(foe);
        setAvatarClass(`${avatarSkinClass} contender-image-recovering`);
        break;
      case 'Double KO':
        setWinner(avatar);
        setLoser(foe);
        setAvatarClass(`${avatarSkinClass} contender-image-knocked-out`);
        break;
      default:
        break;
    }

    setFoeClass(`contender-image-${foe.type} contender-image-${determineFoeClass(foe.type.toLowerCase())}`);
    
  }, [battleReport]);

  const content = <div id="contentSection">
    { renderWinnerImage() }
    { renderHeadline() }
    { renderBattleResult() }
    { renderLoserImage() }
    <div id="loot">
      <span className="battle-gain">XP Gained:&nbsp;&nbsp;<strong>{battleReport.xpGain}</strong></span>
      {
        battleReport.ptGain > 0 ? <span className="battle-gain">PT Earned:&nbsp;&nbsp;<strong>{battleReport.ptGain}</strong></span> : null
      }
      { renderItemsAcquired() }
      { renderSpecialStatus() }
    </div>
  </div>

  return  <div>

    <DialogTemplate 
      type={'headless'}
      identifier={`battle-report ${battleReport.outcome === 'Avatar Wins' ? 'battle-report-win' : 'battle-report-loss'}`}
      title={null}
      content={content} 
      cancelButton={true} 
      confirmButton="Details" 
      isOpen={modalOpen} 
      handleSave={handleModalSave} 
      handleClose={() => setModalOpen(false)} 
      disableBackdropClick={false} 
    /> 

  </div>

}

export default BattleReport;

BattleReport.propTypes = {
  modalOpen : PropTypes.bool.isRequired,
  setModalOpen : PropTypes.func.isRequired,
  handleModalAction : PropTypes.func.isRequired,
  battleReport : PropTypes.object.isRequired,
  handleItemClick : PropTypes.func.isRequired
};