import React from 'react';
// COMPONENTS
import Hexagon from '../../Hexagon/Hexagon';
import { Fire, Earth, Water, Air, Power, Health } from '../../../common/icons';
import { Run, Crossfit, Stairs, Yoga, Walk, Elliptical, Swim, Rowing, Kayaking, StandUpPaddling, Ride, Weights, Hike, Ski, Snowboard, Snowshoe, Workout, LevelUp  } from '../../../common/icons';
import { BsStopwatchFill } from 'react-icons/bs';
// STYLES
import './InfoAboutActivities.scss';


const InfoAboutActivities = function({name}){
  return(
    <div className="info-about-activities">
      {/* FIRE */}
      <div className="row-title row-title-fire fire-highlight"><strong>FIRE Activities</strong><span> - Critical Strike</span></div>
      <div className="about-row">
        
        <div className="about-activity-wrapper">
          <Run /> Running <br />
          <Crossfit /> Crossfit <br />
          <Stairs /> Stair Stepper <br />
        </div>
        <div className="about-description-wrapper">
          <BsStopwatchFill /> <strong className="highlight">20</strong><span className="highlight">min</span>
        </div>
        <div className="about-hex-wrapper">
          <Hexagon diameter={75} color={"#e25822"} border={3} borderColor="#333533" icon={ <Fire /> } iconStyles={{ color: '#e8eddf', size: '3em' }} text={'+1'} textStyles={{color : '#e8eddf', fontSize: '1em', fontWeight: 'bold' }} />
        </div>
      </div>
      {/* EARTH */}
      <div className="row-title row-title-earth earth-highlight"><strong>EARTH Activities</strong><span> - Thorns Damage</span></div>
      <div className="about-row">
        <div className="about-activity-wrapper">
          <Ride /> Biking <br />
          <Weights /> Weight Lifitng <br />
          <Hike /> Hiking <br />
        </div>
        <div className="about-description-wrapper">
          <BsStopwatchFill /> <strong>30</strong><span>min</span>
        </div>
        <div className="about-hex-wrapper">
          <Hexagon diameter={75} color={"#8A360F"} border={3} borderColor="#333533" icon={ <Earth /> } iconStyles={{ color: '#e8eddf', size: '2.4em' }} text={'+1'} textStyles={{color : '#e8eddf', fontSize: '1em', fontWeight: 'bold' }}  modifier={{ text:'3px' }} />
        </div>
      </div>
      {/* WATER */}
      <div className="row-title row-title-water water-highlight"><strong>WATER Activities</strong><span> - Vampiric Touch</span></div>
      <div className="about-row">
        <div className="about-activity-wrapper">
          <Rowing /> Rowing <br />
          <Kayaking /> Kayaking <br />
          <StandUpPaddling /> Paddleboarding <br />
          <Ski /> Skiing <br />
          <Snowboard /> Snowboarding <br />
          <Snowshoe /> Snowshoeing <br />
        </div>
        <div className="about-description-wrapper">
          <BsStopwatchFill /> <strong>30</strong><span>min</span>
        </div>
        <div className="about-hex-wrapper">
          <Hexagon diameter={75} color={"#0f5e9c"} border={3} borderColor="#333533" icon={ <Water /> } iconStyles={{ color: '#e8eddf', size: '3em' }} text={'+1'} textStyles={{color : '#e8eddf', fontSize: '1em', fontWeight: 'bold' }}  modifier={{ icon:'-10px', text: '5px' }} />
        </div>
      </div>
      <div className="about-row about-row-no-border">
        <div className="about-activity-wrapper">
          <Swim /> Swimmimg <br />
        </div>
        <div className="about-description-wrapper">
          <BsStopwatchFill /> <strong className="highlight">20</strong><span className="highlight">min</span>
        </div>
        <div className="about-hex-wrapper">
        
        </div>
      </div>
      {/* AIR */}
      <div className="row-title row-title-air air-highlight"><strong>AIR Activities</strong><span> - Evasion</span></div>
      <div className="about-row">
        <div className="about-activity-wrapper">
          <Yoga /> Yoga <br />
          <Walk /> Walking <br />
        </div>
        <div className="about-description-wrapper">
          <BsStopwatchFill /> <strong>30</strong><span>min</span>
        </div>
        <div className="about-hex-wrapper">
          <Hexagon diameter={75} color={"#16a0f5"} border={3} borderColor="#333533" icon={ <Air /> } iconStyles={{ color: '#e8eddf', size: '2.5em' }} text={'+1'} textStyles={{color : '#e8eddf', fontSize: '1em', fontWeight: 'bold' }}  modifier={{ text:'5px' }} />
        </div>
      </div>
      <div className="about-row about-row-no-border">
        <div className="about-activity-wrapper">
          <Elliptical /> Elliptical <br />
        </div>
        <div className="about-description-wrapper">
          <BsStopwatchFill /> <strong className="highlight">20</strong><span className="highlight">min</span>
        </div>
        <div className="about-hex-wrapper">
          
        </div>
      </div>
      {/* OTHER */}
      <div className="row-title row-title-other"><strong>OTHER Activities</strong></div>
      <div className="about-row">
        <div className="about-activity-wrapper">
          <Workout /> Any other workout activity <br />
        </div>
        <div className="about-description-wrapper">
          <BsStopwatchFill /> <strong>30</strong><span>min</span>
        </div>
        <div className="about-hex-wrapper">
          <Hexagon diameter={45} color={"#e25822"} border={3} borderColor="#333533" icon={ <Fire /> } iconStyles={{ color: '#e8eddf', size: '1.5em' }} text={'+.25'} textStyles={{color : '#e8eddf', fontSize: '.9em', fontWeight: 'bold' }}  modifier={{ text:'-1px' }} />
          <Hexagon diameter={45} color={"#8A360F"} border={3} borderColor="#333533" icon={ <Earth /> } iconStyles={{ color: '#e8eddf', size: '1.5em' }} text={'+.25'} textStyles={{color : '#e8eddf', fontSize: '.9em', fontWeight: 'bold' }}  modifier={{ text:'-1px' }} />
          <Hexagon diameter={45} color={"#0f5e9c"} border={3} borderColor="#333533" icon={ <Water /> } iconStyles={{ color: '#e8eddf', size: '1.5em' }} text={'+.25'} textStyles={{color : '#e8eddf', fontSize: '.9em', fontWeight: 'bold' }}  modifier={{ text:'-1px' }} />
          <Hexagon diameter={45} color={"#16a0f5"} border={3} borderColor="#333533" icon={ <Air /> } iconStyles={{ color: '#e8eddf', size: '1.5em' }} text={'+.25'} textStyles={{color : '#e8eddf', fontSize: '.9em', fontWeight: 'bold' }}  modifier={{ text:'-1px' }} />
        </div>
      </div>

      {/* ON Level Up */}
      <div className="row-title row-title-other"><strong>On Level Up</strong></div>
      <div  className="about-row about-row-level-up">
        <div className="about-activity-wrapper">
          Workout, Gain XP, Level Up
        </div>
        <div className="about-description-wrapper about-description-level-up">
          <LevelUp /> <a href="https://www.desmos.com/calculator/pyw7io8ukq">See Level Curve</a>
        </div>
        <div className="about-hex-wrapper">
          <Hexagon diameter={75} color={"#cfdbd5"} border={3} borderColor="#333533" icon={ <Power /> } iconStyles={{ color: '#333533', size: '3em' }} text={'+5'} textStyles={{color : '#333533', fontSize: '1em', fontWeight: 'bold' }}  modifier={{ icon:'-10px' }} />  
          <Hexagon diameter={75} color={"#cfdbd5"} border={3} borderColor="#333533" icon={ <Health /> } iconStyles={{ color: '#333533', size: '3em' }} text={'+5'} textStyles={{color : '#333533', fontSize: '1em', fontWeight: 'bold' }}  modifier={{ icon:'-10px' }} />  

        </div>
      </div>
    </div>
  )
};

export default InfoAboutActivities;