import { generate } from 'rand-token';
import avatars from './allyList.json';

const lowercaseUnderscore = function(ally){
    if(ally !== null && typeof ally !== 'undefined'){
        return ally.replace(/\s+/g, '_').toLowerCase();
    }
};
const lowercaseDash = function(ally){
    if(ally !== null && typeof ally !== 'undefined'){
        return ally.replace(/\s+/g, '-').toLowerCase();
    }
};
const lowercaseSpace = function(ally){
    if(ally !== null && typeof ally !== 'undefined'){
        return ally.replace(/-|_/g, ' ').toLowerCase();
    }
};
const titlecaseUnderscore = function(ally){
    if(ally !== null && typeof ally !== 'undefined'){
        ally = ally[0].toUpperCase() + ally.substring(1);
        return ally.replace(/\s+/g, '_')
    }
}

function capitalize(val) {
  if (typeof val === "string") {
    return val.charAt(0).toUpperCase() + val.slice(1);
  }
  throw new Error(`${val} is not a String; can't transform`);
}

const shuffleArray = function(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
  }

const getUrlParamsAfterHash = function(){
let qs;  
if(window.location.href.indexOf('#') !== -1){
    qs = window.location.hash.replace(/[#]/g, "?");
}else{
    qs = window.location.href.split('+').join(' ');
}

var params = {},
    tokens,
    re = /[?&]?([^=]+)=([^&]*)/g;

while (tokens = re.exec(qs)) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
}

return params;

};
const getUrlParams = function(){
  var params = new URLSearchParams(window.location.search);
  const urlParams = {};
    for (let [key, value] of params.entries()) {
      if(value === 'true'){
        value = true;
      }else if(value === 'false'){
        value = false;
      }
      urlParams[key] = value;
    }
    return urlParams;
}

function getCookie(name) {
const value = `; ${document.cookie}`;
const parts = value.split(`; ${name}=`);
if (parts.length === 2) return parts.pop().split(';').shift();
}

function setLsWithExpiry(key, value, ttl) {
const now = new Date()
// `item` is an object which contains the original value
// as well as the time when it's supposed to expire
const item = {
    value: value,
    expiry: now.getTime() + ttl
}
localStorage.setItem(key, JSON.stringify(item))
}

function getLsWithExpiry(key) {
    const itemStr = localStorage.getItem(key)

    // if the item doesn't exist, return null
    if (!itemStr) {
        return null
    }

    const item = JSON.parse(itemStr)
    const now = new Date()

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
    }
    return item.value
}

function thousandsFormat(num){
  if(!num){
    return 0;
  }

  var num_parts = num.toString().split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num_parts.join(".");
}

function roundNumbersTenth(num){
    if(num < 1){
        return (Math.floor(10*num)/10).toString().replace(/^0+/, "");
    }
    return (Math.floor(10*num)/10).toString();
}
function roundNumbersHundreth(num){
    if(num < 1){
        return (Math.floor(100*num)/100).toString().replace(/^0+/, "");
    }
    return (Math.floor(100*num)/100).toString();
}

function cloneObj(obj, deep=false){
    var result = {};
    for(let key in obj){
      if(deep && obj[key] instanceof Object){
         if(obj[key] instanceof Array){
           result[key] = [];
           obj[key].forEach(function(item){
              if(item instanceof Object){
                 result[key].push(cloneObj(item, true));
              } else {
                 result[key].push(item);
              }
           });
         } else {
           result[key] = cloneObj(obj[key]);
         }
      } else {
         result[key] = obj[key];
      }
    }
    return result
}

// If the route is longer than just one directory, check only the first string for the public/private route check
// eg - /users/myusername -> only check /users
function _uniqueUrlCheck(path){
    if(path.split('/').length > 2){
        return '/' + path.split('/')[1];
    }
    return path;
}

function isPrivateRoute(privateRoutes, path){
    path = _uniqueUrlCheck(path);
    return privateRoutes.find(route => route === path) !== undefined;
}

function isPublicRoute(publicRoutes, path){
    path = _uniqueUrlCheck(path);
    return publicRoutes.find(route => route === path) !== undefined;
}

function alertAdder(current, newAlerts){
    newAlerts = Array.isArray(newAlerts) ? newAlerts : [newAlerts];
    const fadeOutAlerts = [];
    
    const arrayWithIndexes = newAlerts.map((alert, i) => {
        const token = generate(16);
        let mappedAlert = { type : alert.type, message : alert.message, confirm : alert.confirm, identifier : alert.identifier, index : token }
        if(alert.link){
          mappedAlert.link = alert.link;
        }
        if(alert.persist){
            mappedAlert.persist = true;
        }else{
            mappedAlert.persist = false;
            fadeOutAlerts.push(token);
        }
        if(alert.cb){
          mappedAlert.cb = alert.cb;
        }

      return mappedAlert;
    });

    return {newAlertArray : [...current, ...arrayWithIndexes], fadeOutAlerts };
  }

  function alertRemover(current, indiciesForRemoval){
    return current.filter((alert) => {
      return !indiciesForRemoval.includes(alert.index);
    });
  }

  function getAvatarAlias(character){
        const alias = avatars.find((av) => av.character === character).alias;
        return alias;
  }
  function determineFoeClass(type){
    const foeClasses = {
        spirits : ['wraith', 'specter', 'apparition','banshee','poltergeist','phantasm','shade','phantom', 'shadow-self'],
        elementals : ['gusty rascal', 'rock skipper', 'flame fiend', 'splash artist', 'wheezing jinn', 'granite golem', 'burning jinn', 'cyclonic siren', 'storming oni', 'hulking aggro crag', 'scorching archfiend', 'high priestess of the tides'],
        titans : ['plaguebringer', 'guardian of the depths']
      }
      let parentClass = null;
      for(let targetClass in foeClasses ){
        const containedInClass = foeClasses[targetClass].includes(type);
        if(containedInClass){
            parentClass = targetClass;
          break;
        }
      }
      return parentClass;
  }

  function rankingSuffix(num){
    const numStr = num.toString();
    const lastChar = numStr.charAt(numStr.length-1);
    const elevenAndTwelve = [11,111,1111,11111,12,112,1112,11112, 13];
    const matchesEorT = elevenAndTwelve.includes(num);
    if(matchesEorT){
      return 'th';
    }else if(lastChar === '1'){
      return 'st';
    }else if(lastChar === '2'){
      return 'nd';
    }else if(lastChar === '3'){
      return 'rd';
    }else{
      return 'th';
    }
  }

  function determineSkinType(skin){
    switch(skin?.name){
      case 'Shadow Self':
        return 'shadow';
      case 'Ascended Self':
        return 'ascended';
      case 'Fire Brand':
        return 'fire_brand';
      case 'Earth Shaker':
        return 'earth_shaker';
      case 'Tide Caller':
        return 'tide_caller';
      case 'Gale Force':
        return 'gale_force';
      default: 
        return 'base';
    }
  }

  function determineSkinName(skin, characterName){
    switch(skin?.name){
      case 'Shadow Self':
        return "shadow_" + lowercaseUnderscore(characterName);
      case 'Ascended Self':
        return "ascended_" + lowercaseUnderscore(characterName);
      case 'Fire Brand':
        return 'fire_brand_' + lowercaseUnderscore(characterName);
      case 'Earth Shaker':
        return 'earth_shaker_' + lowercaseUnderscore(characterName);
      case 'Tide Caller':
        return 'tide_caller_' + lowercaseUnderscore(characterName);
      case 'Gale Force':
        return 'gale_force_' + lowercaseUnderscore(characterName);
      default: 
        return lowercaseUnderscore(characterName);
    }
  }

  function convertItemArrayToCategories(items){

    const defaultItems = {
      skins : items.filter(item => item.type === 'skin'),
      pets : items.filter(item => item.type === 'pet'),
      titles : items.filter(item => item.type === 'title'),
      consumables : items.filter(item => item.type === 'consumable'),
      codices : items.filter(item => item.type === 'codex'),
    };

    return defaultItems;
  }


  function convertItemIdsToFullItems(itemIds, defaultItems){

    const allAvatarItems = itemIds.map((itemInstance) => {
      const matchingItem = defaultItems.find(item => item.id === itemInstance.itemID);
      return Object.assign({}, itemInstance, matchingItem);
    });

    return allAvatarItems;
  }

  function convertAorAn(nextWord){
    const vowels = ['a','e','i','o','u'];
    const firstLetterIsVowel = vowels.includes(nextWord[0].toLowerCase());
    return firstLetterIsVowel === true ? 'an' : 'a';
  }


export { lowercaseUnderscore, lowercaseDash, lowercaseSpace, titlecaseUnderscore, capitalize, shuffleArray, getUrlParams, getUrlParamsAfterHash, getLsWithExpiry, setLsWithExpiry, thousandsFormat, roundNumbersTenth, roundNumbersHundreth, cloneObj, isPrivateRoute, isPublicRoute, alertAdder, alertRemover, getAvatarAlias, determineFoeClass, rankingSuffix, determineSkinType, determineSkinName, convertItemArrayToCategories, convertItemIdsToFullItems,convertAorAn };