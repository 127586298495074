import _axios from 'axios';
import axiosRetry from 'axios-retry';
import { axiosOptions, axiosDeleteConfig } from '../common/axiosDefaults';
import { handleHttpError } from '../common/errorHandler';
const endpoint = process.env.REACT_APP_HF_ENDPOINT;

let axios = _axios.create();
axiosRetry(axios, {retries: 3});

const validateAdmin = function(body){
  return axios.post(`${endpoint}mock-data/check-admin-password`, body, axiosOptions())
  .then(({ data }) =>{
    return data;
  }).catch(({ request, response }) => {
    // Need to return here. If async is needed, need to wrap it in a new Promise
    // client received an error response (5xx, 4xx, or something from bad code before request)
    return handleHttpError(request, response);
  });
};

const createRandomAvatar = function(body){
  return axios.post(`${endpoint}mock-data/insert-random-avatar`, body, axiosOptions())
  .then(({ data }) =>{
    return data.data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};

const createRandomActivities = function(body){
  return axios.post(`${endpoint}mock-data/insert-mock-activities`, body, axiosOptions())
  .then(({ data }) =>{
    return data;
  }).catch(({ request, response }) => {
    // client received an error response (5xx, 4xx, or something from bad code before request)
    return handleHttpError(request, response);
  })
};

const getFakeActivities = function(body){
  return axios.post(`${endpoint}mock-data/get-fake-activities`, body, axiosOptions())
  .then(({ data }) =>{
      return data;
  }).catch(({ request, response }) => {
    // client received an error response (5xx, 4xx, or something from bad code before request)
    return handleHttpError(request, response);
  });
};

// RUNS ALL BATTLES QUED UP
const runAllBattles = function(){
  return axios.get(`${endpoint}mock-cron/run-all-battles`, axiosOptions())
  .then(({ data }) =>{
    return data.data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};


const setAvatarHealthAndStatus = function(body){
  return axios.post(`${endpoint}mock-data/set-avatar-health-and-status`, body, axiosOptions())
  .then(({ data }) =>{
    const message = data.data;
    return message;
  }).catch(({ request, response  }) => {
    // Need to return here. If async is needed, need to wrap it in a new Promise
    // client received an error response (5xx, 4xx, or something from bad code before request)
    return handleHttpError(request, response);
  });
};

const hourlyAvatarUpdate = function(){
  return axios.get(`${endpoint}mock-cron/regenerate-health`, axiosOptions())
  .then(({ data }) =>{
    return data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};

const tryRecovery = function(){
  return axios.get(`${endpoint}mock-cron/try-recovery`, axiosOptions())
  .then(({ data }) =>{
      return data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};

const generateFoe = function(body){
  return axios.post(`${endpoint}mock-cron/generate-foe`, body, axiosOptions())
  .then(({ data }) =>{
      return data.data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};

const determinePBAs = function(body){
  return axios.post(`${endpoint}mock-cron/mock-determine-pbas`, body, axiosOptions())
  .then(({ data }) =>{
      return data.data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};

const runPBAsForBattleById = function(){
  return axios.get(`${endpoint}mock-cron/mock-run-pbas`, axiosOptions())
  .then(({ data }) =>{
      return data.data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};

const adminToggleFeedback = function(body){
  return axios.post(`${endpoint}mock-data/toggle-feedback-boolean`, body, axiosOptions())
  .then(({ data }) => {
    return data;
  }).catch(({ request, response }) => {console.log(request, response);
    return handleHttpError(request, response);
  });
}

const deleteOneAccount = function(body){
  return axios.delete(`${endpoint}mock-data/${body.email}`, axiosDeleteConfig(body))
  .then(({ data }) => {
    return data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
}



export { validateAdmin, createRandomAvatar, createRandomActivities, getFakeActivities, runAllBattles, setAvatarHealthAndStatus, hourlyAvatarUpdate, tryRecovery, generateFoe, determinePBAs, runPBAsForBattleById, adminToggleFeedback, deleteOneAccount };