import React from 'react';
// COMPONENTS
import Header from '../Header/Header';
import { Button } from '@material-ui/core';
// STYLES
import './NotFoundPage.scss';

const NotFoundPage = function(){
  return(
    <div id="notFoundPage">
      <Header title={process.env.REACT_APP_NAME} subtitle={'A Fitness Game'} menuButton={false} />
      <div>
        <h2>404 - PAGE NOT FOUND</h2>
        <Button href="/welcome"  variant="contained" color="primary" >Return to Login</Button>
      </div>
    </div>
  )
}

export default NotFoundPage;