import React, { useEffect } from 'react';
// COMMON
import history from '../../common/history';
import { getUrlParams, getUrlParamsAfterHash } from '../../common/helperFunctions';
import { sendVerificationCode } from "../../services/authService";
import { debugErrors } from "../../common/errorHandler";
// STYLES
import './OAuthPage.scss';

const ENV = process.env.REACT_APP_HOST_ENV;

/*
 EVERYTHING IN THIS FILE EFFECTS THE MOBILE APP ONLY!
*/


const OAuthPage = function ({ updateState }) {
  const { verify, verifyPassword } = getUrlParams(history.location);

  // EMAIL SIGNUP VERIFICATION
  useEffect(() => {
    let redirectTimeout;
    if (verify) {
      (async () => {
        // Sends verification code back to server the redirects to app
        try {
          await sendVerificationCode({ emailCode: verify })

          updateState({ alerts: [{ type: 'success', message: 'Email Has been verified, you will now be returned to the HeroFit app!' }] });
          redirectTimeout = setTimeout(() => {
            if (ENV === "development" || ENV === "staging") {
              //window.location.replace(`exp://192.168.1.10:19000/--/?register=true`)
              window.location.replace(`exp://172.30.200.89:19000/--/?register=true`)
            } else if (ENV === "production") {
              //PROD:
              window.location.replace(`herofit://?register=true`)
            } else { throw new Error("No Matching Environment!"); }
          }, 4000);
        } catch (error) {
          updateState({ alerts: [{ type: 'error', message: error.message }] });
          return debugErrors(error);
        }

      })();
    }
    return () => clearTimeout(redirectTimeout);
  }, [verify]);


  // EMAIL VERIFICATION FROM PASSWORD RESET

  // VERIFY PASSWORD
  // When this url param comes in, we open part 2 of forgot password modal which allows them to reset their password
  useEffect(() => {
    let redirectTimeout;
    if (verifyPassword) {
      console.log('in verify password hook!');
      (async () => {
        // Sends verification code back to server the redirects to app
        try {
          updateState({ appIsLoading: false, alerts: [{ type: 'success', message: 'Email Has been verified, you will now be returned to the HeroFit app!' }] });
          redirectTimeout = setTimeout(() => {
            //DEV: - redirect to expo app
            if (ENV === "development" || ENV === "staging") {
              window.location.replace(`exp://192.168.1.10:19000/--/?verifyPassword=${verifyPassword}`)
              //window.location.replace(`exp://172.30.200.89:19000/--/?verifyPassword=${verifyPassword}`)
            } else if (ENV === "production") {
              //PROD:
              window.location.replace(`herofit://?verifyPassword=${verifyPassword}`)
            } else { throw new Error("No Matching Environment!"); }
          }, 4000);
        } catch (error) {
          updateState({ alerts: [{ type: 'error', message: error.message }] });
          return debugErrors(error);
        }
      })();
    }
    return () => clearTimeout(redirectTimeout);
  }, [verifyPassword]);


  return (
    <div id="oauthPage" className="page">
      <div id="oauthPageContent">
        <section id="titleContainer">
          <h1>{process.env.REACT_APP_NAME}</h1>
          <h2>The Fitness Tracking Game!!</h2>
        </section>
        <section id="successContainer">

          <div className="success-content">
            <div>
              <h3>Successfully verified Email!</h3>
            </div>
          </div>

        </section>

      </div>
      <div id="oauthPageOverlay"></div>
    </div>
  )
};

export default OAuthPage;

OAuthPage.propTypes = {

};