import React from 'react';
// COMPONENTS
import Header from '../Header/Header';
import InfoAboutBattles from './InfoAboutBattles/InfoAboutBattles';

const BattlesPage = function({ MainMenuButton }){
  return(
    <section id="battlesPage" className="page">
      <Header title={`Battles`} subtitle={"How the Daily Battles Work"} MainMenuButton={MainMenuButton} />
      <InfoAboutBattles />
      <div className="about-segment">
        <em>A special thanks to SuperPoekie for this technical desciption of the HeroFit battle system.</em>
        <br /><br />
        <span>Stay up-to-date with all the latest HeroFit news on <a href="https://reddit.com/r/herofit">our subreddit.</a></span>
      </div>
      <div className="about-segment">
        <div className="segment-content">
          <div id="elementalMatchups">
            <h2>Round by Round, Element vs Element</h2>
            <p>
              The Elemental Damage calculation has been around for a while, but hasn't been described... until now! It's a bit involved and has nothing to do with the new traits. The gist of it is each of the Heroe's individual elements are calculated against all of the foe's elements, averaged, then reduced by the enemies mirror element. 
            </p>
            <ol>
              <li>The initial calculation vs the foes elements goes through one of three functions: "Mirror" (Water vs Water), "Advantage Over" (For water, it would be Fire and Earth), and "Weak Against" (For water, it would be Air).</li>
              <li>Repeat the previous step for each of the heroe's elements</li>
              <li>Average the totals for each element, so for instance, water damage should have 4 damage values at this points. Take the average.</li>
              <li>Each of the four averages are now reduced by one last function that takes the foe's mirror element and treats it just like the armor value is used to reduce physical damage. For instance, if you are very water heavy, and so is the foe, this last function will reduce ALL of the elemental damge your hero will do by a sizeable amount.</li>
              <li>As of patch 1.4.0, the final values will be displayed on the Battle Report within each round as PHYSICAL + FIRE + EARTH + WATER + AIR</li>
            </ol>
          </div>
          <div id="interactionTable">
            <table className="table">
              <thead>
                <tr>
                  <th colSpan="7"><strong>Element Interactions</strong><br /><span>Before Final Reduction calculation</span></th>
                </tr>
                <tr>
                  <th><strong>Element</strong></th>
                  <th><strong>Advantage Over</strong><br /><span className="muted">1.5 * damage</span></th>
                  <th><strong>Weak Against</strong><br /><span className="muted">.75 * damage</span></th>
                  <th><strong>Mirror</strong><br /><span className="muted">1.0 * damage</span></th>
                </tr>
              </thead>
              <tbody>
                <tr className="fire-highlight">
                  <td><strong>Fire</strong></td>
                  <td>Air & Water</td>
                  <td>Earth</td>
                  <td>Fire</td>
                </tr>
                <tr className="air-highlight">
                  <td><strong>Air</strong></td>
                  <td>Earth & Water</td>
                  <td>Fire</td>
                  <td>Air</td>
                </tr>
                <tr className="water-highlight">
                  <td><strong>Water</strong></td>
                  <td>Fire & Earth</td>
                  <td>Air</td>
                  <td>Water</td>
                </tr>
                <tr className="earth-highlight">
                  <td><strong>Earth</strong></td>
                  <td>Air & Fire</td>
                  <td>Water</td>
                  <td>Earth</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  )
};

export default BattlesPage;