import React from 'react';
// COMPONENTS
import Header from '../Header/Header';
// STYLES
import './LegalPages.scss';

const PrivacyPolicyPage = function({ MainMenuButton, updateState }){

  return(
    <section id="privacyPolicyPage" className="legal-page">
      
      <Header title={process.env.REACT_APP_NAME} subtitle={'Privacy Policy'} MainMenuButton={MainMenuButton} />
      <div id="legalPageContent">
      <h3 className="c9" id="h.p25ehqtfo7j4"><span className="c6">Overview</span></h3>
      <h2>WE WILL NOT SELL YOUR DATA.</h2>
      <p className="c4"><span className="c5">HeroFit</span><span className="c1">&nbsp;(we&rdquo; or &ldquo;us&rdquo;) values its visitors&rsquo; privacy. This privacy policy is effective 12-9-2020; it summarizes what information we might collect from a registered user or other visitor (&ldquo;you&rdquo;), and what we will and will not do with it.</span></p>
      <p className="c4"><span className="c5">Please note that this privacy policy does not govern the collection and use of information by companies that </span><span className="c5">HeroFit</span><span className="c5">&nbsp;does not control, nor by individuals not employed or managed by </span><span className="c5">HeroFit</span><span className="c1">. If you visit a Web site that we mention or link to, be sure to review its privacy policy before providing the site with information.</span></p>
      <h3 className="c9" id="h.mosv2p4qbkj5"><span className="c6">What we do with your personally identifiable information</span></h3>
      <p className="c4"><span className="c5">It is always up to you whether to disclose personally identifiable information to us, although if you elect not to do so, we reserve the right not to register you as a user or provide you with any products or services. &ldquo;</span><span className="c5 c13">Personally identifiable information</span><span className="c1">&rdquo; means information that can be used to identify you as an individual, such as, for example:</span></p>
      <ul className="c11 lst-kix_qdy4qi28ux0l-0 start">
         <li className="c3"><span className="c1">your name, company, email address, phone number, billing address, and shipping address</span></li>
         <li className="c3"><span className="c5">your </span><span className="c5">HeroFit</span><span className="c1">&nbsp;user ID and password</span></li>
      </ul>
      <ul className="c11 lst-kix_wulxow4bj35a-0 start">
         <li className="c3"><span className="c1">any account-preference information you provide us</span></li>
         <li className="c3"><span className="c1">your computer&rsquo;s domain name and IP address, indicating<br />where your computer is located on the Internet </span></li>
         <li className="c14"><span className="c1">session data for your login session, so that our computer can &lsquo;talk&rsquo; to yours while you are logged in</span></li>
      </ul>
      <p className="c4"><span className="c1">If you do provide personally identifiable information to us, either directly or through a reseller or other business partner, we will:</span></p>
      <ul className="c11 lst-kix_ecnnk6yrxu4s-0 start">
         <li className="c3"><span className="c1">not sell or rent it to a third party without your permission &mdash; although unless you opt out (see below), we may use your contact information to provide you with information we believe you need to know or may find useful, such as (for example) news about our services and products and modifications to the Terms of Service;</span></li>
         <li className="c3"><span className="c1">take commercially reasonable precautions to protect the information from loss, misuse and unauthorized access, disclosure, alteration and destruction;</span></li>
         <li className="c3"><span className="c1">not use or disclose the information except:</span></li>
      </ul>
      <ul className="c11 lst-kix_ecnnk6yrxu4s-1 start">
         <li className="c12"><span className="c1">as necessary to provide services or products you have ordered, such as (for example) by providing it to a carrier to deliver products you have ordered;</span></li>
         <li className="c12"><span className="c1">in other ways described in this privacy policy or to which you have otherwise consented;</span></li>
         <li className="c12"><span className="c1">in the aggregate with other information in such a way so that your identity cannot reasonably be determined (for example, statistical compilations);</span></li>
         <li className="c12"><span className="c1">as required by law, for example, in response to a subpoena or search warrant;</span></li>
         <li className="c12"><span className="c1">to outside auditors who have agreed to keep the information confidential;</span></li>
         <li className="c12"><span className="c1">as necessary to enforce the Terms of Service;</span></li>
         <li className="c15"><span className="c5">as necessary to protect the rights, safety, or property of </span><span className="c5">HeroFit</span><span className="c1">, its users, or others; this may include (for example) exchanging information with other organizations for fraud protection and/or risk reduction.</span></li>
      </ul>
      <p className="c4 c8"><span className="c1"></span></p>
      <h3 className="c9" id="h.n7uayih056y"><span className="c6">External data storage sites</span></h3>
      <p className="c4"><span className="c1">We may store your data on servers provided by third party hosting vendors with whom we have contracted.</span></p>
      <h3 className="c9" id="h.krd5dyt811hd"><span className="c6">Your privacy responsibilities</span></h3>
      <p className="c4"><span className="c1">To help protect your privacy, be sure:</span></p>
      <ul className="c11 lst-kix_39zok8aphhin-0 start">
         <li className="c3"><span className="c1">not to share your email or password with anyone else;</span></li>
         <li className="c3"><span className="c5">to log off the </span><span className="c5">HeroFit</span><span className="c1">&nbsp;Web site when you are finished;</span></li>
      </ul>
      <h3 className="c9" id="h.aba7qvfnkcho"><span className="c6">Notice to European Union users</span></h3>
      <p className="c4"><span className="c5">HeroFit</span><span className="c1">&lsquo;s operations are located primarily in the United States. If you provide information to us, the information will be transferred out of the European Union (EU) to the United States. By providing personal information to us, you are consenting to its storage and use as described herein.</span></p>
      <h3 className="c9" id="h.fbdy2us5bs2k"><span className="c6">Information collected from children</span></h3>
      <p className="c4"><span className="c5">You must be at least 13 years old to use </span><span className="c5">HeroFit</span><span className="c5">&lsquo;s websites and services. </span><span className="c5">HeroFit</span><span className="c5">&nbsp;does not knowingly collect information from children under 13. (See the </span><span className="c5">U.S.</span><span className="c1">&nbsp;Children&rsquo;s Online Privacy Protection Act.)</span></p>
      <h3 className="c9" id="h.h0hmq4fom1u3"><span className="c6">Changes to this privacy policy</span></h3>
      <p className="c4"><span className="c1">We reserve the right to change this privacy policy as we deem necessary or appropriate because of legal compliance requirements or changes in our business practices. If you have provided us with an email address, we will endeavor to notify you, by email to that address, of any material change to how we will use personally identifiable information.</span></p>
      <p className="c4 c8"><span className="c1"></span></p>
      <p className="c4 c8"><span className="c1"></span></p>
      <p className="c4 c8"><span className="c1"></span></p>
      <h2 className="c7" id="h.lk2wcydvb8gt"><span className="c0">A. Introduction</span></h2>
      <p className="c4"><span className="c1">The privacy of HeroFit&#39;s visitors is very important to us, and we are committed to safeguarding it. This policy explains what we will do with your personal information.</span></p>
      <p className="c4"><span className="c1">Consenting to our use of cookies in accordance with the terms of this policy when you first visit our website permits us to use cookies every time you visit our website.</span></p>
      <h2 className="c7" id="h.9ooxpzdfooqw"><span className="c0">B. Credit</span></h2>
      <p className="c4"><span className="c1">This document was created using a template from SEQ Legal (seqlegal.com)</span></p>
      <p className="c4"><span className="c5">and modified by Website Planet (</span><span className="c5"><a className="c10" href="https://www.google.com/url?q=https://www.websiteplanet.com/&amp;sa=D&amp;ust=1607550002839000&amp;usg=AOvVaw3f-qvTFv0kqTX1BdtTXfqs">www.websiteplanet.com</a></span><span className="c1">)</span></p>
      <h2 className="c7" id="h.q1uyr6rsm1ie"><span className="c0">C. Collecting personal information</span></h2>
      <p className="c4"><span className="c1">The following types of personal information may be collected, stored, and used:</span></p>
      <p className="c4"><span className="c1">information about your computer including your IP address, geographical location, browser type and version, and operating system;</span></p>
      <p className="c4"><span className="c1">information about your visits to and use of this website including the referral source, length of visit, page views, and website navigation paths;</span></p>
      <p className="c4"><span className="c1">information, such as your email address, that you enter when you register with HeroFit;</span></p>
      <p className="c4"><span className="c1">information that you enter when you create a profile on HeroFit&mdash;for example, your name, profile pictures, gender, birthday, relationship status, interests and hobbies, educational details, and employment details;</span></p>
      <p className="c4"><span className="c1">information, such as your name and email address, that you enter in order to set up subscriptions to our emails and/or newsletters;</span></p>
      <p className="c4"><span className="c1">information that you enter while using the services on HeroFit;</span></p>
      <p className="c4"><span className="c1">information that is generated while using HeroFit, including when, how often, and under what circumstances you use it;</span></p>
      <p className="c4"><span className="c1">information relating to anything you purchase, services you use, or transactions you make through HeroFit, which includes your name, address, telephone number, email address, and credit card details;</span></p>
      <p className="c4"><span className="c1">information that you post to HeroFit with the intention of publishing it on the internet, which includes your username, profile pictures, and the content of your posts;</span></p>
      <p className="c4"><span className="c1">information contained in any communications that you send to us by email or through HeroFit, including its communication content and metadata;</span></p>
      <p className="c4"><span className="c1">any other personal information that you send to us.</span></p>
      <p className="c4"><span className="c1">Before you disclose to us the personal information of another person, you must obtain that person&rsquo;s consent to both the disclosure and the processing of that personal information in accordance with this policy</span></p>
      <h2 className="c7" id="h.mpvaiozfwgks"><span className="c0">D. Using your personal information</span></h2>
      <p className="c4"><span className="c1">Personal information submitted to us through our website or apps will be used for the purposes specified in this policy or on the relevant pages of the website. We may use your personal information for the following:</span></p>
      <ul className="c11 lst-kix_hhy26e5jrlgr-0 start">
         <li className="c2"><span className="c1">administering our website, apps and business;</span></li>
         <li className="c2"><span className="c1">personalizing HeroFit for you;</span></li>
         <li className="c2"><span className="c1">enabling your use of the services available on HeroFit;</span></li>
         <li className="c2"><span className="c1">sending you goods purchased through HeroFit;</span></li>
         <li className="c2"><span className="c1">supplying services purchased through HeroFit;</span></li>
         <li className="c2"><span className="c1">sending statements, invoices, and payment reminders to you, and collecting payments from you;</span></li>
         <li className="c2"><span className="c1">sending you non-marketing commercial communications;</span></li>
         <li className="c2"><span className="c1">sending you email notifications that you have specifically requested;</span></li>
         <li className="c2"><span className="c1">sending you our email newsletter, if you have requested it (you can inform us at any time if you no longer require the newsletter);</span></li>
         <li className="c2"><span className="c1">sending you marketing communications relating to our business or the businesses of carefully-selected third parties which we think may be of interest to you, by post or, where you have specifically agreed to this, by email or similar technology (you can inform us at any time if you no longer require marketing communications);</span></li>
         <li className="c2"><span className="c1">providing third parties with statistical information about our users (but those third parties will not be able to identify any individual user from that information);</span></li>
         <li className="c2"><span className="c1">dealing with inquiries and complaints made by or about you relating to HeroFit;</span></li>
         <li className="c2"><span className="c1">keeping HeroFit secure and prevent fraud;</span></li>
         <li className="c2"><span className="c1">verifying compliance with the terms and conditions governing the use of HeroFit (including monitoring private messages sent through our website private messaging service); and</span></li>
         <li className="c2"><span className="c1">other uses.</span></li>
      </ul>
      <p className="c4"><span className="c1">If you submit personal information for publication on HeroFit, we will publish and otherwise use that information in accordance with the license you grant to us.</span></p>
      <p className="c4"><span className="c1">Your privacy settings can be used to limit the publication of your information on HeroFit and can be adjusted using privacy controls on the website.</span></p>
      <p className="c4"><span className="c1">We will not, without your express consent, supply your personal information to any third party for their or any other third party&rsquo;s direct marketing.</span></p>
      <h2 className="c7" id="h.qsfotq4sty29"><span className="c0">E. Disclosing personal information</span></h2>
      <p className="c4"><span className="c1">We may disclose your personal information to any of our employees, officers, insurers, professional advisers, agents, suppliers, or subcontractors as reasonably necessary for the purposes set out in this policy.</span></p>
      <p className="c4"><span className="c1">We may disclose your personal information to any member of our group of companies (this means our subsidiaries, our ultimate holding company and all its subsidiaries) as reasonably necessary for the purposes set out in this policy.</span></p>
      <p className="c4"><span className="c1">We may disclose your personal information:</span></p>
      <p className="c4"><span className="c1">to the extent that we are required to do so by law;</span></p>
      <p className="c4"><span className="c1">in connection with any ongoing or prospective legal proceedings;</span></p>
      <p className="c4"><span className="c1">in order to establish, exercise, or defend our legal rights (including providing information to others for the purposes of fraud prevention and reducing credit risk);</span></p>
      <p className="c4"><span className="c1">to the purchaser (or prospective purchaser) of any business or asset that we are (or are contemplating) selling; and</span></p>
      <p className="c4"><span className="c1">to any person who we reasonably believe may apply to a court or other competent authority for disclosure of that personal information where, in our reasonable opinion, such court or authority would be reasonably likely to order disclosure of that personal information.</span></p>
      <p className="c4"><span className="c1">Except as provided in this policy, we will not provide your personal information to third parties.</span></p>
      <p className="c4"><span className="c1">F. International data transfers</span></p>
      <p className="c4"><span className="c1">Information that we collect may be stored, processed in, and transferred between any of the countries in which we operate in order to enable us to use the information in accordance with this policy.</span></p>
      <p className="c4"><span className="c1">Information that we collect may be transferred to the following countries which do not have data protection laws equivalent to those in force in the European Economic Area: the United States of America, Russia, Japan, China, and India.</span></p>
      <p className="c4"><span className="c1">Personal information that you publish on HeroFit or submit for publication on HeroFit may be available, via the internet, around the world. We cannot prevent the use or misuse of such information by others.</span></p>
      <p className="c4"><span className="c1">You expressly agree to the transfers of personal information described in this Section F.</span></p>
      <p className="c4"><span className="c1">G. Retaining personal information</span></p>
      <p className="c4"><span className="c1">This Section G sets out our data retention policies and procedure, which are designed to help ensure that we comply with our legal obligations regarding the retention and deletion of personal information.</span></p>
      <p className="c4"><span className="c1">Personal information that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.</span></p>
      <p className="c4"><span className="c1">Without prejudice to article G-2, we will usually delete personal data falling within the categories set out below at the date/time set out below:</span></p>
      <p className="c4"><span className="c1">if you don't delete or request your account to be deleted, your personal data will be kept as necessary, otherwise type will be deleted at the end of the month.</span></p>
      <p className="c4"><span className="c1">Notwithstanding the other provisions of this Section G, we will retain documents (including electronic documents) containing personal data:</span></p>
      <p className="c4"><span className="c1">to the extent that we are required to do so by law;</span></p>
      <p className="c4"><span className="c1">if we believe that the documents may be relevant to any ongoing or prospective legal proceedings; and</span></p>
      <p className="c4"><span className="c1">in order to establish, exercise, or defend our legal rights (including providing information to others for the purposes of fraud prevention and reducing credit risk).</span></p>
      <h2 className="c7" id="h.zefy0on0rdff"><span className="c0">H. Security of your personal information</span></h2>
      <p className="c4"><span className="c1">We will take reasonable technical and organizational precautions to prevent the loss, misuse, or alteration of your personal information.</span></p>
      <p className="c4"><span className="c1">We will store all the personal information you provide on our secure (password- and firewall-protected) servers.</span></p>
      <p className="c4"><span className="c1">All electronic financial transactions entered into through HeroFit will be protected by encryption technology.</span></p>
      <p className="c4"><span className="c1">You acknowledge that the transmission of information over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet.</span></p>
      <p className="c4"><span className="c1">You are responsible for keeping the password you use for accessing HeroFit confidential; we will not ask you for your password (except when you log in to our website or apps).</span></p>
      <h2 className="c7" id="h.ex687ifj3fmx"><span className="c0">I. Amendments</span></h2>
      <p className="c4"><span className="c1">We may update this policy from time to time by publishing a new version on our website. You should check this page occasionally to ensure you understand any changes to this policy. We may notify you of changes to this policy by email or through the private messaging system on HeroFit.</span></p>
      <h2 className="c7" id="h.xevoumq7uksy"><span className="c0">J. Your rights</span></h2>
      <p className="c4"><span className="c1">You may instruct us to provide you with any personal information we hold about you; provision of such information will be subject to the following:</span></p>
      <p className="c4"><span className="c1">the supply of appropriate evidence of your identity (we will usually accept a photocopy of your passport certified by a notary plus an original copy of a utility bill showing your current address).</span></p>
      <p className="c4"><span className="c1">We may withhold personal information that you request to the extent permitted by law.</span></p>
      <p className="c4"><span className="c1">You may instruct us at any time not to process your personal information for marketing purposes.</span></p>
      <p className="c4"><span className="c1">In practice, you will usually either expressly agree in advance to our use of your personal information for marketing purposes, or we will provide you with an opportunity to opt out of the use of your personal information for marketing purposes.</span></p>
      <h2 className="c7" id="h.2ums5tblkw88"><span className="c0">K. Third party websites</span></h2>
      <p className="c4"><span className="c5">HeroFit</span><span className="c1">&nbsp;includes hyperlinks to, and details of, third party websites. We have no control over, and are not responsible for, the privacy policies and practices of third parties.</span></p>
      <h2 className="c7" id="h.63eicvpq0dyb"><span className="c0">L. Updating information</span></h2>
      <p className="c4"><span className="c1">Please let us know if the personal information that we hold about you needs to be corrected or updated.</span></p>
      <h2 className="c7" id="h.wkalsfvjctlc"><span className="c0">M. Cookies and Local Storage</span></h2>
      <p className="c4"><span className="c1">Our website uses cookies. A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server. Cookies may be either &ldquo;persistent&rdquo; cookies or &ldquo;session&rdquo; cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed. Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be linked to the information stored in and obtained from cookies. We use both session and persistent cookies on our website.</span></p>
      <p className="c4"><span className="c1">The names of the cookies and local storage items that we use on our website, and the purposes for which they are used, are set out below:</span></p>
      <ul className="c11 lst-kix_hba3c392pjh3-0 start">
         <li className="c2"><span className="c1">herofit-stravaActivities</span></li>
         <li className="c2"><span className="c1">herofit-avatar</span></li>
         <li className="c2"><span className="c1">herofit-jwt</span></li>
         <li className="c2"><span className="c1">herofit-cookiePermission</span></li>
         <li className="c2"><span className="c5">anything else prefixed with herofit</span></li>
      </ul>
      <p className="c4"><span className="c1">we use Google Analytics on our website to recognize a computer when a user visits the website, track users as they navigate the website, enable the use of a shopping cart on the website (when it becomes implemented), improve the website&rsquo;s usability, analyze the use of the website, administer the website, prevent fraud and improve the security of the website, and personalize the website for each user.</span></p>
      <p className="c4"><span className="c1">Most browsers allow you to refuse to accept cookies&mdash;for example:</span></p>
      <p className="c4"><span className="c1">in Internet Explorer (version 10) you can block cookies using the cookie handling override settings available by clicking &ldquo;Tools,&rdquo; &ldquo;Internet Options,&rdquo; &ldquo;Privacy,&rdquo; and then &ldquo;Advanced&rdquo;;</span></p>
      <p className="c4"><span className="c1">in Firefox (version 24) you can block all cookies by clicking &ldquo;Tools,&rdquo; &ldquo;Options,&rdquo; &ldquo;Privacy,&rdquo; selecting &ldquo;Use custom settings for history&rdquo; from the drop-down menu, and unticking &ldquo;Accept cookies from sites&rdquo;; and</span></p>
      <p className="c4"><span className="c1">in Chrome (version 29), you can block all cookies by accessing the &ldquo;Customize and control&rdquo; menu, and clicking &ldquo;Settings,&rdquo; &ldquo;Show advanced settings,&rdquo; and &ldquo;Content settings,&rdquo; and then selecting &ldquo;Block sites from setting any data&rdquo; under the &ldquo;Cookies&rdquo; heading.</span></p>
      <p className="c4"><span className="c1">Blocking all cookies or Local Storage will have a negative impact upon the usability of many websites. If you block cookies, you will not be able to use HeroFit.</span></p>
      <p className="c4"><span className="c1">You can delete cookies already stored on your computer&mdash;for example:</span></p>
      <p className="c4"><span className="c5">in Internet Explorer (version 10), you must manually delete cookie files (you can find instructions for doing so at </span><span className="c5"><a className="c10" href="https://www.google.com/url?q=https://support.microsoft.com/kb/278835&amp;sa=D&amp;ust=1607550002847000&amp;usg=AOvVaw3oCTGD7DJs6FCf25QhY3sl">http://support.microsoft.com/kb/278835</a></span><span className="c1">&nbsp;);</span></p>
      <p className="c4"><span className="c1">in Firefox (version 24), you can delete cookies by clicking &ldquo;Tools,&rdquo; &ldquo;Options,&rdquo; and &ldquo;Privacy&rdquo;, then selecting &ldquo;Use custom settings for history&rdquo;, clicking &ldquo;Show Cookies,&rdquo; and then clicking &ldquo;Remove All Cookies&rdquo;; and</span></p>
      <p className="c4"><span className="c1">in Chrome (version 29), you can delete all cookies by accessing the &ldquo;Customize and control&rdquo; menu, and clicking &ldquo;Settings,&rdquo; &ldquo;Show advanced settings,&rdquo; and &ldquo;Clear browsing data,&rdquo; and then selecting &ldquo;Delete cookies and other site and plug-in data&rdquo; before clicking &ldquo;Clear browsing data.&rdquo;</span></p>
      <p className="c4"><span className="c1">Deleting cookies will have a negative impact on the usability of many websites.</span></p>
      <p className="c4 c8"><span className="c1"></span></p>
      <h1 className="c9" id="h.3382hb8wb2ul"><span className="c17">Questions or comments?</span></h1>
      <p className="c4"><span className="c5">If you have questions or comments about </span><span className="c5">HeroFit</span><span className="c5">&#39;s privacy policy, send an email to </span><span className="c16"><a className="c10" href="mailto:info@herofit.io">info@herofit.io</a></span><span className="c5">, or contact us directly from our website at </span><span className="c16"><a className="c10" href="https://www.google.com/url?q=https://herofit.io/contact&amp;sa=D&amp;ust=1607550002848000&amp;usg=AOvVaw0t7inxKVpk_uTsRnuKjCzY">herofit.io/contact</a></span><span className="c1">.</span></p>
      <p className="c4"><span className="c5">Thank you for playing </span><span className="c5">HeroFit</span><span className="c1">!</span></p>
      </div>
    </section>
  )
}

export default PrivacyPolicyPage;