import _axios from 'axios';
import axiosRetry from 'axios-retry';
import { axiosOptions, axiosDeleteConfig } from '../common/axiosDefaults';
import { handleHttpError } from '../common/errorHandler';
const endpoint = process.env.REACT_APP_HF_ENDPOINT;

let axios = _axios.create();
axiosRetry(axios, {retries: 3});

const updateAvatarName = function(body){
  return axios.post(`${endpoint}account/update-avatar-name`, body, axiosOptions())
  .then(({ data }) => {
    return data.data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};

const updateOwnerUsername = function(body){
  return axios.post(`${endpoint}account/update-owner-username`, body, axiosOptions())
  .then(({ data }) => {
    return data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};

const deleteAccount = function(body){
  return axios.delete(`${endpoint}account/${body.username}`, axiosDeleteConfig(body))
  .then(({ data }) => {
    return data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
}

// Delete Strava fields in user table for particular user
const disconnectStrava = function(body){
  return axios.post(`${endpoint}auth/disconnect-strava`, body, axiosOptions())
  .then(({ data }) =>{
    return data;
  }).catch(({ request, response }) => {
      return handleHttpError(request, response);
  });
}


export { updateAvatarName, updateOwnerUsername, deleteAccount, disconnectStrava };