import React from 'react';
// LIBRARIES
import PropTypes from 'prop-types';
// COMPONENTS
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loading from './Overlays/Loading';

// STYLES
import './DialogTemplate.scss';
import Sensei from '../../assets/images/misc/sensei-modal-header.webp';


const DialogTemplate = function({ type, identifier, character, title, text, content, cancelButton, confirmButton, secondaryButton, isOpen, handleSave, handleClose, disableButton, disableBackdropClick, isLoading, closeOnSave = true }){
  const handleModalClose = (e, saveData) => {
    if(saveData){
      handleSave();
    }
    // Some modals load something else after the modal action. For these, we don't want to close the modal.
    if(closeOnSave){
      handleClose();
    }
  };

  const renderTopImage = function(character){
    switch(character){
      case 'owl':
        return <div id="dialogTopImage">
        <img src={Sensei} alt="Master Sensei Owl" />
      </div>
      default:
        return null
    }
  }

  const renderConfirmButton = function(){
    if(confirmButton){
      if(typeof confirmButton === 'object'){
        return <div id="dialogConfirmButton">
          <Button disabled={disableButton} onClick={(e) =>{ handleModalClose(e, true) }} variant="contained" color="primary" >
            { confirmButton }
          </Button>
        </div>
      }else{
        return (
          <div id="dialogConfirmButton">
            <Button disabled={disableButton} onClick={(e) =>{ handleModalClose(e, true) }} variant="contained" color="primary" >
              { confirmButton }
            </Button>
          </div>
        )
      }
    }else if(secondaryButton){
      return <div id="dialogConfirmButton">
        <Button disabled={disableButton} onClick={(e) =>{ handleModalClose(e, false) }} variant="contained" color="secondary" >
          { secondaryButton }
        </Button>
      </div>
    }
    return null;
  }

  const renderCancelButton = function(){
    if(cancelButton){
      return (
        <div id="dialogCancel" onClick={(e) =>{ handleClose(e, false)}}>
          X
        </div>
      )
    }
    return null;
  }

  const renderTitle = function(type, title){
    if(type === 'speech' || type === 'headless'){
      return null;
    }
    return <DialogTitle id="form-dialog-title">{ title }</DialogTitle>;
  }

  const renderText = function(type, text){
    if(text){
      if(type ==='speech'){
        return <span>
          <span className="arrow-up"></span>
          { text }
        </span>
      }
      return <span>
        { text }
      </span >
    }
    return null;

  }


  return (
    <div id="dialogWrapper">
      <Dialog className={`dialog-template dialog-template-${type} dialog-template-${identifier} ${isLoading ? 'dialog-template-loading' : ''}`} open={isOpen} onClose={ (e)=>{ handleModalClose(e, false) }} disableBackdropClick={disableBackdropClick}>
        <div id="dialogTop">
          { renderTopImage(character) }
          { renderCancelButton() }
        </div>
        { renderTitle(type, title) }
        <DialogContent>
          <DialogContentText>
            { renderText(type, text) }
          </DialogContentText>
          <div className="content-wrapper">
            { content }
          </div>
        </DialogContent>
        <DialogActions>
          { renderConfirmButton() }
        </DialogActions>
        { isLoading ? <Loading /> : null }
      </Dialog>
      
    </div>
  );
}

export default DialogTemplate;

DialogTemplate.propTypes = {
  handleSave : PropTypes.func,
  disableButton : PropTypes.bool, 
  title : PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  text : PropTypes.string,
  content : PropTypes.object,
  cancelButton : PropTypes.bool.isRequired,
  confirmButton : PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  secondaryButton : PropTypes.string,
  isOpen : PropTypes.bool.isRequired,
  disableBackdropClick : PropTypes.bool.isRequired,
  isLoading : PropTypes.bool,
  closeOnSave : PropTypes.bool
}