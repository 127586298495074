import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
//LIBRARIES
import PropTypes from 'prop-types';
import history from '../../common/history';
// COMPONENTS
import { EmailShareButton, FacebookShareButton, FacebookMessengerShareButton, RedditShareButton, TwitterShareButton, EmailIcon, RedditIcon, FacebookIcon, FacebookMessengerIcon, TwitterIcon } from "react-share";
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Header from '../Header/Header';
import { Fire, Earth, Water, Air, Power, Health, Recovery, Armor, Aether } from '../../common/icons.js';
import Hexagon from '../Hexagon/Hexagon';
import ActivitiesTable from '../ActivitiesTable/ActivitiesTable';
import BattleReport from '../HomePage/Modals/BattleReport/BattleReport';
import BattleReportDetail from '../HomePage/Modals/BattleReport/BattleReportDetail';
import ItemDetail from "../Dialog/Modals/ItemDetail";
// COMMON
import { debugErrors } from "../../common/errorHandler";
import { getProfileInfoByHeroName, getMatchingUsers, getHeroNameByUsername } from '../../services/userService';
import { fetchBattleReportById } from '../../services/battleService';
import HEROES from '../../common/includedHeroImages';
import ITEMS from '../../common/includedItemImages';
import VILLAINS from '../../common/includedVillainImages';
import dynamicComponent from "../../common/dynamicComponent";
import { lowercaseUnderscore, thousandsFormat, determineFoeClass, determineSkinType, determineSkinName, convertItemArrayToCategories, convertItemIdsToFullItems } from '../../common/helperFunctions';
//STYLES
import './ProfilePage.scss';

const ProfilePage = function({ MainMenuButton, defaultItems }){
  const [user, setUser] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [activities, setActivities] = useState(null);
  const [userTotals, setUserTotals] = useState(null);
  const [battles, setBattles] = useState([]);
  const [search, setSearch] = useState("");
  const [hasSearched, setHasSearched] = useState(false);
  const [avatarList, setAvatarList] = useState([]);
  const [pets, setPets] = useState([]);
  const [titles, setTitles] = useState([]);
  const [skins, setSkins] = useState([]);
  const [items, setItems] = useState([]);
  const [ activeSkin, setActiveSkin ] = useState(null)
  // Modals
  const [ battleId, setBattleId ] = useState(null);
  const [ battleReport, setBattleReport ] = useState(null);
  const [ battleReportModalOpen, setBattleReportModalOpen ] = useState(false);
  const [ battleReportDetailModalOpen, setBattleReportDetailModalOpen ] = useState(false);
  const [itemModalOpen, setItemModalOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  function handleSearchChange(target){
    setSearch(target.value);
  }

  // On click of item, close open modal then load new item into item modal
  function handleItemClick(item){
    setBattleReportModalOpen(false);
    setActiveItem(item);
    setItemModalOpen(true);
  }
  

  function handleSearch(e){
    e.preventDefault();

    getMatchingUsers({ searchInput : search })
    .then((data) =>{
      if(data.error){
        const error = data.error;
        error.message = 'Unable to get matching users';
        return debugErrors(error);
      }console.log('THE DATA', data);
      const { userList } = data;
      setAvatarList(userList[0] ? userList[0].concat(userList[1]) : userList[1]);
      setHasSearched(true);
    });
  }

  function renderPets(){
    if(pets.length){
      const mappedPets = pets.map((pet, i) =>{
        return <div onClick={() => handleItemClick(pet)} key={i} className="pet-content-container">
            <img className="item-pet" src={ITEMS.pets[lowercaseUnderscore(pet.name)]} alt="Pet" />
          </div>
      });


      return mappedPets;
    }
    return null;
  }

  function renderSkins(){

    if(skins.length){
      const mappedSkins = skins.map((skin, i) =>{
        
        return <div onClick={() => handleItemClick(skin)} key={i} className="skin-content-container">
            {<img src={HEROES[determineSkinType(skin)][determineSkinName(skin, avatar.character)]} className={`skin-image  ${skin.class}` } alt={skin.name} /> }
          </div>
      });

      return mappedSkins;
    }
    return null;
  }

  function renderBattles(battles){

    return battles.map((battle, i) => {
      const foe = battle.foe;
      const foeClass = determineFoeClass(foe.type.toLowerCase());
      const isShadow = foe.type === 'Shadow-Self';
      const isSpirit = foeClass === "spirits" && !isShadow;

      let leftImgSrc = HEROES[determineSkinType(activeSkin)][determineSkinName(activeSkin, avatar.character)];
      let rightImgSrc = isShadow ? VILLAINS.spirits['shadow_' + lowercaseUnderscore(avatar.character)] : VILLAINS[foeClass][lowercaseUnderscore(foe.type)];
      let left = battle.avatarName;
      let right = battle.foe.name;
      return <li key={i}>
        <div className={`battle battle-${battle.id} ${battle.outcome === 'TBD' ? 'battle-tbd' : ''}`} onClick={() => handleBattleClick(battle.id, battle.avatarID)}>
          <div id="b1">
            <div className={`left-battle-img ${activeSkin?.class}`}><img src={leftImgSrc} alt={left} /></div>
            <span>{ left }</span>
          </div>
          <div id="b2">
            <strong>VS</strong><br />
            <span>{ battle.outcome === 'Avatar Wins' ? 'Hero Wins' : battle.outcome }</span>
          </div>
          <div id="b3">
            <div className={`right-battle-img foe-${foe.type} ${isSpirit ? 'spirit' : ''}`}><img  src={rightImgSrc} alt={right} /></div>
            <span>{ right }</span>
          </div>

        </div>
      </li>
    });
  }

  function renderActiveTitle(){
    if(titles.length){
      const title = titles.find((title) => title.equipped);
      if(title){
        return <div onClick={() => handleItemClick(title)} className="avatar-title">
          { dynamicComponent({ type: title.type, name: title.name, style : null, cssClass: title.class }) }{title.name}
        </div> 
      }
      return null;
    }
    return null;

  }

  function renderAllTitles(){
    if(titles.length){
      return titles.map((title, i)=>{
        return(
          <div onClick={() => handleItemClick(title)}  key={i} className="title-descriptor">
            <strong> { dynamicComponent({ type: title.type, name: title.name, style: null, cssClass: title.class }) }{title.name} </strong>
          </div>
        )
      })
    }
    return null;
  }

  function renderActiveSkin(){
    if(activeSkin){
      return <img onClick={() => handleItemClick(activeSkin)}  className={activeSkin.class} src={HEROES[determineSkinType(activeSkin)][determineSkinName(activeSkin, avatar.character)]} alt={avatar.character} />
    }
    return <img src={HEROES[determineSkinType()][determineSkinName(null, avatar.character)]} alt={avatar.character} />;
  }


  function renderUserProfile(user){
    if(avatar && user){
      return(
        <div id="userProfile">
          <div id="avatarDescription" className="panel">
            { renderActiveTitle() }
            <strong className="avatar-name">{avatar.name}</strong><br />
            <strong className="owner-username">{avatar.ownerUserName}</strong>
            <div>
              <span className="avatar-level">Level <strong>{avatar.level}</strong><span className="total-xp muted">&nbsp;&nbsp;&nbsp;XP:{thousandsFormat(avatar.activityXP + avatar.battleXP)} ({thousandsFormat(avatar.activityXP)} + {thousandsFormat(avatar.battleXP)})</span></span><br />
            </div>
            <div className="activity-count xp-generator">
              Activities: <strong>{activities ? activities.length : 0}</strong>
            </div>
            <div className="battle-stats xp-generator">
              Battles: <strong>{avatar.battleWins} - {avatar.battleLosses} - {avatar.battleDraws + avatar.battleDkos} </strong>
            </div>
            
            { renderAllTitles() }
            
          </div>

          <div id="imgContainer" className="panel panel-hero">
            <div className="social-sharing-profile">
              <div className="share-herofit-text">
              <strong>Help HeroFit Grow!</strong>
                <span className="muted">Share This Profile</span>
              </div>
              <div className="social-list">
                <EmailShareButton subject={`HeroFit - The Fitness Tracking Game`} body={`I found this game and thought you might be interested!`} children={<EmailIcon bgStyle={{ fill : '#242423' }} size={32} round={true} />} url={`${window.location}`}  />
                <FacebookMessengerShareButton children={<FacebookMessengerIcon  bgStyle={{ fill : '#242423' }} size={32} round={true} />} url={`${window.location}`} quote={'Check out my Hero Profile on HeroFit!'} hashtag={'HeroFit'}  />
                <FacebookShareButton children={<FacebookIcon  bgStyle={{ fill : '#242423' }} size={32} round={true} />} url={`${window.location}`} quote={'Check out my Hero Profile on HeroFit!'} hashtag={'HeroFit'} />
                <RedditShareButton  title={'HeroFit - The Fitness Tracking Game'} children={<RedditIcon  bgStyle={{ fill : '#242423' }} size={32} round={true} />} url={`${window.location}`} />
                <TwitterShareButton hashtags={['Herofit','FitnessGame',"C25K","COUCHTO5K","fitnessMotivator","exerciseGame","rpg","incrementalGame","strava"]} title={'HeroFit - The Fitness Tracking Game'} children={<TwitterIcon  bgStyle={{ fill : '#242423' }} size={32} round={true} />} url={`${window.location}`} />
              </div>
            </div>
            <div className="avatar-img-container ">
              { renderActiveSkin() }
            </div> 
          </div>

          <div id="stats" className="panel panel-stats">
            
            <div id="primaryAttributes">
              <div id="r1">
                <Hexagon diameter={168} color={"#333533"} borderColor="#F1C85B" icon={ <Power /> } iconStyles={{ color: '#E8EDDF', size: '5.4em' }} text={Math.floor(avatar.power) || '0'} textStyles={{color : '#E8EDDF', fontSize: '4em', fontWeight: 'bold' }}  modifier={{ text:'-3px', icon:'-3px' }} />
                <Hexagon diameter={84} color={"#333533"} borderColor="#F1C85B" icon={ <Recovery /> } iconStyles={{ color: '#E8EDDF', size: '2.4em' }} text={Math.floor(avatar.recovery) || '0'} textStyles={{color : '#E8EDDF', fontSize: '1.8em', fontWeight: 'bold' }}  modifier={{ text:'-3px', icon:'-3px' }} />
              </div>
              <div id="r2">
                <Hexagon diameter={84} color={"#333533"} borderColor="#F1C85B" icon={ <Armor /> } iconStyles={{ color: '#E8EDDF', size: '2.4em' }} text={Math.floor(avatar.armor) || '0'} textStyles={{color : '#E8EDDF', fontSize: '1.8em', fontWeight: 'bold' }}  modifier={{ text:'-3px', icon:'-3px' }} />
                <Hexagon diameter={168} color={"#333533"} borderColor="#F1C85B" icon={ <Health /> } iconStyles={{ color: '#E8EDDF', size: '5em' }} text={Math.floor(avatar.maxHealth)} textStyles={{color : '#E8EDDF', fontSize: '4em', fontWeight: 'bold' }}  modifier={{ text:'-3px', icon:'-3px' }} />
              </div>
            </div>

            <div id="secondaryAttributes">
              <Hexagon diameter={64} color={"#cfdbd5"} borderColor="#333533" icon={ <Fire /> } iconStyles={{ color: '#333533', size: '2.1em' }} text={Math.floor(avatar.fire) || '0'} textStyles={{color : '#333533', fontSize: '1.5em', fontWeight: 'bold' }}  modifier={{ text:'-2px' }} />
              <Hexagon diameter={64} color={"#cfdbd5"} borderColor="#333533" icon={ <Earth /> } iconStyles={{ color: '#333533', size: '1.9em' }} text={Math.floor(avatar.earth) || '0'} textStyles={{color : '#333533', fontSize: '1.5em', fontWeight: 'bold' }}  modifier={{ text:'-2px' }} />
              <Hexagon diameter={64} color={"#cfdbd5"} borderColor="#333533" icon={ <Air /> } iconStyles={{ color: '#333533', size: '1.9em' }} text={Math.floor(avatar.air) || '0'} textStyles={{color : '#333533', fontSize: '1.5em', fontWeight: 'bold' }}  modifier={{ text:'-2px' }} />
              <Hexagon diameter={64} color={"#cfdbd5"} borderColor="#333533" icon={ <Water /> } iconStyles={{ color: '#333533', size: '1.9em' }} text={Math.floor(avatar.water) || '0'} textStyles={{color : '#333533', fontSize: '1.5em', fontWeight: 'bold' }}  modifier={{ text:'-2px' }} />
              {
                avatar.aether ? <Hexagon diameter={64} color={"#cfdbd5"} borderColor="#333533" icon={ <Aether /> } iconStyles={{ color: '#333533', size: '1.9em' }} text={Math.floor(avatar.aether) || '0'} textStyles={{color : '#333533', fontSize: '1.5em', fontWeight: 'bold' }}  modifier={{ text:'-2px' }} /> : null
              }
            </div>
          </div>
          <div id="pets" className="panel panel-pets">
            <h3>Pets</h3><br />
            <div id="petWrapper">{ renderPets() }</div>
          </div>
          <div id="skins" className="panel panel-skins">
            <h3>Costumes</h3>
            <div id="skinWrapper">
              <ul>{ renderSkins() }</ul>
            </div>
          </div>
          <div id="battles" className="panel">
            <h3>Battles</h3>
            <ul>{ renderBattles(battles) }</ul>
          </div>      
        </div>
      )
    }
    return(
      <div id="loadingContainer">
        <div className="page-loading-indicator">
          <CircularProgress style={{width:'100%', height: '100%'}} />
          <div className="loading-message">Looking for Hero...</div>
        </div>
      </div>
    )
  }

  function getHeroNameFromUsername(username){
    getHeroNameByUsername({username})
    .then((data) =>{
      if(data.error){
        const error = data.error;
        error.message = 'Unable to get user by email';
        return debugErrors(error);
      }
      const { name } = data;
      history.push(`/users/${encodeURI(name)}`);
      window.location.href = `/users/${encodeURI(name)}`;
    });
  }

  function renderFindUser(){
    function renderResultsList(){
      if(avatarList.length){
        const mappedList = avatarList.map((item, i ) =>{
          if(item.name){
            return <li key={i}><a  className="link-text" href={`users/${encodeURI(item.name)}`}>HERO -  { item.name }</a></li>;
          }else if(item.username){
            return <li key={i}><span className="link-text" onClick={() =>getHeroNameFromUsername(item.username)}>USER -  { item.username }</span></li>;
          }

        });
        return(
          <ul > { mappedList } </ul>
        )
      }
      return hasSearched ? <h3>No Matching Users!</h3> : null;
    }

    return(
      <div id="searchWrapper">
      <div id="findUser">
          <h2>Find a User by Username or Hero name</h2>
            <form id="search" onSubmit={handleSearch}>
              <div id="searchContainer">
                <input name="search" placeholder="Search" type="text" value={search} onChange={({target}) =>{ handleSearchChange(target) }}  maxLength="25" />
              </div>
              <Button className="block submit-button" type="submit" variant="contained" color="primary">Search</Button>
            </form>
          <div id="userList">
            { renderResultsList() }
          </div>
        </div>
      </div>
    )
  }

  function handleBattleClick(id, avatarID){
    

    return fetchBattleReportById({id, avatarID})
    .then((data) =>{
      if(data.error){
        return debugErrors(data.error);
      }
      const { adjResult } = data;
      //console.log('ADJ resuLETS - ', adjResult);
      if(adjResult.outcome !== 'TBD'){
        setBattleId(id);
        setBattleReport(adjResult);

        setBattleReportModalOpen(true);
      }
    });
  }
  
  // fetch specific avatar's data based on URL & assign it to state
  useEffect(()=>{
    const path = history.location.pathname;
    let heroName;

    if(defaultItems.length){
      // If the user is in the url, set it to state then fetch user
      if(path.split('/').length > 2){
        heroName =  decodeURI(path.split('/')[2]);
        setUser(heroName);

        getProfileInfoByHeroName({ heroName })
        .then((data) =>{
          if(data.error){
            const error = data.error;
            error.message = 'Unable to get info by username.';
            debugErrors(error);
            return  window.location.href = `/users`;
          }else if(data.noUser){
            setSearch(heroName);
            setHasSearched(true);
            return setUser(null);
          }
          const { userTotals, activities, avatar, itemInstances, battles } = data;

          const allAvatarItems = convertItemIdsToFullItems(itemInstances, defaultItems);
          const itemsByCategory = convertItemArrayToCategories(allAvatarItems);


          const mappedItemTypes = {
            pets : itemsByCategory.pets.sort((a,b) => a.equipped === b.equipped ? 0 : a ? -1 : 1),
            titles : itemsByCategory.titles.sort((a,b) => a.equipped === b.equipped ? 0 : a ? -1 : 1),
            skins : itemsByCategory.skins.sort((a,b) => a.equipped === b.equipped ? 0 : a ? -1 : 1),
            items : [],
          }
          const equippedSkin = itemsByCategory.skins.find(item => item.equipped);
          setAvatar(avatar);
          setPets(mappedItemTypes.pets);
          setTitles(mappedItemTypes.titles);
          setItems(mappedItemTypes.items);
          setSkins(mappedItemTypes.skins);
          setActiveSkin(equippedSkin || null);
          setActivities(activities);
          setUserTotals(userTotals);
          setBattles(battles);
        });
      }
    }
  }, [defaultItems]);

  return(
    <section id="profilePage">
      <Header title={`Hero Profile`} subtitle={user || null} MainMenuButton={MainMenuButton} />
      {
        user ? renderUserProfile(user)
        : renderFindUser()
      }
      
      {
        activities ? 
        <div id="latestActivitiesWrapper"><ActivitiesTable activityData={activities} tableType={'latest'} theme={'light'}  /></div>
        : null
      }

      {
        battleReport ? 
          <div>
            <BattleReport modalOpen={battleReportModalOpen} setModalOpen={setBattleReportModalOpen}  handleModalAction={ () => {setBattleReportModalOpen(false); setBattleReportDetailModalOpen(true);} } battleReport={battleReport} battleId={battleId} avatarEquip={activeSkin ? [activeSkin] : null} handleItemClick={handleItemClick} defaultItems={defaultItems} />
            <BattleReportDetail modalOpen={battleReportDetailModalOpen} setModalOpen={setBattleReportDetailModalOpen} battleReport={battleReport} battleId={battleId} />
          </div>
        :
        null
      }

    {
      activeItem ? 
        <ItemDetail modalOpen={itemModalOpen} setModalOpen={setItemModalOpen} item={activeItem} avatar={avatar} />
      : null
    }
    </section>
  )
}

export default ProfilePage;

ProfilePage.propTypes = {
  MainMenuButton : PropTypes.object.isRequired,
  defaultItems : PropTypes.array.isRequired,
  //updateState : PropTypes.func.isRequired
};