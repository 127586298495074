import _axios from 'axios';
import axiosRetry from 'axios-retry';
import { axiosOptions } from '../common/axiosDefaults';
import { handleHttpError } from '../common/errorHandler';
const endpoint = process.env.REACT_APP_HF_ENDPOINT;

let axios = _axios.create();
axiosRetry(axios, {retries: 3});

const getUser = function(body){
    return axios.post(`${endpoint}user/user-details`, body, axiosOptions())    
    .then(({ data }) => {
        return data.data;
    }).catch(({ request, response }) => {
        return handleHttpError(request, response);
    });
};

const getSavedActivities = function(body){
    return axios.post(`${endpoint}user/get-user-activities`, { ...body }, axiosOptions())
    .then(({ data }) =>{
        if(data.data.activities){
            return { activities : data.data.activities, latestActivityDate : data.data.latestActivityDate };
        }else{
            return { activities : [], latestActivityDate : null };
        }
    }).catch(({ request, response }) => {
        return handleHttpError(request, response);
    });
};

const instantiateUserTotals = function(body){
    return axios.post(`${endpoint}user/instantiate-user-totals`, body, axiosOptions())
    .then(({ data }) => {
        return data.data;
    }).catch(({ request, response }) => {
        return handleHttpError(request, response);
    });
};

const updateUserTotals = function(body){
    return axios.post(`${endpoint}user/update-user-totals`, body, axiosOptions())
    .then(({ data }) => {
        return data.data;
    }).catch(({ request, response }) => {
        return handleHttpError(request, response);
    });
};

const getProfileInfoByHeroName = function(body){
    return axios.post(`${endpoint}user/get-profile-info-by-email`, body, axiosOptions())
    .then(({ data }) => {
        return data.data;
    }).catch(({ request, response }) => {
        return handleHttpError(request, response);
    });
};

const getMatchingUsers = function(body){
    return axios.post(`${endpoint}user/get-matching-users`, body, axiosOptions())
    .then(({ data }) => {
        return data.data;
    }).catch(({ request, response }) => {
        return handleHttpError(request, response);
    });
};

const getHeroNameByUsername = function(body){
    return axios.post(`${endpoint}user/get-hero-name-by-username`, body, axiosOptions())
    .then(({ data }) => {
        return data.data;
    }).catch(({ request, response }) => {
        return handleHttpError(request, response);
    });
};


export { getUser, getSavedActivities, instantiateUserTotals, updateUserTotals, getProfileInfoByHeroName, getMatchingUsers, getHeroNameByUsername }