import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// LIBRARIES
import PropTypes from 'prop-types';
// COMMON
import history from '../../common/history';
import { getUrlParams } from '../../common/helperFunctions';
import { debugErrors } from "../../common/errorHandler";
// SERVICES
import { sendVerificationCode } from "../../services/authService";
import { getAvatar } from "../../services/avatarService";

// COMPONENTS
import Hexagon from '../Hexagon/Hexagon';
import { Login, Register, ForgotPassword } from '../Dialog/Modals/Auth/AllAuthModals';

// STYLES
import './WelcomePage.scss';

const WelcomePage = function({ updateState, MainMenuButton, handlePostLogin }){
  // Auth Modals State
  const [LoginOpen, setLoginOpen] = useState(false);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [isLegacyUser, setIsLegacyUser] = useState(false);
  const { login, verify, verifyPassword } = getUrlParams(history.location);

  function handlePickChampion(){
    history.push('/select-hero');
  }

  function toggleLoginForgotPassword(destination, legacyUser){
    // this property will change the title of the Forgot Password modal
    if(legacyUser){
      setIsLegacyUser(true);
    }

    if(destination === 'Login'){
      setForgotPasswordOpen(false);
      setLoginOpen(true);
    }else if(destination === 'Forgot Password'){
      setLoginOpen(false);
      setForgotPasswordOpen(true);
    }
  }
  useEffect(() =>{
    if(login){
      setLoginOpen(true);
    }
  }, [login]);

  // VERIFY EMAIL
  // NOTE! This is only for verifying email, not resetting password or updating legacy accounts
  useEffect(() =>{
    if(verify){
      // Sends verification code back to server, then logs the user in
      // returning user, then fetch avatar
      sendVerificationCode({ emailCode : verify })
      .then((data) =>{
        if(data.error){console.log('IN VERIFICATION ERROR', data.error);
          const error = data.error;
          updateState({ alerts : [{ type : 'error', message : error.message }] });
          return debugErrors(error);
        }
 
        const { user } = data;
  
        return getAvatar({ email : user.email})
        .then((data) =>{
          if(data.error){
            const error = data.error;
            updateState({ alerts : [{ type : 'error', message : error.message }] });
            return debugErrors(error);
          }
          updateState({ alerts : [{ type : 'success', message : "Email has been verified!" }] });
          const { avatar } = data;
          setTimeout(() =>{
            return handlePostLogin(user, avatar);
          }, 1500);
        });
      });
    }
  }, [verify]);

  // VERIFY PASSWORD
  // When this url param comes in, we open part 2 of forgot password modal which allows them to reset their password
  useEffect(() =>{
    if(verifyPassword){
      setForgotPasswordOpen(true);
    }
  }, [verifyPassword]);

  return(
    <div id="welcomePage" className="page">
      <div id="welcomePageContent">
        {MainMenuButton}
        <section id="titleContainer">
          <div id="titleLogo">
            <a href="https://herofitgame.com"><img src={require("../../assets/images/misc/herofit-logo.webp")} alt="HeroFit" /></a><br />

          </div>
          <h2>The Fitness Tracking Game</h2>
        </section>
        {/* <div id="pickChampionButton" className="shake-delay" onClick={ () =>{ handlePickChampion() }}>
          <Hexagon button={true} diameter={160} color={"#D4AF37"} border={10} borderColor="#F1C85B" text={<div>Select<br />Hero</div>} textStyles={{color : '#fff', fontSize: '2.8em', fontWeight: 'bold' }}  modifier={{ text:'10px' }} />
        </div> */}
        
        {/* <div id="loginLink">
          <strong onClick={() => setLoginOpen(true)}>login</strong>
        </div> */}
        <section id="aboutContent">
          <p><strong>Track your exercises</strong> in the real world, <strong>level up your Hero</strong> in game! The more you exercise, the stronger they get.<br /></p>
        </section>
        <div id="mainCta">
          <h2 className="brand-highlight">
          Download Now
          </h2>
          <div id="appAndPlayButtons">
    
            <a href="https://apps.apple.com/app/id1592847717"><img src={require("../../assets/images/misc/appstorebadge.svg")} alt={"App Store"}  /></a>
            <a href="https://play.google.com/store/apps/details?id=com.herofitllc.herofit&hl=en_US&gl=US"><img id="googlePlay" src={require("../../assets/images/misc/googleappbadge.svg")}  alt={"Google Play Store"}  /></a>
                          
          </div>
        </div>
        {/* <section id="onboardSteps">
          <strong className="cta-initiation-title brand-highlight">Hero, you're our only hope!</strong><br />
          <strong className="">Begin The Hero's Initiation at once!</strong>
          <ol>
            <li>Choose your Hero</li>
            <li>Create a HeroFit Account</li>
            <li>Connect/Create your Strava Account</li>
          </ol>
        </section> */}
        <div id="linkToAbout">
          {/* <Link to="/about">Learn More</Link> */}
          <a href="https://herofitgame.com">Learn More</a>
        </div>

      </div>
      <div id="welcomePageOverlay"></div>

      {/* Authentication Modals */}
      <Login
        modalOpen={LoginOpen}
        setModalOpen={setLoginOpen}
        toggleLoginForgotPassword={toggleLoginForgotPassword}
        updateState={updateState}
        handlePostLogin={handlePostLogin}
      />
      {/* <ForgotPassword
        modalOpen={forgotPasswordOpen}
        setModalOpen={setForgotPasswordOpen}
        toggleLoginForgotPassword={() => toggleLoginForgotPassword('Login')}
        updateState={updateState}
        token={verifyPassword}
        isLegacyUser={isLegacyUser}
      /> */}
    </div>
  )
};

export default WelcomePage;

WelcomePage.propTypes = {
  MainMenuButton : PropTypes.object.isRequired,
  updateState : PropTypes.func.isRequired,
  handlePostLogin : PropTypes.func,
  openOnLoad : PropTypes.func
};