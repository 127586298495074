import _axios from 'axios';
import axiosRetry from 'axios-retry';
import { axiosOptions } from '../common/axiosDefaults';
import { handleHttpError } from '../common/errorHandler';
const endpoint = process.env.REACT_APP_HF_ENDPOINT;

let axios = _axios.create();
axiosRetry(axios, {retries: 3});

const insertAvatar = function(body){
  return axios.post(`${endpoint}avatar/insert-avatar`, body, axiosOptions())
  .then(({ data }) => {
    return data.data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};

const updateAvatarStats = function(body){
  return axios.post(`${endpoint}avatar/update-avatar-stats`, body, axiosOptions())
  .then(({ data }) => {
    return data.data || { data : null };
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};

const upgradeSequence = function(body){
  console.log('THEBOD', body);
  return axios.post(`${endpoint}avatar/upgrade-sequence`, body, axiosOptions())
  .then(({ data }) => {
    return data.data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};

const getAvatar = function(body){  
  return axios.post(`${endpoint}avatar/get-avatar`, body, axiosOptions())
  .then(({ data }) => {
    const { avatar } = data.data;
    return { avatar };      
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};

const checkAvatarName = function(body){
  return axios.post(`${endpoint}avatar/check-avatar-name`, body, axiosOptions())
  .then(({ data }) => {
    return data.data;      
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};

const fetchAvatarsByPage = function(body){
  return axios.post(`${endpoint}avatar/fetch-avatars-by-page`, body, axiosOptions())
  .then(({ data }) => {
    return data.data;
  }).catch(({ request, response }) => {
    // Need to return here. If async is needed, need to wrap it in a new Promise
    // Client received an error response (5xx, 4xx, or something from bad code before request)
    return handleHttpError(request, response);
  });
};

const fetchAvatarsByPageAndRankingType = function(body){
  return axios.post(`${endpoint}avatar/fetch-avatars-by-page-and-ranking-type`, body, axiosOptions())
  .then(({ data }) => {
    return data.data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};

const fetchAvatarsByPageAndRankingTypeBattle = function(body){
  return axios.post(`${endpoint}avatar/fetch-avatars-by-page-and-ranking-type-battle`, body, axiosOptions())
  .then(({ data }) => {
    return data.data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};


export { insertAvatar, updateAvatarStats, upgradeSequence, getAvatar, checkAvatarName, fetchAvatarsByPage, fetchAvatarsByPageAndRankingType, fetchAvatarsByPageAndRankingTypeBattle }