/*
 ALL FOES
*/

/*
 SHADOW-SELF
*/

import shadow_filtron_five from '../assets/images/avatars/filtron_five/shadow_filtron_five.webp';
import shadow_solar_celeste from '../assets/images/avatars/solar_celeste/shadow_solar_celeste.webp';
import shadow_boulder_bro from '../assets/images/avatars/boulder_bro/shadow_boulder_bro.webp';
import shadow_compost_creature from '../assets/images/avatars/compost_creature/shadow_compost_creature.webp';
import shadow_chrono_guy from '../assets/images/avatars/chrono_guy/shadow_chrono_guy.webp';
import shadow_natural_ninja from '../assets/images/avatars/natural_ninja/shadow_natural_ninja.webp';
import shadow_timber_terror from '../assets/images/avatars/timber_terror/shadow_timber_terror.webp';
import shadow_wilhelm_the_wild from '../assets/images/avatars/wilhelm_the_wild/shadow_wilhelm_the_wild.webp';
import shadow_empath_aurelia from '../assets/images/avatars/empath_aurelia/shadow_empath_aurelia.webp';
import shadow_repete from '../assets/images/avatars/repete/shadow_repete.webp';

// SPIRITS

import apparition from '../assets/images/foes/spirits/apparition/apparition.webp';
import banshee from '../assets/images/foes/spirits/banshee/banshee.webp';
import phantasm from '../assets/images/foes/spirits/phantasm/phantasm.webp';
import phantom from '../assets/images/foes/spirits/phantom/phantom.webp';
import poltergeist from '../assets/images/foes/spirits/poltergeist/poltergeist.webp';
import shade from '../assets/images/foes/spirits/shade/shade.webp';
import specter from '../assets/images/foes/spirits/specter/specter.webp';
import wraith from '../assets/images/foes/spirits/wraith/wraith.webp';


// ELEMENTALS
import gusty_rascal from '../assets/images/foes/elementals/gusty_rascal.webp';
import rock_skipper from '../assets/images/foes/elementals/rock_skipper.webp';
import flame_fiend from '../assets/images/foes/elementals/flame_fiend.webp';
import splash_artist from '../assets/images/foes/elementals/splash_artist.webp';

import wheezing_jinn from '../assets/images/foes/elementals/wheezing_jinn.webp';
import granite_golem from '../assets/images/foes/elementals/granite_golem.webp';
import burning_jinn from '../assets/images/foes/elementals/burning_jinn.webp';
import cyclonic_siren from '../assets/images/foes/elementals/cyclonic_siren.webp';

import storming_oni from '../assets/images/foes/elementals/storming_oni.webp';
import hulking_aggro_crag from '../assets/images/foes/elementals/hulking_aggro_crag.webp';
import scorching_archfiend from '../assets/images/foes/elementals/scorching_archfiend.webp';
import high_priestess_of_the_tides from '../assets/images/foes/elementals/high_priestess_of_the_tides.webp';


// TITANS
import plaguebringer from '../assets/images/foes/titans/plaguebringer.webp';
import guardian_of_the_depths from '../assets/images/foes/titans/guardian_of_the_depths.webp';

const VILLAINS =  {
  spirits : { 
    apparition,
    banshee,
    phantasm,
    phantom,
    poltergeist,
    shade,
    specter,
    wraith,
    // Shadow self
    shadow_filtron_five, shadow_solar_celeste, shadow_boulder_bro, shadow_compost_creature, shadow_chrono_guy, shadow_natural_ninja, shadow_timber_terror, shadow_wilhelm_the_wild, shadow_empath_aurelia, shadow_repete,
  },
  elementals : {
    gusty_rascal,
    rock_skipper,
    flame_fiend,
    splash_artist,

    wheezing_jinn,
    granite_golem,
    burning_jinn,
    cyclonic_siren,

    storming_oni,
    hulking_aggro_crag,
    scorching_archfiend,
    high_priestess_of_the_tides,
  },
  titans : { 
    plaguebringer,
    guardian_of_the_depths
  }
};

export default VILLAINS;