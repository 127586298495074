import React from 'react';
import { Link } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import { EmailShareButton, FacebookShareButton, FacebookMessengerShareButton, RedditShareButton, TwitterShareButton, EmailIcon, RedditIcon, FacebookIcon, FacebookMessengerIcon, TwitterIcon } from "react-share";
// LIBRARIES
import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@material-ui/lab';
import history from '../../common/history';
// STYLES
import './Footer.scss';



const Footer = function({ alerts, clearAlert }){
  function handleConfirmButton(a){
    if(a.identifier === 'Cookie Permission'){
      localStorage.setItem("herofit-cookiePermission", "true");
      clearAlert(a.index);
    }

    if(a.cb){
      a.cb();
    }
    
  }


  function renderAlerts(){
    const alertEls = alerts.map((a)=>{
        return(
          <Alert key={`alert-${a.index}`} severity={a.type} className={`alert alert-${a.type} alert-index-${a.index}  ${a.persist ? 'fade-in' : 'fade-in-then-out'}`} >
            <AlertTitle className="alert-title">{a.type === 'info' ? 'FYI' : a.type}</AlertTitle>
            { a.link ? <a href={a.link} target="_blank" >{a.message}</a> : a.message }
            { a.confirm ? <button onClick={() => handleConfirmButton(a)}>{a.confirm}</button> : null }
            <span className={`close-alert`} onClick={() =>{ clearAlert(a.index) }}><MdClose /></span>
          </Alert>
        )
    });

    if(alerts.length){
      return <div id="alertWrapper" key="alert-wrapper" className={` alert-wrapper`}>
        <section key="alert-section" className="alert-section">
            {[...alertEls]}
        </section>
    </div>
    }
    return null;
  }

  return(
    <footer>
      <div id="footerContent">
        <div>
          <strong>&copy; 2020 - 2022 HeroFit</strong><br />
          <div className="social-sharing-profile">
            <div className="social-list">
              <EmailShareButton subject={`HeroFit - The Fitness Tracking Game`} body={`I found this game and thought you might be interested!`} children={<EmailIcon bgStyle={{ fill : '#242423' }} iconFillColor={'#d4af37'}  size={32} round={true} />} url={`${window.location}`}  />
              <FacebookMessengerShareButton children={<FacebookMessengerIcon  bgStyle={{ fill : '#242423' }} iconFillColor={'#d4af37'} size={32} round={true} />} url={`${window.location}`} quote={'Check out my Hero Profile on HeroFit!'} hashtag={'HeroFit'}  />
              <FacebookShareButton children={<FacebookIcon  bgStyle={{ fill : '#242423' }} iconFillColor={'#d4af37'} size={32} round={true} />} url={`${window.location}`} quote={'Check out my Hero Profile on HeroFit!'} hashtag={'HeroFit'} />
              <RedditShareButton title={'HeroFit - The Fitness Tracking Game'} children={<RedditIcon  bgStyle={{ fill : '#242423' }} iconFillColor={'#d4af37'} size={32} round={true} />} url={`${window.location}`} />
              <TwitterShareButton hashtags={['Herofit','FitnessGame',"C25K","COUCHTO5K","fitnessMotivator","exerciseGame","rpg","incrementalGame","strava"]} title={'HeroFit - The Fitness Tracking Game'} children={<TwitterIcon  bgStyle={{ fill : '#242423' }} iconFillColor={'#d4af37'} size={32} round={true} />} url={`${window.location}`} />
            </div>
          </div>
          
          <Link to={`/terms-of-service`}>Terms of Service</Link><br />
          <Link to={`/privacy-policy`}>Privacy Policy</Link><br />
          <Link to={`/contact`}>Contact</Link>
        </div>
        <div id="footerBackgroundImage"></div>
      </div>
      { renderAlerts() }
    </footer>
  )
};

export default Footer;

Footer.propTypes = {
  alerts : PropTypes.arrayOf(PropTypes.object).isRequired,
  clearAlert : PropTypes.func.isRequired
};