import React, { useState, useEffect } from 'react';
// LIBRARIES
import PropTypes from 'prop-types';
//import moment from 'moment';
import moment from 'moment-timezone'
const CountdownTimer = function({countdownType, hideLabel}){
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  
  
  useEffect(() =>{

      let tomorrowDate = moment.tz("America/Denver").add(1, 'days').date();
      if(1 <= tomorrowDate && tomorrowDate <=9){tomorrowDate = '0'+ tomorrowDate;}
      // Need to check if next day is Jan 1, if so, set tomorrow month to 1, otherwise set it to current month
      let tomorrowMonth = moment.tz("America/Denver").add(1, 'days').month() === 12 && moment.tz("America/Denver").add(1, 'days').date() === 1 
      ? 1 
      : (moment.tz("America/Denver").add(1, 'days').month() + 1);

      if(1 <= tomorrowMonth && tomorrowMonth <=9){tomorrowMonth = '0'+ tomorrowMonth;}
      const tomorrowYear = moment.tz("America/Denver").add(1, 'days').year();
      //console.log(tomorrowYear,tomorrowMonth, tomorrowDate );

      //.tz("America/Denver").unix();2014-06-22T09:21:08-07:00
      const eventTime = moment.tz(`${tomorrowYear}-${tomorrowMonth}-${tomorrowDate} 02:00:00`, 'YYYY-MM-DD HH:mm:ss', true, "America/Denver").unix();
      //console.log('ET', eventTime, moment.tz(`${tomorrowYear}-${tomorrowMonth}-${tomorrowDate} 02:00:00`, 'YYYY-MM-DD HH:mm:ss', true, "America/Denver"));
      //console.log(eventTime.isValid());



      let currentTime = moment.tz(Date.now(), "America/Denver").unix(),
      diffTime = eventTime - currentTime,
      interval = 1000,
      duration = moment.duration(diffTime * 1000, 'milliseconds');
      //console.log(eventTime,currentTime, diffTime);

    setInterval(() =>{

      duration = moment.duration(duration.asMilliseconds() - interval, 'milliseconds');
      let h = moment.duration(duration).hours().toString(),
      m = moment.duration(duration).minutes().toString(),
      s = moment.duration(duration).seconds().toString();

      
      h = h.length === 1 ? '0' + h : h;
      m = m.length === 1 ? '0' + m : m;
      s = s.length === 1 ? '0' + s : s;

      // show how many hours, minutes and seconds are left
      setHours(h);
      setMinutes(m);
      setSeconds(s);

    }, interval);
 

    return () => clearInterval(interval);

  }, []);

  function renderCountdown(){
    if(countdownType === 'battle'){
      return (
        <span id="battleCountdown" className={`caution`}>
          <strong className="battle-hours">{hours}</strong>:<strong className="battle-minutes">{minutes}</strong>:<strong className="battle-seconds">{seconds}</strong>
        </span>
      )
    }else if(countdownType === 'ko'){
      return (
        <span id="koCountdown" className={`disabled`}>
          <strong className="ko-hours">{hours}</strong>:<strong className="ko-minutes">{minutes}</strong>:<strong className="ko-seconds">{seconds}</strong>
        </span>
      )
    }
  }

  return(
    <div>
      <span className={hideLabel ? 'hide' : ''}>{countdownType === 'battle' ? 'Battle:  ' : countdownType === 'ko' ?  'KO:  ' : ''}</span>
      { renderCountdown() }
    </div>
  )
};

export default CountdownTimer;

CountdownTimer.propTypes = {
  countdownType : PropTypes.string.isRequired,
  hideLabel : PropTypes.bool,
};