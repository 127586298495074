const privateRoutes = [
  "/",
  "/home",
  "/training",
  "/account",
  "/campaign",
  "/inventory",
];
const publicRoutes = [
  "/welcome",
  "/login",
  "/logout",
  "/contact",
  "/about",
  "/battles",
  "/items",
  "/ranking",
  "/404",
  "/users",
  "/terms-of-service",
  "/privacy-policy",
  "/select-hero",
  "/oauth",
  "/signup-rewards"
];

export { privateRoutes, publicRoutes };
