import React, { useState, useEffect } from 'react';
// LIBRARIES
import PropTypes from 'prop-types';
// COMPONENTS
import DialogTemplate from '../DialogTemplate';
// COMMOMN
import history from '../../../common/history';
import HEROES from '../../../common/includedHeroImages';
import VILLAINS from '../../../common/includedVillainImages';
import ITEMS from '../../../common/includedItemImages';
import dynamicComponent from "../../../common/dynamicComponent";
import { debugErrors } from "../../../common/errorHandler";
import { lowercaseUnderscore, determineSkinType, determineSkinName } from '../../../common/helperFunctions';
import { fetchAllDefaultItems, fetchAllItemsOfTypeForSale } from '../../../services/inventoryService';
// ASSETS
import './ItemDetail.scss';

const battleInstantItems = ['Storm Crow Bone Chimes', 'Smoldering Skull Torch', 'Petrified Power Totem', 'Wave-Swept Battle Conch', 'Plague Token', 'Obsidian Mirror'];

const ItemDetail = function({ modalOpen, setModalOpen, item, avatar, inventoryToggle, consumeItem, unequip, itemDetailPrimaryButton }){
  const [disabledAction, setDisabledAction] = useState(false);

  // No Save, just close modal
  function handleModalSave(){
    // INVENTORY PAGE FUNCTIONALITY - If the inventory toggle function is passed
    if(itemDetailPrimaryButton === 'Use Now'){
      console.log('use now');
      consumeItem(item);
    }else if(itemDetailPrimaryButton === 'OK'){
      return;
    }else if(inventoryToggle){
      // If unequip is true, call inventory toggle with null so the item is unequipped
      if(unequip){
        inventoryToggle(null, item.type);
      // If unequip is false, equip item
      }else{
        inventoryToggle(item, item.type);
      }
    }
    // Close Modal
    setDisabledAction(false);
    setModalOpen(false);
    if(battleInstantItems.includes(item.name)){
      setTimeout(() =>{
        history.push('/?awaitingBattle=true');
      }, 1500);
    }
  }

  function determineImage(item){

    if(item.type === 'skin'){
      return HEROES[determineSkinType(item)][determineSkinName(item, avatar.character)];
    }else if(item.type === 'title'){

    }else{
      return ITEMS[item.type+'s'][lowercaseUnderscore(item.name)] || ITEMS[item.type][lowercaseUnderscore(item.name)];
    }
    return null;
  }

  function renderLore(){
    if(item.lore){
      return <div id="lore" className="display-linebreak">
        <p>{item.lore}</p>
      </div>
    }
    return null;
  }

  function renderEffects(item){
    if(item.effects?.length){
      const mappedItems = item.effects.map((effect,i) => {
          return (
            <li key={i}>
              <h5>{ effect.name }</h5>
              <strong>{ effect.type }</strong><br />
              <p>{ effect.description }</p>
            </li>
          )
      });

      return <div className="effect-properties">
        <h4>Effects</h4>
        <ul>
          { mappedItems }
        </ul>
      </div>

    }
    return null;
  }

  function renderItemTitle(){
    return item.type === 'consumable' ? `${item.name} ${item.count ? `(${item.count})` : null}` : `${item.name}`;
  }

  function _checkIfDisabled(){
    if(disabledAction){
      return true;
    }

    // Check for items that are usable when KOed
    if(avatar.status === "Knocked Out"){
      // If at least one effect is Revive
      if(!item.effects.some(effect => effect.name === 'Revive')){
        return true;
      }
    }
    return false
  }

  const content = <div id="content">
    <div id="primaryRow">
      <div className="col col-details">
        <h4>Item Type</h4>
        <p className="item-type">{item.type === 'skin' ? 'costume' : item.type}</p>
        { renderEffects(item) }
      </div>
      <div id="itemImageContainer" className="col col-image">
        {
          item.icon ?
          dynamicComponent({type : item.type, name : item.name, style: { fontSize: "100px" }, cssClass: item.class})
          : <img id="itemImage"  className={`item-image  ${item.class}` } src={determineImage(item)} alt="Item Details" />
        }
      </div>
    </div>

    <div id="secondaryRow">
      <h4>Description</h4>
      <p>{item.description}</p>
    </div>
  </div>

  // Prevent consuming battle item if the user has already queued for battle
  useEffect(() =>{
    if(item.type === 'consumable'){
      const battleRestricted = battleInstantItems.includes(item.name);
      if(battleRestricted){
        if(avatar.goToBattle || !avatar.restedEnough){
          setDisabledAction(true);
        }
      }else{
        setDisabledAction(false);
      }
    }

  }, [item]);

  return  <div>
    
    <DialogTemplate 
      type={item.type === 'codex' ? 'headless' : 'basic'}
      identifier={item.type === 'codex' ? 'item-detail-codex' : 'item-detail'}
      title={ renderItemTitle() }
      text={renderLore()}
      content={content} 
      cancelButton={true} 
      confirmButton={unequip ? 'Unequip' : itemDetailPrimaryButton ? itemDetailPrimaryButton : inventoryToggle ? 'Equip' : null} 
      isOpen={modalOpen} 
      handleSave={handleModalSave} 
      handleClose={() => setModalOpen(false)} 
      disableButton={_checkIfDisabled()} 
      disableBackdropClick={false} 
      
    /> 
    

  </div>

}

export default ItemDetail;



ItemDetail.propTypes = {
  modalOpen : PropTypes.bool.isRequired,
  setModalOpen : PropTypes.func.isRequired,
  item : PropTypes.object.isRequired,
  avatar : PropTypes.object.isRequired,
  // Specifically for inventory toggling
  inventoryToggle : PropTypes.func,
  unequip : PropTypes.bool,
  consumeItem : PropTypes.func,
  itemDetailPrimaryButton : PropTypes.string.isRequired
};