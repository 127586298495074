import React, { useEffect, useState } from "react";
// LIBRARIES
import PropTypes from "prop-types";
// COMMON
import HEROES from '../../../common/includedHeroImages';
import VILLAIN_IMAGES from "../../../common/includedVillainImages";
import { getAvatarAlias, lowercaseUnderscore, determineSkinType, determineSkinName} from "../../../common/helperFunctions";
import { debugErrors } from '../../../common/errorHandler';
// SERVICES
import { runSpecificBattle } from "../../../services/battleService";
import { getAvatar } from '../../../services/avatarService';
import { fetchBattleReport } from '../../../services/battleService';
// COMPONENTS
import DialogTemplate from "../../Dialog/DialogTemplate";
import { MdLinearScale as PageBreak } from "react-icons/md";
import { FaSkull } from "react-icons/fa";

const AwaitingBattle = function ({ modalOpen, setModalOpen, handleModalAction, avatar, upcomingFoe, upcomingRewards, handleItemClick, runBattleNow, updateState, equipItemsOnAvatar }) {
  const [foe, setFoe] = useState(null);
  const [foeImage, setFoeImage] = useState(null);
  const [avatarImage, setAvatarImage] = useState(null);

  function owlSpeech(foeType) {
    const defaultMessage = `This is what you trained for, youngling. I believe in you.`;
    const useDifferentMessage =
      Math.floor(Math.random() * 9) === 0 ? true : false;
    if (useDifferentMessage) {
      return defaultMessage;
    }
    switch (foeType) {
      case "Shade":
        return `Shine your light, ${avatar.name}! Shades draw bonus power in the Shadows!`;
      case "Wraith":
        return `Your armor will stand for nothing against this menace. You must build your health as part of your defense!`;
      case "Phantasm":
        return `Phantasms are like phantoms, but with bonus armor as well as health! Strike with the elements to take these beefy baddies downs.`;
      case "Phantom":
        return `Phantoms are tough to take down with their Shadowy Influx ability. I hope you can deal some big damage my young tadpole.`;
      case "Banshee":
        return `Some foes know how to steal your life force for their own use! Be leary of these parasites.`;
      case "Specter":
        return `Your breath is your source of life, my protégé. Specters know how to use fear as a weapon, reducing your health.`;
      case "Poltergeist":
        return `Poltergeist are tricksters, but not to be taken lightly! They'll scramble your stats and possibly leave you very vulnerable.`;
      case "Apparition":
        return `These foes know how to negate some of your own power. Elemental power is immune, however.`;
      case "Shadow-Self":
        return `Are you ready to face your Shadow, ${avatar.name}? It can be the most difficult thing you'll have to do. Rise above, and you'll be stronger than ever!`;
      case "Plaguebringer":
        return `${avatar.name}, exercise extreme caution! Titans have unbridled power and few have what it takes. If you survive, Plaguebringer will leave you INFECTED for a day, losing health instead of recovering.`;
      case "Gusty Rascal":
      case "Wheezing Jinn":
      case "Storming Oni":
        return `Hold onto your hat ${avatar.name}, you've got an Air Elemental in front of you. Let's hope you've got some FIRE inside you, and not just EARTH and WATER!`;
      case "Rock Skipper":
      case "Granite Golem":
      case "Hulking Aggro Crag":
        return `These EARTH elementals are dangerous foes. Watch out for their painful 'Thorns Damage', which makes your own attacks work against you! Water is a pretty good counter to Earth though, dealing extra damage and recovering health on hit!`;
      case "Flame Fiend":
      case "Burning Jinn":
      case "Scorching Archfiend":
        return `${avatar.name}, your body heat is too much, don't stand so close!, Oh, that's a FIRE elemental. Heroes with strong FIRE of their own will nullify much of their damage, while AIR Heroes will struggle.`;
      case "Splash Artist":
      case "Cyclonic Siren":
      case "High Priestess of the Tides":
        return "Water elementals are formidable foes, my friend. They're more difficult to take down, since they'll recover health every attack! FIRE works well, dealing extra damage to water, and giving a chance to 'Critical Strike'! The sooner you end the battle, the better."
        default:
        return defaultMessage;
    }
  }

  function handleModalSave() {
    // If A battle was initiated from a consumable, and the user clicked 'Battle', run battle then fetch avatar and report, finally close modal and open battle report
    if(runBattleNow){

      // CALL BATTLE ENDPOINT
      runSpecificBattle({ avatarID : avatar.id })
      .then((data) =>{
        if(data.error){
          return debugErrors(data.error);
        }
        
        const promises = [getAvatar({ email : avatar.owner }), fetchBattleReport({ avatarID : avatar.id })];
        return Promise.allSettled(promises)
      }).then((results) =>{
        if(results[0].status !== 'fulfilled' || results[1].status !== 'fulfilled'){
          throw new Error('Unable to Fetch post-battle Avatar or Battle Report');
        }
        const { avatar } = results[0].value;
        // Takes item instance IDs and assigns full items to the hero under 'equipped' property
        // updates state with new avatar
        equipItemsOnAvatar(avatar);
        
        const battleReport = results[1].value.result;
        updateState({ battleReport });
        handleModalAction(true);
        setModalOpen(false);
      }).catch((error)=>{
        debugErrors(error);
      });
 
      
    }else{
      handleModalAction();
      setModalOpen(false);
    }
  }

  function determineFoeImage(foe) {
    if (foe.type === "Shadow-Self") {
      return VILLAIN_IMAGES.spirits["shadow_" + lowercaseUnderscore(avatar.character)];
    }
    return VILLAIN_IMAGES[foe.class.toLowerCase()][lowercaseUnderscore(foe.type)];
  }

  function renderUpcomingRewards(){
    if(!upcomingRewards || !upcomingRewards.length){
      return null;
    }

    const mappedRewards = upcomingRewards.map((item, i ) => {
      return <span key={i} onClick={() => handleItemClick(item)}  className="reward-item"><span className="reward-type">{item.type.toUpperCase()} - </span><strong>{item.name}</strong></span>
    });
    return <div id="potentialRewards">
      <strong>Rewards:</strong>
      { mappedRewards }
    </div>
  }

  useEffect(() => {
    if (upcomingFoe) {
      setFoe(upcomingFoe);
      setFoeImage(determineFoeImage(upcomingFoe));
    }
  }, [upcomingFoe]);

  useEffect(()=>{
    let skin = avatar.equipped.find(item => item.type === 'skin');
    setAvatarImage(skin);
  }, [avatar]);

  const content = (
    <div>
      <div className="page-break">
        <PageBreak />
      </div>
      <div className="action-to-take">
        <FaSkull style={{ fontSize: "1.5em", color: "black" }} />
        &nbsp;{" "}
        <strong>
          <span className="highlight">{avatar.name}</span> will be matched up
          against{" "}
          <span className="highlight">
            {upcomingFoe ? upcomingFoe.name : "a foe"}
          </span>{" "}
          tonight at 2 AM MST!
          <br />
          (for Europeans, that's 10 am CET)
        </strong>
      </div>
      <div className="more-content">
        <div id="matchupRow">
          <div id="avatarText" className="primary-row">
            <div id="avatar">
              <img id="avatarImage" className={avatar.equipped.find(item => item.type === 'skin')?.class} src={HEROES[determineSkinType(avatarImage)][determineSkinName(avatarImage, avatar.character)]} alt={'Hero'} />
            </div>
            <strong>{avatar.name}</strong>
            <span>{getAvatarAlias(avatar.character)}</span>
          </div>
          <div id="vs" className="primary-row">
            <strong>VS</strong><br />
            <div>
              { renderUpcomingRewards() }
            </div>
          </div>
          <div id="foeImageContainer" className="primary-row">
            {foe ? (
              <div id="foeText">
                <img className={`foe`} src={foeImage} alt="foe" />
                <br />
                <br />
                <strong>{foe.name}</strong>
                <br />
                <span>{foe.type}</span>
              </div>
            ) : (
              <div id="questionMark">
                <strong>?</strong>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <DialogTemplate
        type={"speech"}
        identifier={"awaiting-battle"}
        character={"owl"}
        title={null}
        text={owlSpeech(upcomingFoe ? upcomingFoe.type : null)}
        content={content}
        cancelButton={true}
        secondaryButton={runBattleNow ? null : 'OK'}
        confirmButton={runBattleNow ? 'Battle' : null}
        isOpen={modalOpen}
        handleSave={handleModalSave}
        handleClose={() => setModalOpen(false)}
        disableButton={false}
        disableBackdropClick={false}
      />
    </div>
  );
};

export default AwaitingBattle;

AwaitingBattle.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  handleModalAction: PropTypes.func.isRequired,
  avatar: PropTypes.object.isRequired,
  upcomingFoe: PropTypes.object,
  handleItemClick : PropTypes.func.isRequired,
  runBattleNow : PropTypes.bool,
  updateState : PropTypes.func
};
