import React, { useState, useEffect } from 'react';
// LIBRARIES
import PropTypes from 'prop-types';
// COMPONENTS
import { Link } from 'react-router-dom';
import DialogTemplate from '../../Dialog/DialogTemplate';

const NewPatch = function({modalOpen, setModalOpen, patchDetails, skipRefresh}){
  const [ headline, setHeadline ] = useState('New Patch Details');
  const [ openingRemarks, setOpeningRemarks ] = useState(null);
  const [ closingRemarks, setClosingRemarks ] = useState(null);
  const [ newFeatures, setNewFeatures ] = useState(null);
  const [ bugFixes, setBugFixes ] = useState(null);
  const [ balanceChanges, setBalanceChanges ] = useState(null);
  const [ miscDetails, setMiscDetails ] = useState(null);

  function renderOpeningRemarks(){
    if(!openingRemarks){return null;}

    return <p>{ openingRemarks }</p>
  }

  // No Save, just close modal
  function handleModalSave(refresh){
    setModalOpen(false);
    if(refresh){
      setTimeout(()=>{
        window.location.reload();
      }, 750);
    }
  }

  function renderNewFeatures(){
    if(!newFeatures){ return null; }
    const liList = newFeatures.map((item, i) =>{
      return <li key={i}><strong>{item.title}</strong><br /><span>{item.description}</span></li>
    });
    return <div id="newFeatures">
      <strong className="patch-detail-title brand-highlight">New Features:</strong><br />
      <ul> { liList } </ul>
    </div>
  }

  function renderBugFixes(){
    if(!bugFixes){ return null; }
    const liList = bugFixes.map((item, i) =>{
      return <li key={i}>{item}</li>
    });
    return <div id="newFeatures">
      <strong className="patch-detail-title brand-highlight">Bug Fixes:</strong><br />
      <ul> { liList } </ul>
    </div>
  }

  function renderBalanceChanges(){
    if(!balanceChanges){ return null; }
    const liList = balanceChanges.map((item, i) =>{
      return <li key={i}>{item}</li>
    });
    return <div id="newFeatures">
      <strong className="patch-detail-title brand-highlight">Balance Changes:</strong><br />
      <ul> { liList } </ul>
    </div>
  }

  function renderMiscDetails(){
    if(!miscDetails){ return null; }
    const liList = miscDetails.map((feature, i) =>{
      return <li key={i}>{feature}</li>
    });
    return <div id="newFeatures">
      <strong className="patch-detail-title brand-highlight">Misc:</strong><br />
      <ul> { liList } </ul>
    </div>
  }

  function renderClosingRemarks(){
    if(!closingRemarks){return null;}

    return <p>{ closingRemarks }</p>
  }

  useEffect(() =>{
    setHeadline(patchDetails?.headline);
    setOpeningRemarks(patchDetails?.openingRemarks);
    setNewFeatures(patchDetails?.newFeatures);
    setBugFixes(patchDetails?.bugFixes);
    setBalanceChanges(patchDetails?.balanceChanges);
    setMiscDetails(patchDetails?.miscDetails);
    setClosingRemarks(patchDetails?.closingRemarks);

  }, [patchDetails]);

  const content = <div>
    <div className="more-content">
      <section id="patchDetailsSection">
        { renderOpeningRemarks() }
        { renderNewFeatures() }
        { renderBugFixes() }
        { renderBalanceChanges() }
        { renderMiscDetails() }
        <br />
        { renderClosingRemarks() }
      </section>
      <div id="linkToReddit">
        <strong>Join the discussion about the latest patch on <a href="https://reddit.com/r/herofit" target="_blank">our subreddit.</a></strong>
      </div>
    </div>
  </div>

  return  <div>
    {
      // If version mismatch, need force user to refresh the page
      process.env.REACT_APP_VERSION !== patchDetails.version && !skipRefresh ? 
        <DialogTemplate 
          type={'basic'}
          identifier={'new-patch'}
          title={headline}
          text={`Refresh the page to receive version ${patchDetails.version}`}
          content={content} 
          cancelButton={false} 
          secondaryButton={null} 
          confirmButton="Refresh"
          isOpen={modalOpen} 
          handleSave={() => handleModalSave(true)} 
          handleClose={() => setModalOpen(false)} 
          disableButton={false} 
          disableBackdropClick={true} 
        /> 
      :
        <DialogTemplate 
          type={'basic'}
          identifier={'new-patch'}
          title={headline}
          text={null}
          content={content} 
          cancelButton={true} 
          secondaryButton="OK" 
          isOpen={modalOpen} 
          handleSave={handleModalSave} 
          handleClose={() => setModalOpen(false)} 
          disableButton={false} 
          disableBackdropClick={false} 
        /> 
    }

  </div>

}

export default NewPatch;

NewPatch.propTypes = {
  modalOpen : PropTypes.bool.isRequired,
  setModalOpen : PropTypes.func.isRequired,
  patchDetails : PropTypes.object.isRequired,
  skipRefresh : PropTypes.bool.isRequired
};