import React, { useState, useEffect,  } from 'react';
// LIBRARIES
import PropTypes from 'prop-types';
// COMPONENTS
import DialogTemplate from '../../../Dialog/DialogTemplate';
import { useDidMount } from '../../../../common/hookHelpers';
import { BsFillPlusSquareFill } from 'react-icons/bs';
import * as Icons from '../../../../common/icons';
import SuccessModalOverlay from '../../../Dialog/Overlays/Success';
import './SpendQP.scss';

const SpendQP = function({ modalOpen, setModalOpen, avatar, handleStatChange, goToItems }){
  const didMount = useDidMount();
  const [success, setSuccess] = useState(false);
  const [power, setPower] = useState(0);
  const [maxHealth, setMaxHealth] = useState(0);
  const [recovery, setRecovery] = useState(0);
  const [armor, setArmor] = useState(0);
  const [fire, setFire] = useState(0);
  const [earth, setEarth] = useState(0);
  const [water, setWater] = useState(0);
  const [air, setAir] = useState(0);
  const [aether, setAether] = useState(0);
  const [powerClass, setPowerClass] = useState(false);
  const [maxHealthClass, setMaxHealthClass] = useState(false);
  const [recoveryClass, setRecoveryClass] = useState(false);
  const [armorClass, setArmorClass] = useState(false);
  const [fireClass, setFireClass] = useState(false);
  const [earthClass, setEarthClass] = useState(false);
  const [waterClass, setWaterClass] = useState(false);
  const [airClass, setAirClass] = useState(false);
  const [aetherClass, setAetherClass] = useState(false);

  function handleClick(stat, amount){
    if(avatar.qp > 1){
      handleStatChange(stat, amount);
    }else if(avatar.qp === 1){
      handleStatChange(stat, amount, true);
    }
  }

  function handleCloseAction(){
    setModalOpen(false);
    setSuccess(false);
  }

  const content = <div id="spendQP">
      <div className="column">
      {/*  AETHER - Late game only (after level 100) */}
      {
        avatar.albedo ? 
          <div className="qp-item qp-item-aether">
            <div className="stat stat-button">
              <button onClick={() =>{ handleClick('aether', 1) }}>
                <BsFillPlusSquareFill />
              </button>
            </div>
            <div className="stat stat-increase">
              <strong>+1</strong>
            </div>
            <div className="stat stat-name">
              <strong>Aether</strong>
              <p>Temporal Distortion</p>
              <p>Recover Health from Workouts</p>
              <Icons.Aether />
            </div>
            <input className={`stat stat-current ${aetherClass ? 'highlight' : 'muted'}`} disabled value={Math.floor(aether)} />
          </div>
        : null
      }
        
      <div className="qp-item qp-item-power">
          <div className="stat stat-button">
            <button onClick={() =>{ handleClick('power', 1) }}>
              <BsFillPlusSquareFill />
            </button>
          </div>
          <div className="stat stat-increase">
            <strong>+1</strong>
          </div>
          <div className="stat stat-name">
            <strong>Power</strong>
            <p>Primary Damage</p>
            <Icons.Power />
          </div>
          <input className={`stat stat-current ${powerClass ? 'highlight' : 'muted'}`} disabled value={Math.floor(power)} />
        </div>
        
        <div className="qp-item qp-item-maxHealth">
          <div className="stat stat-button">
            <button onClick={() =>{ handleClick('maxHealth', 1) }}>
              <BsFillPlusSquareFill />
            </button>
          </div>
          <div className="stat stat-increase">
            <strong>+1</strong>
          </div>
          <div className="stat-name">
            <strong>Health</strong>
            <p>Increases Maximum Health</p>
            <Icons.Health />
          </div>
          <input className={`stat stat-current ${maxHealthClass ? 'highlight' : 'muted'}`} disabled value={Math.floor(maxHealth)} />
        </div>

        <div className="qp-item qp-item-recovery">
          <div className="stat stat-button">
            <button onClick={() =>{ handleClick('recovery', 1) }}>
              <BsFillPlusSquareFill />
            </button>
          </div>
          <div className="stat stat-increase">
            <strong>+1</strong>
          </div>
          <div className="stat stat-name">
            <strong>Recovery</strong>
            <p>Regain Health Faster</p>
            <p>Chance to Revive Early</p>
            <Icons.Recovery />
          </div>
          <input className={`stat stat-current ${recoveryClass ? 'highlight' : 'muted'}`} disabled value={Math.floor(recovery)} />
        </div>

        
        

        <div className="qp-item qp-item-armor">
          <div className="stat stat-button">
            <button onClick={() =>{ handleClick('armor', 1) }}>
              <BsFillPlusSquareFill />
            </button>
          </div>
          <div className="stat stat-increase">
            <strong>+1</strong>
          </div>
          <div className="stat-name">
            <strong>Armor</strong>
            <p>Non-Elemental Resistance</p>
            <Icons.Armor />
          </div>
          <input className={`stat stat-current ${armorClass ? 'highlight' : 'muted'}`} disabled value={Math.floor(armor)} />
        </div>

      
      </div>
      
      <div className="column">


      <div className="qp-item qp-item-air">
          <div className="stat stat-button">
            <button  onClick={() =>{ handleClick('air', 1) }}>
              <BsFillPlusSquareFill />
            </button>
          </div>
          <div className="stat stat-increase">
            <strong>+1</strong>
          </div>
          <div className="stat stat-name">
            <strong>Air</strong>
            <p>Evasion</p>
            <p>Resistance to Air</p>
            <p>Bonus Damage to Earth & Water</p>
            <Icons.Air />
          </div>
          <input className={`stat stat-current ${airClass ? 'highlight' : 'muted'}`} disabled value={Math.floor(air)} />
        </div>

        <div className="qp-item qp-item-water">
          <div className="stat stat-button">
            <button onClick={() =>{ handleClick('water', 1) }}>
              <BsFillPlusSquareFill />
            </button>
          </div>
          <div className="stat stat-increase">
            <strong>+1</strong>
          </div>
          <div className="stat stat-name">
            <strong>Water</strong>
            <p>Vampiric Touch</p>
            <p>Resistance to Water</p>
            <p>Bonus Damage to Fire & Earth</p>
            <Icons.Water />
          </div>
          <input className={`stat stat-current ${waterClass ? 'highlight' : 'muted'}`} disabled value={Math.floor(water)} />
        </div>


        <div className="qp-item qp-item-fire">
          <div className="stat stat-button">
            <button onClick={() =>{ handleClick('fire', 1) }}>
              <BsFillPlusSquareFill />
            </button>
          </div>
          <div className="stat stat-increase">
            <strong>+1</strong>
          </div>
          <div className="stat stat-name">
            <strong>Fire</strong>
            <p>Critical Strike</p>
            <p>Resistance to Fire</p>
            <p>Bonus Damage to Air & Water</p>
            <Icons.Fire />
          </div>
          <input className={`stat stat-current ${fireClass ? 'highlight' : 'muted'}`} disabled value={Math.floor(fire)} />
        </div>

        <div className="qp-item qp-item-earth">
          <div className="stat stat-button">
            <button onClick={() =>{ handleClick('earth', 1) }}>
              <BsFillPlusSquareFill />
            </button>
          </div>
          <div className="stat stat-increase">
            <strong>+1</strong>
          </div>
          <div className="stat stat-name">
            <strong>Earth</strong>
            <p>Thorns Damage</p>
            <p>Resistance to Earth</p>
            <p>Bonus Damage to Air & Fire</p>
            <Icons.Earth />
          </div>
          <input className={`stat stat-current ${earthClass ? 'highlight' : 'muted'}`} disabled value={Math.floor(earth)} />
        </div>

      </div>
      
      { success ? <SuccessModalOverlay  message={'Quantum Points Activated'} /> : null }
    </div> 

   // On load of avatar, set existing values to local state, highlight on click
   useEffect(() =>{

    if(avatar){
      setPower(avatar.power);
      setMaxHealth(avatar.maxHealth);
      setRecovery(avatar.recovery);
      setArmor(avatar.armor);
      setFire(avatar.fire);
      setEarth(avatar.earth);
      setWater(avatar.water);
      setAir(avatar.air);
      setAether(avatar.aether);

      if(didMount && avatar.power !== power){
        setPowerClass(true);
      }else if(didMount && avatar.maxHealth !== maxHealth){
        setMaxHealthClass(true);
      }else if(didMount && avatar.recovery !== recovery){
        setRecoveryClass(true);
      }else if(didMount && avatar.armor !== armor){
        setArmorClass(true);
      }else if(didMount && avatar.fire !== fire){
        setFireClass(true);
      }else if(didMount && avatar.water !== water){
        setWaterClass(true);
      }else if(didMount && avatar.earth !== earth){
        setEarthClass(true);
      }else if(didMount && avatar.air !== air){
        setAirClass(true);
      } else if(didMount && avatar.aether !== aether){
        setAetherClass(true);
      } 

    }

  }, [avatar, maxHealth, recovery, power, armor, fire, earth, water, air]);

  useEffect(() =>{
    if(didMount && avatar.qp === 0){
      setSuccess(true);
      setTimeout(() =>{
        setModalOpen(false);
        setTimeout(() =>{
          setSuccess(false);
        },1000);
      }, 2000);
    }
  },[avatar]);


  return  <div>

    <DialogTemplate 
      type={'basic'}
      identifier={'spend-qp'}
      title={`Quantum Points: ( ${ avatar.qp } )`}
      text={avatar.status === 'New Recruit' ? 'Spend now or save for later' : null}
      content={content} 
      cancelButton={true} 
      secondaryButton={null}
      isOpen={modalOpen} 
      handleClose={() => handleCloseAction()} 
      disableButton={false}
      disableBackdropClick={false} 
    /> 

  </div>

}

export default SpendQP;

SpendQP.propTypes = {
  modalOpen : PropTypes.bool.isRequired,
  setModalOpen : PropTypes.func.isRequired,
  avatar : PropTypes.object.isRequired,
  handleStatChange : PropTypes.func.isRequired,
  goToItems : PropTypes.func.isRequired
};