import _axios from 'axios';
import axiosRetry from 'axios-retry';
import { off } from 'process';
import { axiosOptions } from '../common/axiosDefaults';
import { handleHttpError } from '../common/errorHandler';
import { setJwtInLocalStorage } from '../common/jwtModule';

let endpoint = process.env.REACT_APP_HF_ENDPOINT;

let axios = _axios.create();
axiosRetry(axios, {retries: 3});

const login = function(body){
    return axios.post(`${endpoint}auth/login`, body, axiosOptions())
    .then(({ data }) => {
        if(data.error){
            return data.error;
        }
        const { user, tokenObject } = data.data;
        setJwtInLocalStorage(tokenObject);
        return data.data;
    }).catch(({ request, response }) => {
        return handleHttpError(request, response);
    });
};

const register = function(body){
    return axios.post(`${endpoint}auth/register`, body, axiosOptions())
    .then(({ data }) => {
        const { user, tokenObject } = data.data;
        setJwtInLocalStorage(tokenObject);
        return { user };
    }).catch(({ request, response }) => {
        return handleHttpError(request, response);
    });
};

const resetPassword = function(body){
    return axios.post(`${endpoint}auth/reset-password`, body, axiosOptions())
    .then(({ data }) => {
        return data;
    }).catch(({ request, response }) => {
        return handleHttpError(request, response);
    });
};

// Only Meant for password reset
const sendPasswordResetEmailVerification = function(body){
    return axios.post(`${endpoint}auth/reset-password-confirm-email`, body, axiosOptions())
    .then(({ data }) => {
        return data;
    }).catch(({ request, response }) => {
        return handleHttpError(request, response);
    });
};

const getStravaClientCredentials = function(){
    return axios.get(`${endpoint}auth/strava-client-credentials`, axiosOptions())
    .then(({ data }) =>{
        return data.data;
    }).catch(({ request, response }) => {
        return handleHttpError(request, response);
    });
};

const insertStravaCredentials = function(body){
    return axios.post(`${endpoint}auth/strava-credentials`, body, axiosOptions('cake'))
    .then(({ data }) => {
        return data.data;
    }).catch(({ request, response }) => {
        return handleHttpError(request, response);
    })
};


const fetchLatestPatchDetails = function(){

    return axios.get(`${endpoint}auth/fetch-latest-patch-details`, axiosOptions())
    .then(({ data }) =>{
      return data.data;
    }).catch(({ request, response }) => {
        return handleHttpError(request, response);
    });
}

const updateSeenLatestPatch = function(body){
    return axios.post(`${endpoint}auth/update-seen-latest-patch`, body, axiosOptions())
    .then(({ data }) =>{
      return data.data;
    }).catch(({ request, response }) => {
        return handleHttpError(request, response);
    });
};

const sendVerificationCode = function(body){
    // For testing locally on the app
    if(endpoint.includes('localhost')){
        endpoint = "http://172.30.200.89:3001/";
        //endpoint = "http://192.168.1.10:3001/";
    }
    return axios.post(`${endpoint}auth/validated-email`, body, axiosOptions())
    .then(({ data }) =>{
      return data.data;
    }).catch(({ request, response }) => {
        return handleHttpError(request, response);
    });
}

// Only meant for initial registration email confirm
const resendEmailConfirmation = function(body){
    return axios.post(`${endpoint}auth/resend-email-confirmation`, body, axiosOptions())
    .then(({ data }) =>{
      return data.data;
    }).catch(({ request, response }) => {
        return handleHttpError(request, response);
    });
}


export { login, register, getStravaClientCredentials, insertStravaCredentials, fetchLatestPatchDetails, updateSeenLatestPatch, resetPassword, sendPasswordResetEmailVerification, sendVerificationCode, resendEmailConfirmation }