import _axios from 'axios';
import axiosRetry from 'axios-retry';
import { axiosOptions } from '../common/axiosDefaults';
import { handleHttpError } from '../common/errorHandler';
const endpoint = process.env.REACT_APP_HF_ENDPOINT;

let axios = _axios.create();
axiosRetry(axios, {retries: 3});

const emailFeedbackData = function(body){
    return axios.post(`${endpoint}email/feedback-data`, body, axiosOptions())
    .then(({ data }) =>{
        return data.data;
    }).catch(({ request, response }) => {
        return handleHttpError(request, response);
    });
};

const emailContactForm = function(body){
    return axios.post(`${endpoint}email/contact-form`, body, axiosOptions())
    .then(({ data }) =>{
        return data;
    }).catch(({ request, response }) => {
        return handleHttpError(request, response);
    });
};

const emailAppError = function(body){
    body.errorLocation = body.status === 400 || body.status === 500 ? 'Backend' : 'Frontend';
    return axios.post(`${endpoint}email/app-error`, body, axiosOptions())
    .then(({ data }) =>{
        console.log('APP ERROR Email Delivered', data);
    }).catch(({ request, response }) => {
        console.log('Error sending email - response', response);
        return handleHttpError(request, response);
    });
};


export { emailFeedbackData, emailContactForm, emailAppError };