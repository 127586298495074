import { getJwtInLocalStorage } from './jwtModule';

function axiosOptions(){
  const jwtHeader = getJwtInLocalStorage();
  // If a non-expired JWT is saved locally, attach it to the request
  if(jwtHeader){
      return { headers : { Authorization : jwtHeader }, withCredentials : true };
  }else{
      return { withCredentials : true };
  }
}

function axiosDeleteConfig(body){
  const jwtHeader = getJwtInLocalStorage();
  return { headers : { Authorization : jwtHeader }, data : body  , withCredentials : true }
}

export { axiosOptions, axiosDeleteConfig };