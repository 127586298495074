import React, { useState } from 'react';
// LIBRARIES
import PropTypes from 'prop-types';
// COMPONENTS
import DialogTemplate from '../../../Dialog/DialogTemplate';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';


const Feedback = function({modalOpen, setModalOpen, handleModalAction}){
  const [opinion, setOpinion] = useState(null);
  const [openResponse, setOpenResponse] = useState('');


  const handleTextChange = (e) => {
    setOpenResponse(e.target.value);
  };

  function handleModalSave(){

    setModalOpen(false);
    handleModalAction({type : 'feedback', title : 'Is the Battle Report clear & useful?', data : { opinion, openResponse }})
  }

  function handleRadioChange(e){
    setOpinion(e.target.value);
  }

  const content = <div>
    <form id="opinionForm">
      <FormControl component="fieldset">
        <RadioGroup aria-label="gender" name="gender1" value={opinion} onChange={handleRadioChange}>
          <FormControlLabel value="I never read it..." control={<Radio />} label="I never read it..." />
          <FormControlLabel value="I don't think so. There's too much info that's unexplained" control={<Radio />} label="I don't think so. There's too much info that's unexplained" />
          <FormControlLabel value="I don't think so. The way the info is displayed is confusing" control={<Radio />} label="I don't think so. The way the info is displayed is confusing" />
          <FormControlLabel value="It's useful, but there's definitely room for improvement with how it's presented" control={<Radio />} label="It's useful, but there's definitely room for improvement with how it's presented" />
          <FormControlLabel value="I really like it! It gives life to the battles & helps me improve my hero" control={<Radio />} label="I really like it! It gives life to the battles & helps me improve my hero" />
        </RadioGroup>
      </FormControl>
      
      <TextField
        id="standard-multiline-static"
        multiline
        rows={4}
        placeholder={"What specifically is hard to understand with the report? Any suggestions?"}
        variant="outlined"
        onChange={handleTextChange}
      />
    </form>
   
  </div>;

  return  <div>

    <DialogTemplate 
      type={'basic'}
      identifier={'feedback'}
      title={'Is the Battle Report clear & useful?'}
      text={"The Battle Report is the screen that shows the hero & foe stats after a battle"}
      content={content} 
      cancelButton={true} 
      confirmButton="Send" 
      isOpen={modalOpen} 
      handleSave={handleModalSave} 
      handleClose={() => setModalOpen(false)} 
      disableButton={opinion ? false : true} 
      disableBackdropClick={false} 
    /> 

  </div>

}

export default Feedback;

Feedback.propTypes = {
  modalOpen : PropTypes.bool.isRequired,
  setModalOpen : PropTypes.func.isRequired,
  handleModalAction : PropTypes.func.isRequired,
};