import React, { useState, useEffect } from 'react';
// LIBRARIES
import PropTypes from 'prop-types';
// COMMON
import { debugErrors } from "../../../../common/errorHandler";
import history from '../../../../common/history';
// COMPONENTS
import DialogTemplate from '../../DialogTemplate';
import { InputLabel , Input, Link} from '@material-ui/core';
import Loading from '../../Overlays/Loading';
import { login, resendEmailConfirmation } from "../../../../services/authService";
import { getAvatar } from '../../../../services/avatarService';
// STYLES
import './AuthModal.scss';

const Login = function({ modalOpen, setModalOpen, updateState, toggleLoginRegister, toggleLoginForgotPassword, handlePostLogin }){
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formIsValid, setFormIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [helperText, setHelperText] = useState(null);
  const [user, setUser] = useState(null);
  
  function handleLogin(){
    setLoading(true);
    setHelperText('');
    login({ email, password })
    .then((data) =>{
      if(data.error){
        const error = data.error;
        if(error?.meta?.oldUserMigration){
          toggleLoginForgotPassword('Forgot Password', true);
          updateState({ alerts : [{ type : 'error', message : error.message }] });
          return setLoading(false);
        }
        updateState({ alerts : [{ type : 'error', message : error.message }] });
        setLoading(false);
        return debugErrors(error);
      }
      const { user } = data;

      // User hasn't confirmed email yet
      if(!user.active){
        updateState({ alerts : [{ type : 'warning', message : "Please verify your account by clicking the link in the message sent to your email when you registered" }] });
        setUser(user);
        return setLoading(false);
      }

      getAvatar({ email : user.email })
      .then((data) =>{
        if(data.error){
          const error = data.error;
          updateState({ alerts : [{ type : 'error', message : error.message }] });
          setLoading(false);
          return debugErrors(error);
        }
        const { avatar } = data;
        //console.log(user, avatar );
        // Only perform this action if user logs in from home page
        if(handlePostLogin){
          setTimeout(() =>{
            setLoading(false);
            return handlePostLogin(user, avatar);
          }, 1500);
        }
      })
    })
  }

  function handleResendEmailConfirmation(e){
    e.preventDefault();

    resendEmailConfirmation({ email : user.email || email, emailCode : user.emailCode })
    .then((data) =>{
      if(data.error){
        const error = data.error;
        updateState({ alerts : [{ type : 'error', message : error.message }] });
        return debugErrors(error);
      }
      return updateState({ alerts : [{ type : 'success', message : "Please check your email to verify account. Check your spam folder if the message is not in your inbox. Sometimes the email takes a few minutes to arrive." }] });
    });
  }

  useEffect(() => {
    if(email.includes('@')){
      if(password.length >= 8){
        return setFormIsValid(true);
      }
    }
    return setFormIsValid(false);
  }, [email, password]);

  function handleRegisterToggle(){
    const path = history.location.pathname;
    if(path === '/welcome'){
      return null;
    }
    return <strong>Don't have an account? <span className="link-text" onClick={() => toggleLoginRegister('Register')}>Sign up here.</span></strong>;
  }

  const content = (
    <form id="loginForm" className="auth-form">
      <div id="loginEmail" className="input-container">
        <InputLabel><strong>Email <span className="warning">*</span></strong></InputLabel>
        <Input autoFocus placeholder="Enter Email" type="email" fullWidth required onChange={event => setEmail(event.target.value)} value={email} />
      </div>

      <div id="loginPassword" className="input-container">
        <InputLabel><strong>Password <span className="warning">*</span></strong></InputLabel>
        <Input placeholder="Enter Password" type="password" fullWidth required onChange={event => setPassword(event.target.value)} />
        {/* <div id="forgotPassword" onClick={() => toggleLoginForgotPassword('Forgot Password')}>
          <span className="link-text">Forgot Password?</span>
        </div> */}
      </div>

      <div id="helperText" className={helperText ? '' : 'hide'}>
        <strong className="warning">{ helperText }</strong>
      </div>
      <br />
        { handleRegisterToggle() }
      <br />
        { 
          user && !user.active ?
            <Link to="/" onClick={(e) => handleResendEmailConfirmation(e)}>Resend Email Confirmation</Link>
          : null
        }
      <br />
      { loading ? <Loading /> : null }

      
    </form>
  );

  return(
    <DialogTemplate 
    type={'basic'}
    identifier={`login`}
    title={"Login"}
    content={content} 
    cancelButton={true} 
    confirmButton="Log In" 
    isOpen={modalOpen} 
    handleSave={handleLogin} 
    handleClose={() => setModalOpen(false)} 
    closeOnSave={false}
    disableButton={formIsValid ? false : true} 
    disableBackdropClick={true} 
  />
  )
}

export default Login;

Login.propTypes = {
  modalOpen : PropTypes.bool.isRequired,
  setModalOpen : PropTypes.func.isRequired,
  updateState : PropTypes.func.isRequired,
  toggleLoginRegister : PropTypes.func.isRequired, 
  toggleLoginForgotPassword : PropTypes.func.isRequired,
  handlePostLogin : PropTypes.func
};
