import axios from 'axios';

const exchangeStravaAuthCode = function(authData, resolve, reject){
  return axios.post(`https://www.strava.com/api/v3/oauth/token?client_id=${authData.clientId}&client_secret=${authData.clientSecret}&code=${authData.code}&grant_type=authorization_code`,{withCredentials:true})
  .then((data) =>{
    resolve(data.data);
  }).catch((error) => {
    let e = error.response;
    reject({status : e.status, message : e.data.message, debug : JSON.stringify(e.data.errors[0]) });
  })
};

const getStravaActivityData = function(stravaAccessToken, resolve, reject){

  return axios.get(`https://www.strava.com/api/v3/athlete/activities`, { headers: { 'Authorization': `Bearer ${stravaAccessToken}` } })
  .then((data) => {
    resolve(data.data);
  }).catch((error) => {
    reject({status : 500, message : "Error connecting to Strava", debug :  error.toString() });
  })
};

// Uses permanent refresh token to get an access token, needed for further requests
const getNewAccessToken = function(authData, resolve, reject){
       
  return axios.post(`https://www.strava.com/api/v3/oauth/token?client_id=${authData.clientId}&client_secret=${authData.clientSecret}&refresh_token=${authData.refreshToken}&grant_type=refresh_token`, {withCredentials:true})
  .then((data) => {
    resolve(data.data);
  }).catch((error) => {
    reject({status : 500, message : "Error connecting to Strava", debug :  error.toString() });
  })
};

// Uses permanent refresh token to get an access token, needed for further requests
const getStravaUserId = function(stravaAccessToken, resolve, reject){
        
  return axios.get(`https://www.strava.com/api/v3/athlete`, { headers: { 'Authorization': `Bearer ${stravaAccessToken}` } })
  .then((data) => {
    resolve(data.data);
  }).catch((error) => {
    let e = error.response;
    reject({status : e.status, message : e.data.message, debug : JSON.stringify(e.data.errors[0]) });
  })
};

export { exchangeStravaAuthCode, getStravaActivityData, getNewAccessToken, getStravaUserId }