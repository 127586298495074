import _axios from 'axios';
import axiosRetry from 'axios-retry';
import { axiosOptions, axiosDeleteConfig } from '../common/axiosDefaults';
import { handleHttpError } from '../common/errorHandler';
const endpoint = process.env.REACT_APP_HF_ENDPOINT;

let axios = _axios.create();
axiosRetry(axios, {retries: 3});

const fetchAvatarInventory = function (body) {
  return axios.post(`${endpoint}inventory/fetch-avatar-inventory`, body, axiosOptions())
    .then(({ data }) => {
      return data.data;
    }).catch(({ request, response }) => {
      return handleHttpError(request, response);
    });
};

const equipItem = function (body) {
  return axios.post(`${endpoint}inventory/equip-item`, body, axiosOptions())
    .then(({ data }) => {
      return data.data;
    }).catch(({ request, response }) => {
      return handleHttpError(request, response);
    });
};

const equipUnequipItem = function (body) {
  return axios.post(`${endpoint}inventory/equip-unequip-item`, body, axiosOptions())
    .then(({ data }) => {
      return data.data;
    }).catch(({ request, response }) => {
      return handleHttpError(request, response);
    });
};

const unequipItem = function (body) {
  return axios.post(`${endpoint}inventory/unequip-item`, body, axiosOptions())
    .then(({ data }) => {
      return data.data;
    }).catch(({ request, response }) => {
      return handleHttpError(request, response);
    });
};

const fetchAllDefaultItems = function(){
  return axios.get(`${endpoint}inventory/get-default-items`, axiosOptions())
  .then(({ data }) =>{
    return data.data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
}

const fetchAllItemsOfTypeForSale = function(body){
  return axios.post(`${endpoint}inventory/fetch-items-of-type-for-sale`, body, axiosOptions())
  .then(({ data }) =>{
    return data.data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
}

const insertItemByAvatarId = function(body){
  return axios.post(`${endpoint}inventory/insert-item-by-avatar-id`, body, axiosOptions())
  .then(({ data }) =>{
    return data.data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
}

const insertItemForAllAvatars = function(body){
  return axios.post(`${endpoint}inventory/insert-item-for-all-avatars`, body, axiosOptions())
  .then(({ data }) =>{
    return data.data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
}

const buyItemByAvatarId = function(body){
  return axios.post(`${endpoint}inventory/buy-item-by-avatar-id`, body, axiosOptions())
  .then(({ data }) =>{
    return data.data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
}

const consumeItemRequest = function(body){
  return axios.delete(`${endpoint}inventory/use-consumable-item`, axiosDeleteConfig(body))
  .then(({ data }) =>{
    return data.data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};

export { fetchAvatarInventory, equipItem, equipUnequipItem, unequipItem, fetchAllDefaultItems, fetchAllItemsOfTypeForSale, insertItemByAvatarId, insertItemForAllAvatars, buyItemByAvatarId, consumeItemRequest };
