import { useRef, useEffect, useState, useCallback } from 'react';

// Useful in React hooks for determining if the component has already been mounted
const useDidMount = () => {
  const didMountRef = useRef(false);
  useEffect(() => {
    didMountRef.current = true;
  }, []);
  return didMountRef.current;
};

// Useful in React hooks for checking updated prop values against previous values
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function useForceUpdate() {
  const [, setTick] = useState(0);
  const update = useCallback(() => {
    setTick(tick => tick + 1);
  }, [])
  return update;
}


export { useDidMount, usePrevious, useForceUpdate };