import React, { useState, useEffect } from 'react';
// LIBRARIES
import PropTypes from 'prop-types';
import ReCAPTCHA from "react-google-recaptcha";
// COMMON
import { debugErrors } from "../../../../common/errorHandler";
import { register } from "../../../../services/authService";
import history from '../../../../common/history';
// COMPONENTS
import DialogTemplate from '../../DialogTemplate';
import { Input, InputLabel, Checkbox } from '@material-ui/core';
import LoadingOverlay from '../../Overlays/Loading';
import SuccessOverlay from '../../Overlays/Success';
// STYLES
import './AuthModal.scss';

const Register = function({ modalOpen, setModalOpen, updateState, toggleLoginRegister, handlePostRegister }){
  const [captchaValue, setCaptachValue ] = useState(null);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [emailMarketingOptIn, setEmailMarketingOptIn] = useState(true);
  const [helperText, setHelperText] = useState(null);
  const [formIsValid, setFormIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  function handleRegister(){
    setLoading(true);
    setHelperText('');
    register({ email, firstName, username, password, emailMarketingOptIn, captchaValue })
    .then((data) =>{
      if(data.error){
        const error = data.error;
        if(error.message !== "That email is already in use. Have you already registered? Try logging in instead."){
          debugErrors(error);
        }
        updateState({ alerts : [{ type : 'error', message : error.message }] });
        setTimeout(() =>{
          history.push('/welcome');
        }, 1500);
        return;
      }
      setSuccess(true);
      const { user } = data;
      updateState({ alerts : [{ type : 'success', message : "Please check your email to verify account. Check your spam folder if the message is not in your inbox.", persist : true }] });

      handlePostRegister(user);
    })
    
  }

  function handleEmailInput(val){
    setEmail(val);
    if(!val.includes('@')){
      setHelperText("Must be valid email address");
    }else{
      setHelperText("");
    }
  }

  function handlePasswordInput(val){
    setPassword(val);
    if(val.length < 8){
      setHelperText("Password must be at least 8 characters");
    }else{
      setHelperText("");
    }
  }

  useEffect(() => {
    if(email.includes('@')){
      if(username?.length){
        if(password.length >= 8){
          return setFormIsValid(true);
        }
      }
    }
    return setFormIsValid(false);
  }, [email, username, password]);

  useEffect(() =>{
    if(firstName){
      setUsername(firstName);
    }
  }, [firstName]);

  const content = (
    <form id="signUpForm" className="auth-form">
      <div id="signupEmail" className="input-container">
        <InputLabel><strong>Email <span className="warning">*</span> </strong></InputLabel>
        <Input autoFocus id="emailInput" placeholder="Enter Email Address (required)" type="email" fullWidth required={true} fullWidth onChange={event => handleEmailInput(event.target.value)}  />
      </div>
      <div id="signupFirstName" className="input-container">
        <InputLabel><strong>First Name</strong></InputLabel>
        <Input id="firstNameInput" placeholder="Enter First Name (optional)" type="text" fullWidth required={false} onChange={event => setFirstName(event.target.value)} />
      </div>
      <div id="signupFirstName" className="input-container">
        <InputLabel><strong>Display Name <span className="warning">*</span></strong></InputLabel>
        <Input id="firstNameInput" placeholder="Visible on Public Profile (required)" type="text" fullWidth required={true} onChange={event => setUsername(event.target.value)} value={username} />
      </div>
      <div id="signupPassword" className="input-container">
        <InputLabel><strong>Password <span className="warning">*</span></strong></InputLabel>
        <Input id="passwordInput" placeholder="Enter Password (required)" type="password" fullWidth required={true} onChange={event => handlePasswordInput(event.target.value)} />
      </div>
      <div id="helperText" className={helperText ? '' : 'hide'}>
        <strong className="warning">{ helperText }</strong>
      </div>
      <div id="signupEmailOptIn" className="input-container">
        <InputLabel><span>Receive email feature updates every few months or so (we won't sell your data)</span></InputLabel>
        <Checkbox id="emailOptInCheckbox" checked={emailMarketingOptIn} onChange={event => setEmailMarketingOptIn(event.target.checked)} />
      </div>
      <br />
      <strong>Already have an account? <span className="link-text" onClick={() => toggleLoginRegister('Login')}>Log in here.</span></strong>
      <br />
      { loading ? <LoadingOverlay  /> : null }
      { success ? <SuccessOverlay /> : null }
    </form>
  );


  const captcha = <ReCAPTCHA
      sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
      onChange={(value) => setCaptachValue(value)}
    />
 
  return(
    <DialogTemplate 
    type={'basic'}
    identifier={`register`}
    title={"Sign Up"}
    content={(captchaValue || process.env.REACT_APP_HOST_ENV === 'development') ? content : captcha} 
    cancelButton={true} 
    confirmButton="Sign Up" 
    isOpen={modalOpen} 
    handleSave={handleRegister} 
    handleClose={() => setModalOpen(false)}
    disableButton={formIsValid ? false : true} 
    disableBackdropClick={true} 
  />
  )
}

export default Register;

Register.propTypes = {
  modalOpen : PropTypes.bool.isRequired,
  setModalOpen : PropTypes.func.isRequired,
  updateState : PropTypes.func.isRequired,
  toggleLoginRegister : PropTypes.func.isRequired, 
  handlePostRegister : PropTypes.func.isRequired,
};
