import React, { memo }  from 'react';
// LIBRARIES
import PropTypes from 'prop-types';
// COMPONENTS
import { FaBars } from 'react-icons/fa';

const MainMenuButton = function({ updateState, menuOpen }){

  return (
    <div className="settings-button" onClick={() =>{ updateState({ menuOpen : !menuOpen }) }}>
      <FaBars  />
    </div>
  );
};

export default MainMenuButton;

MainMenuButton.propTypes = {
  menuOpen : PropTypes.bool.isRequired,
  updateState : PropTypes.func.isRequired
};
