import React from 'react';
import moment from 'moment';
import { thousandsFormat } from './helperFunctions';
import * as Icons from './icons.js';

function convertMetersToFeet(meters){
  return meters ? `${thousandsFormat((meters * 3.28084).toFixed(0))} ft` : null
}

function convertMetersToMiles(distance){
  return distance ? `${(distance * 0.000621371).toFixed(2)} mi` : null;
}

function convertMetersSecondsToMilesHours(mps){
  return mps ? `${(mps * 2.2369).toFixed(1)} mph` : null;
}

function convertSecondsToReadableTime(sec){
  const hours = moment.utc(sec*1000).hours();
  const minutes = moment.utc(sec*1000).minutes();
  const readableHours = hours ? `${hours} h` : ``;
  const readableMin = minutes ?  ` ${minutes} min` : ``;
  const comma = readableHours && readableMin ? `,` : ``;
  const readableTime = readableHours + comma + readableMin;
  return readableTime;
}


function checkForDistanceColumns(activityData, includedColumns){
  activityData.forEach((act) => {
    if(act.distance){
      includedColumns.hasDistance = true;
    }
    if(act.averageSpeed){
      includedColumns.hasAverageSpeed = true;
    }
    if(act.maxSpeed){
      includedColumns.hasMaxSpeed = true;
    }
    if(act.elevationGain){
      includedColumns.hasElevationGain = true;
    }
  });
  return includedColumns;
};



function determineActivityIcon(type){
  switch(type){
    case 'Yoga':
      return <Icons.Yoga />
      break;
    case 'Walk':
      return <Icons.Walk />
      break;
    case 'Ride':
    case 'VirtualRide':
      return <Icons.Ride />
      break;
    case 'Hike':
      return <Icons.Hike />
      break;
    case 'WeightTraining':
      return <Icons.Weights />
      break;
    case 'Run':
    case 'VirtualRun':
      return <Icons.Run />
      break;
    case 'StairStepper':
      return <Icons.Stairs />
      break;
    case 'Elliptical':
      return <Icons.Elliptical />
      break;
    case 'Swim':
      return <Icons.Swim />
      break;
    case 'Rowing':
      return <Icons.Rowing />
      break;
    case 'Crossfit':
      return <Icons.Crossfit />
      break;
    case 'StandUpPaddling':
      return <Icons.StandUpPaddling />
      break;
    case 'Kayaking':
      return <Icons.Kayaking />
      break;
    case 'Snowshoe':
      return <Icons.Snowshoe />
    case 'Snowboard':
      return <Icons.Snowboard />
    case 'NordicSki':
    case 'AlpineSki':
    case 'BackcountrySki':
      return <Icons.Ski />
      break;
    default:
      return <Icons.Workout style={{"fontSize":".8em"}} />
  }
}


function determineElementIcon(type){
  switch(type){
    case 'Yoga':
    case 'Walk':
    case 'Elliptical':
      return <Icons.Air />
    case 'Hike':
    case 'WeightTraining':
    case 'Ride':
    case 'VirtualRide':
      return <Icons.Earth />
    case 'Run':
    case 'StairStepper':
    case 'Crossfit':
    case 'VirtualRun':
      return <Icons.Fire />
    case 'StandUpPaddling':
    case 'Swim':
    case 'Rowing':
    case 'Kayaking':
    case 'NordicSki':
    case 'AlpineSki':
    case 'BackcountrySki':
    case 'Snowboard':
    case 'Snowshoe':
      return <Icons.Water/>
    default:
      return <Icons.AllElements style={{"fontSize":".8em"}}/>
  }
}
function calculateXPBonus(sec, isTimeAndHalfActivity){
  const xp = isTimeAndHalfActivity ? ((sec/60) * 1.5) : sec/60;
  return Math.floor(xp);
} 
  
function calculatePowerBonus(sec, isTimeAndHalfActivity){
  const min = sec/60;
  const power = isTimeAndHalfActivity ? (min/20).toFixed(1) : (min/30).toFixed(1);
  return power;
}

function calculateElementBonus(sec, allElements, isTimeAndHalfActivity){
  const min = sec/60;
  const hours = min/60;
  let num;
  if(allElements){
    num = (min / 30) / 4;
  }else if(isTimeAndHalfActivity){
    num = min / 20
  }else{
    num = min / 30
  }

  if(hours < 1){
    //return `${(Math.floor((min /60).toFixed(1)/10)*10).replace(/^0+/, "")}`
    return (Math.floor(10*num)/10).toString().replace(/^0+/, "");
  }
  return (Math.floor(10*num)/10).toString();
}

export default { convertMetersToFeet, convertMetersToMiles, convertMetersSecondsToMilesHours, 
  convertSecondsToReadableTime, checkForDistanceColumns, 
  determineActivityIcon, determineElementIcon, calculateXPBonus, calculatePowerBonus, calculateElementBonus };