import React, {useState} from 'react';
// COMPONENTS
import { DebounceInput } from 'react-debounce-input';
import { Input, InputLabel, Button } from '@material-ui/core';
import Header from '../Header/Header';
// LIBRARIES
import PropTypes from 'prop-types';
// COMMON
import { updateAvatarName, updateOwnerUsername, deleteAccount, disconnectStrava } from '../../services/accountService';
import { checkAvatarName } from '../../services/avatarService';
import { debugErrors } from '../../common/errorHandler';
import history from '../../common/history';
//STYLES
import './AccountPage.scss';

const AccountPage = function({ admin, avatar, updateState, handleLogout, MainMenuButton, getStravaClientCredentials }){
  const [ heroName, setHeroName ] = useState(avatar.name);
  const [ heroNameIsLegit, setHeroNameIsLegit ] = useState(false);
  const [ ownerUsername, setOwnerUsername ] = useState(admin.username);
  const [ ownerUsernameIsLegit, setOwnerUsernameIsLegit ] = useState(false);
  const [checkingMessage, setCheckingMessage] = useState(false);
  const [helperText, setHelperText] = useState(null);

  function handleHeroNameInput(e){
    const name = e.target.value;
 
    setCheckingMessage(true);
    checkAvatarName({ name })
    .then((data) =>{
      setCheckingMessage(false);
      if(data.error){
        const error = data.error;
        return debugErrors(error);
      }
      const { availability } = data;
      if(availability){
        setHeroName(name);

        if(name.length >= 3 && name.length <= 25){
          setHelperText(null);
          setHeroNameIsLegit(true);
        }else{
          if(name.length < 3){
            setHelperText("Hero Name must be at least 3 characters");
          }else if(name.length > 25){
            setHelperText("Hero Name must be no more than 25 characters");
          }
          setHeroNameIsLegit(false);
        }
      }else{
        setHelperText("Hero Name is already in use, please select another!");
        setHeroNameIsLegit(false);
      }
    });
  }

  function handleOwnerUsernameInput(e){
    const val = e.target.value;
    setOwnerUsername(val);

    if(val && val.length >= 3 && val.length <= 25){
      setOwnerUsernameIsLegit(true);
    }else{
      setOwnerUsernameIsLegit(false);
    }
  }

  function handleHeroNameUpdate(e){
    e.preventDefault();
    updateAvatarName({ newHeroName : heroName, owner : admin.email })
    .then((data) =>{
      
      if(data.error){
        debugErrors(data.error, admin);
      }else{
        const updatedAvatar = Object.assign({}, avatar, { name : data.updatedName });
        updateState({ avatar : updatedAvatar, alerts : [{ type : 'success', message : 'Hero name has been updated!' }] });
      }
    });
  }

  function handleOwnerUsernameUpdate(e){
    e.preventDefault();

    updateOwnerUsername({ newUsername : ownerUsername, owner : admin.email })
    .then((data) =>{
      if(data.error){
        debugErrors(data.error, admin);
      }else{
        const updatedAvatar = Object.assign({}, avatar, { name : data.updatedName });
        updateState({ avatar : updatedAvatar, alerts : [{ type : 'success', message : 'Username has been updated!' }]  });
      }
    });
  }

  function handleDeleteAccount(e){
    e.preventDefault();
    const pResult = prompt('Delete Account: enter your email to confirm.');

    if(pResult === admin.email){
      deleteAccount({ username: admin.username, avatarID : avatar.id, email : admin.email })
      .then(async (data) =>{
        if(data.error){
          debugErrors(data.error, admin);
        }else{
          // NEED NEW SIGNOUT HERE
          
          updateState({  loggedIn : false, menuOpen : false, alerts : [{ type : 'success', message : 'Account has been deleted, we hope to see you again!' }] })
          setTimeout(() =>{
            handleLogout('/welcome', true);
          }, 3000);
        }
      }).catch((error) =>{
        debugErrors(error, admin);
      });
    }else if(pResult !== null){
      alert("Your email must match the email associated with this account!");
    }
  }

  function handleStravaConnection(){
    getStravaClientCredentials()
    .then((data) => {
      if(data.error){
        const error = data.error;
        // Couldn't retrieve strava credentials
        debugErrors(data.error, admin);
        return updateState({appIsLoading : false, alerts : [{type : 'error', message : `${error.status}: ${error.message}`}]});
      }
      document.location = `https://www.strava.com/oauth/authorize?client_id=${data.clientId}&redirect_uri=${data.redirectUri}&response_type=code&scope=activity:read_all`;
    });
  }

  function handleStravaDisconnection(){
    const confirmed = window.confirm("Are you sure you want to disconnect your Strava Account?");
    if(confirmed){
      disconnectStrava({ email : admin.email })
      .then((data) => {
        if(data.error){
          const error = data.error;
          // Couldn't Delete strava credentials
          debugErrors(data.error, admin);
          return updateState({alerts : [{type : 'error', message : `${error.status}: ${error.message}`}]});
        }
        const updatedAdmin = Object.assign(admin, { dataSrcId : null });
        updateState({ admin : updatedAdmin, alerts : [{type : 'success', persist: true, link: 'https://www.strava.com/settings/apps', message : `Your Strava credentials have been removed. Optional: You may wish to revoke access from Strava's side. Click here.`}]});
        localStorage.removeItem('herofit-stravaActivities');
        setTimeout(() =>{
          history.push('/');
        });
      });
    }

  }


  return (
    <section id="accountPage" className="page">
      <Header title={`Account`} subtitle={`Update Your ${process.env.REACT_APP_NAME} Account`} MainMenuButton={MainMenuButton} />
      <div id="formContainer">
        
      {/* <form id="updateStravaConnection">
        <InputLabel><strong>Manage Strava Account</strong></InputLabel>
        <div id="stravaButtonWrapper">
          <Button onClick={handleStravaDisconnection} disabled={admin.dataSrcId ? false : true} variant="contained" color="secondary">
            Disconnect
          </Button>
          <Button onClick={handleStravaConnection} disabled={admin.dataSrcId ? true : false} variant="contained" color="primary">
            Connect
          </Button>
        </div>
      </form> */}
        
        
        
        
        <form id="updateName" onSubmit={handleHeroNameUpdate} >
          <div>
            <InputLabel><strong>Change Hero Name</strong></InputLabel>
            <div id="messageWrapper">
              {
                checkingMessage ? 
                  <div id="checkingMessage"><strong className="muted">Checking Availability...</strong></div>
                : helperText ? 
                  <div id="heroNameHelperText"><strong className="warning">{ helperText }</strong></div> 
                : null
              }
            </div>
            <DebounceInput
              id="updateAvatarName"
              required={true}
              name="search"
              type="text"
              placeholder="Hero Name"
              value={heroName}
              minLength={3}
              debounceTimeout={400}
              onChange={e => handleHeroNameInput(e)}
              disabled={ false} 
            />
            <Button type="submit" disabled={heroNameIsLegit ? false : true} variant="contained" color="primary">
              Save
            </Button>
          </div>
        </form>

        <form id="updateOwnerUsername" onSubmit={handleOwnerUsernameUpdate} >
          <div>
            <InputLabel><strong>Change Username</strong></InputLabel>
            <Input
                  margin="dense"
                  id="updateUsername"
                  placeholder="Username"
                  type="text"
                  required={true}
                  value={ownerUsername}
                  onChange={(e) => handleOwnerUsernameInput(e)}
                />
            <Button type="submit" disabled={ownerUsernameIsLegit ? false : true} variant="contained" color="primary">
              Save
            </Button>
          </div>
        </form>

        <form id="deleteAccount" onSubmit={handleDeleteAccount} >
          <div>
            <InputLabel><strong>Permanently <span className="warning">DELETE</span> account</strong></InputLabel>
            <Button type="submit" variant="contained" color="primary">
              Delete
            </Button>
          </div>
        </form>
      </div>
    </section>
  )
}

export default AccountPage;

AccountPage.propTypes = {
  admin : PropTypes.object.isRequired,
  avatar : PropTypes.object.isRequired,
  updateState : PropTypes.func.isRequired,
  handleLogout : PropTypes.func.isRequired,
  MainMenuButton : PropTypes.object.isRequired,
  getStravaClientCredentials : PropTypes.func.isRequired
};