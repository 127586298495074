import React, { useState } from 'react';
// COMMON
import { lowercaseUnderscore } from '../../../common/helperFunctions';
// COMPONENTS
import DialogTemplate from '../../Dialog/DialogTemplate';
import { Input } from '@material-ui/core';
import { Fire, Earth, Water, Air } from '../../../common/icons';
import Hexagon from '../../Hexagon/Hexagon';
import { DebounceInput } from 'react-debounce-input';
// SERVICES
import { checkAvatarName } from '../../../services/avatarService';
// STYLES
import './SelectAvatarModal.scss';
import { debugErrors } from '../../../common/errorHandler';

const SelectAvatarModal = function({ modalOpen, setModalOpen, handleModalAction, allowSelection, selectedAvatar }) {
  const [nameInput, setNameInput] = useState('');
  const [nameIsLegit, setNameIsLegit] = useState(false);
  const [checkingMessage, setCheckingMessage] = useState(false);
  const [helperText, setHelperText] = useState(null);

  function handleModalSave(){
    handleModalAction(nameInput);
    setModalOpen(false);
  }

  function handleCloseActions(){
    setNameIsLegit(false);
    setCheckingMessage(false)
    setHelperText(false)
    setNameInput('');
    setModalOpen(false)
  }

  const handleNameInput = function(e){
    const name = e.target.value;
 
    setCheckingMessage(true);
    checkAvatarName({ name })
    .then((data) =>{
      setCheckingMessage(false);
      if(data.error){
        const error = data.error;
        return debugErrors(error);
      }
      const { availability } = data;
      if(availability){
        setNameInput(name);

        if(name.length >= 3 && name.length <= 25){
          setHelperText(null);
          setNameIsLegit(true);
        }else{
          if(name.length < 3){
            setHelperText("Hero Name must be at least 3 characters");
          }else if(name.length > 25){
            setHelperText("Hero Name must be no more than 25 characters");
          }
          setNameIsLegit(false);
        }
      }else{
        setHelperText("Hero Name is already in use, please select another!");
        setNameIsLegit(false);
      }
    });

  }


  const content = <div>
    <div className={` avatar_hex ${lowercaseUnderscore(selectedAvatar.character)}_hex`}></div>
    <div id="characterElementContainer">
      <div className={`element-wrapper ${selectedAvatar.fire ? '' : 'element-wrapper-disabled'}`}>
      <div className="element-type"><strong style={{"color":"#e25822"}}>FIRE</strong></div>
        <Hexagon diameter={55} color={"#fff"} border={3} borderColor="#e25822" icon={ <Fire /> } iconStyles={{ color: '#e25822', size: '1.8em' }} text={selectedAvatar.fire || '0'} textStyles={{color : '#e25822', fontSize: '1.2em', fontWeight: 'bold' }}  />
      </div>
      <div className={`element-wrapper ${selectedAvatar.earth ? '' : 'element-wrapper-disabled'}`}>
      <div className="element-type"><strong style={{"color":"#8A360F"}}>EARTH</strong></div>
        <Hexagon diameter={55} color={"#fff"} border={3} borderColor="#8A360F" icon={ <Earth /> } iconStyles={{ color: '#8A360F', size: '1.8em' }} text={selectedAvatar.earth || '0'} textStyles={{color : '#8A360F', fontSize: '1.2em', fontWeight: 'bold' }}  />
      </div>
      <div className={`element-wrapper ${selectedAvatar.water ? '' : 'element-wrapper-disabled'}`}>
      <div className="element-type"><strong style={{"color":"#0f5e9c"}}>WATER</strong></div>
        <Hexagon diameter={55} color={"#fff"} border={3} borderColor="#0f5e9c" icon={ <Water /> } iconStyles={{ color: '#0f5e9c', size: '1.8em' }} text={selectedAvatar.water || '0'} textStyles={{color : '#0f5e9c', fontSize: '1.2em', fontWeight: 'bold' }} />
      </div>
      <div className={`element-wrapper ${selectedAvatar.air ? '' : 'element-wrapper-disabled'}`}>
      <div className="element-type"><strong style={{"color":"#87CEFA"}}>AIR</strong></div>
        <Hexagon diameter={55} color={"#fff"} border={3} borderColor="#16a0f5" icon={ <Air /> } iconStyles={{ color: '#16a0f5', size: '1.8em' }} text={selectedAvatar.air || '0'} textStyles={{color : '#16a0f5', fontSize: '1.2em', fontWeight: 'bold' }}  />
      </div>
    </div>
    <div id="messageWrapper">
      {
        checkingMessage ? 
          <div id="checkingMessage"><strong className="muted">Checking Availability...</strong></div>
        : helperText ? 
          <div id="heroNameHelperText"><strong className="warning">{ helperText }</strong></div> 
        : null
      }
    </div>
    {
      allowSelection ? 
      <div id="heroNameInputContainer">
        <DebounceInput
          id="avatarNameInput"
          required={true}
          name="search"
          type="text"
          placeholder="Hero Name"
          minLength={3}
          debounceTimeout={400}
          onChange={e => handleNameInput(e)}
          disabled={ false} 
        />
      </div>
      : null
    }
  </div>


  return ( <div id="selectAvatarModal">

      <DialogTemplate 
        type={'basic'}
        identifier={'select-hero'}
        title={selectedAvatar.alias}
        text={selectedAvatar.history}
        content={content} 
        cancelButton={true} 
        confirmButton="Select" 
        isOpen={modalOpen} 
        handleSave={handleModalSave} 
        handleClose={() => handleCloseActions()} 
        disableButton={nameIsLegit ? false : true} 
        disableBackdropClick={false} 
      /> 

    </div>

  );
  
}

export default SelectAvatarModal;