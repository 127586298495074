import { emailAppError } from '../services/emailService';

function _checkEmptyObject(obj){
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
}

// Decides how to report users to end users and the dev team
function debugErrors(error, admin = null){
  if(typeof error === 'string'){
    if(process.env.REACT_APP_HOST_ENV=== 'production' || process.env.REACT_APP_HOST_ENV === 'staging'){
      // Likely an expired JWT, don't email error message
      //emailAppError({ status : 999, message : error, version : process.env.REACT_APP_VERSION, accountInfo : admin });
      return error;
    }else{
      console.log('ERROR STRING ONLY - ', error);
      return error
    }
  }else{
    if(process.env.REACT_APP_HOST_ENV === 'production' || process.env.REACT_APP_HOST_ENV === 'staging'){
      // 403 is usually related to leaving HeroFit open on a device. The default message will now tell them to refresh the page.
      if(error.status === 401 || error.status === 403){
        // These errors should redirect the user to the login page 
        if(error.message === 'Access token expired, please sign in again.'){
          setTimeout(() =>{
            document.location = 'http://localhost:3000/welcome';
          },2000);
        }
      }else{
        emailAppError({ status : error.status, message : error.message, debug: error.debug, version : process.env.REACT_APP_VERSION, accountInfo : admin, meta : error.meta });
      }
      return error.message
    }else{
      console.log(`STATUS: ${error.status} \n`, `MESSAGE: ${error.message} \n`, `DEBUG:`, error.debug, `META: ${error.meta}`);
      if(error.status === 401 || error.status === 403){
        if(error.message === 'Access token expired, please sign in again.'){
          setTimeout(() =>{
            document.location = 'http://localhost:3000/welcome';
          },2000);
        }
      }
      if(error.debug && error.debug.length){
        return error.message;
      }
      return error.message;
    }
  }
}

// Handles all errors resulting from or before network requests, formatts returned error in consistant way for front end, which may also pass it to debugErrors
function handleHttpError(request, response){
  if(response){
    const { status, data } = response;
    let { error, meta } = data;
    //console.log('RESP', response);
    let errorArray;
    // If it's an object, it's likely a validation error, so map over it
    if(typeof error === 'object'){
      // This happens when the backend returns an error object without stringifying it. Shouldn't happen but sometimes I forget.
      if(_checkEmptyObject(error)){
        return [{
          status,
          message : `There was a server error, please try again later`,
          debug : [],
          meta : "Forgot to stringify error object returned from server"
        }]
      }

      if(error.length){
        // Map the error arrays to display readable strings
        errorArray = error.map((err) => {
          // If express validator error
          if(err.hasOwnProperty('msg') && err.hasOwnProperty('value') && err.hasOwnProperty('param') && err.hasOwnProperty('location')){
            return `${err.msg} - value ${err.value} of parameter ${err.param} in request ${err.location}`;
          // Any other custom error I write on the server
          }else{ return { error : err }; }
        }); 
      }else{
        errorArray = [error]
      }
    }else if(typeof error === 'string'){
      let status;
      if(error === "Error: Authorization token invalid or expired"){
        status = 401;
      }
      errorArray = [{
        msg : error,
        location : 'Backend'
      }];
      if(status){
        errorArray[0].status = status;
      }
    }else{
      errorArray = [error];
    }
    if(error?.oldUserMigration){
      meta = { oldUserMigration : true };
    }
    return {
      error : {
        status,
        message : error && error.message ? error.message : status === 400 || status === 401 ? errorArray[0].msg : `Client auth error, please try again.`,
        debug : errorArray,
        meta
      }
    }
  // client never received a response, or request never left
  }else if(request) {

    return {
      error : {
        status : null,
        message : "Network Error, please try again later.",
        debug : "Request failed to go through."
      }
    }
  // No request was even made, error in code above
  }else{
    return {
      error : {
        status : null,
        message : "There was an error, please try again later.",
        debug : "Error in client code before the request was made!"
      }
    }
  }
}

export { debugErrors, handleHttpError };