// REACT
import React, { useState, useEffect } from 'react';
// LIBRARIES
import PropTypes from 'prop-types';
import { GiStarSkull } from 'react-icons/gi';
import { RiAwardFill } from 'react-icons/ri';
// COMPONENTS
import Header from '../Header/Header';
import CircularProgress from '@material-ui/core/CircularProgress';
import ItemDetail from '../Dialog/Modals/ItemDetail';
// COMMON
import { lowercaseUnderscore } from '../../common/helperFunctions';
import { usePrevious } from "../../common/hookHelpers";

// SERVICES
import { getVillainList, fetchBattlesWonOrDkoByAvatarID } from '../../services/battleService';
import { fetchAllDefaultItems } from '../../services/inventoryService';
// ASSETS
import './CampaignPage.scss';
import { debugErrors } from '../../common/errorHandler';

const CampaignPage = function({ avatar, MainMenuButton, updateState, defaultItems }){
  const [items, setItems] = useState();
  const [villains, setVillains] = useState();
  const [foesDefeated, setFoesDefeated] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  function handleItemClick(item){
    const name = item.name ? item.name : item;
    const matchedItem = items.find(item => item.name === name);
    setActiveItem(matchedItem);
    setModalOpen(true);
  }

  function renderVillainList(){
    const vClasses = [];
    for(let villainClass in villains){
      vClasses.push(villainClass);
    }

    function renderFoeNames(foeList){
      const FoeListMapping = foeList.map((name,j) => {
        let classList = foesDefeated.includes(`${name}`) ? 'strike-through warning bold foe' : 'foe';

        if(foesDefeated.includes(`${name}`) || (name === 'Shadow-Self' && foesDefeated.includes(`Shadow ${avatar.name}`)) ){
          classList = 'strike-through warning bold foe';
        }else{
          classList = 'foe';
        } 
        return <li key={j} className={classList}>{ name === 'Shadow-Self' ? `Shadow ${avatar.name}` : name } </li>
        
      });


      return FoeListMapping;
    }
    const bossFoes = ['Guardian of the Depths', 'Plaguebringer', 'Shadow-Self', 'Storming Oni', 'Hulking Aggro Crag', 'Scorching Archfiend', 'High Priestess of the Tides'];
    return vClasses.map((villainType, index) =>{
      const foes = villains[villainType].map((foe, i ) =>{

        // Set foeType
        let foeType;
        let isBoss = bossFoes.includes(foe.type) ? true : false;
        if(foe.type === 'Shadow-Self'){
          foeType = `shadow_${lowercaseUnderscore(avatar.character)}`;
        }else{
          foeType = foe.type;
        }
        return <li className={`villain-type villain-type-${foe.type}`} key={i}>
          <h4>{isBoss ? <GiStarSkull /> : null}{foe.type}</h4>
          {
            foe.type === 'Shadow-Self' ?
              <h5 className="muted">Available at level 10+</h5> :
            foe.type === 'Plaguebringer' ?
              <h5 className="muted">Available at level 20+</h5> :
            foe.type === 'Guardian of the Depths' ?
              <h5 className="muted">Available at level 40+</h5>
            : null
          }
          {
            foe.ability ? <span className="ability-text caution"><strong>{foe.ability.name}</strong> <span>({foe.ability.effect})</span></span>
            : null
          }<br />
          {
            foe.reward ? <span onClick={() => handleItemClick(foe.reward) } className="reward-text light-link-text"><RiAwardFill /><strong>{foe.reward.name}</strong></span>
            : null
          }<br />
          {
            foe.itemSummon ? 
            <div className="engage-with-item-container">
              <span>* Use the <strong onClick={() => handleItemClick(foe.itemSummon) } className="light-link-text">{foe.itemSummon}</strong> to battle</span>
              
            </div>
            : null
          }
          
          <strong>Foes:</strong>
          <ul>
            { renderFoeNames(foe.foe) }
          </ul>
          <div className={`villain-background ${lowercaseUnderscore(foeType)}-background`}></div>
        </li> 
        });
      
      
      return <div className={`villain-class villain-class-${villainType}`} key={index}>
        <h3>{villainType}</h3>
       
        <ul>
          { foes }
        </ul>
      </div>
    });
  }

  useEffect(() =>{
    getVillainList()
    .then((data) =>{
      if(data.error){
        const error = data.error;
        debugErrors(error);
        return updateState({ alerts : [{ type : 'error', message :`${error.status}: ${error.message}` }] });
      }
      setVillains(data.villains);
    });

    fetchAllDefaultItems()
    .then((data) =>{

      if(data.error){
        const error = data.error;
        debugErrors(error);
        return updateState({ alerts : [{ type : 'error', message :`${error.status}: ${error.message}` }] });
      }
      setItems(data.items);
    });
  }, []);

  const prevAvatar = usePrevious(avatar);

  useEffect(() =>{
   if( avatar && !prevAvatar){
    setTimeout(() =>{
      fetchBattlesWonOrDkoByAvatarID({ avatarID : avatar.id })
      .then((data) =>{
        if(data.error){
          const error = data.error;
          debugErrors(error);
          return updateState({ alerts : [{ type : 'error', message :`${error.status}: ${error.message}` }] });
        }
        const battles = data.battles;
        const fd = [];
        battles.forEach((battle) =>{
          fd.push(battle.foe.name)
        });
        setFoesDefeated(fd);
      });
    },250)
   }

  }, [avatar]);
  
  
  return(
    <section id="foePage" className="page">
      <Header title={`The Dark Forces`} subtitle={`Overcome All Adversaries`} MainMenuButton={MainMenuButton} />
      {
        villains ? <div id="villainContainer">
            <div id="legend">
              <strong>Key:&nbsp;&nbsp;</strong>
              <span className="bold strike-through warning">Foe You Defeated</span>&nbsp;&nbsp;&nbsp;&nbsp;
              <span className="muted bold">Requirement to Fight</span>&nbsp;&nbsp;&nbsp;&nbsp;
              <span className="caution bold">Special Ability</span>&nbsp;&nbsp;&nbsp;&nbsp;
              <span className="light-link-text bold"><RiAwardFill/> Reward for defeating all foes</span>
            </div>
            { avatar ? renderVillainList() : null } 
           </div>
        :
        <div id="loadingContainer">
          <div className="page-loading-indicator">
            <CircularProgress style={{width:'100%', height: '100%'}} />
            <div className="loading-message">Looking for the Baddies...</div>
          </div>
        </div>
      }
      {
        activeItem ?
         <ItemDetail modalOpen={modalOpen} setModalOpen={setModalOpen} item={activeItem} avatar={avatar} />
        : null
      }
    </section>
  )
}

export default CampaignPage;

CampaignPage.propTypes = {
  avatar : PropTypes.object, 
  MainMenuButton : PropTypes.object,
  updateState : PropTypes.func.isRequired,
  defaultItems : PropTypes.array.isRequired,
};