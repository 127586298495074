import React from 'react';
// COMPONENTS
import CircularProgress from '@material-ui/core/CircularProgress';
// STYLES
import './ModalOverlay.scss';

const Loading = function(){

  return(
    <div id="loadingModalOverlay" className="modal-overlay">
      <div id="loadingModalIndicatorContainer">
        <div id="loadingModalIndicatorWrapper">
          <CircularProgress style={{width:'100%', height: '100%'}} />
        </div>
      </div>
    </div>
  )
}

export default Loading;
