import React, { useState, useEffect } from 'react';
// LIBRARIES
import { IconContext } from "react-icons";
import { CountUp } from 'countup.js';
import { usePrevious } from '../../common/hookHelpers';
// STYLES
import './Hexagon.scss';

const Hexagon = function({ button, diameter, color, border, borderColor, icon, iconStyles, text, textStyles, modifier={icon:0,text:0}, countUp }){
  const [classes, setClasses] = useState('');
  const powerUp =  React.createRef();

  let innerD;
  if(border){
    innerD  = (diameter - (border * 2));
  }
  let hexType = icon && text ? 'both' : icon ? 'icon' : text ? 'text' : 'empty';
  let marginModifier = { icon : modifier.icon, text : modifier.text};

  function renderInnerHexagon(){
    if(border){
      return (
        <div className={`hexagon hexagon-inside ${ button ? 'hexagon-button' : ''} ${ classes }`} style={{ left : border, top: border + 1}}>
          <div className="top"  style={
            { 
              borderLeft : `${ (innerD/ 2).toFixed(2) }px solid transparent `,
              borderRight : `${ (innerD/ 2).toFixed(2) }px solid transparent ` ,
              borderBottom : `${ (innerD/ 3.1).toFixed(2) }px solid ${color} ` 
             }
          }></div>
          <div className="middle" style={
            {
              width : innerD,
              height : parseInt((innerD * .5645).toFixed(2)),
              backgroundColor : color,
            }
            }>
              
              { renderIcon() }
              { renderText() }

            </div>
          <div className="bottom"  style={
            { 
              borderTop : `${ (innerD/ 3.1).toFixed(2) }px solid ${color} `,
              borderLeft: `${ (innerD/ 2).toFixed(2) }px solid transparent `,
              borderRight: `${ (innerD/ 2).toFixed(2) }px solid transparent `
             }
            }>
            </div>
        </div>
      )
    }
    return null;
  }

  function renderIcon(){
    if(hexType === 'both' || hexType === 'icon'){
      return (
        <div className="icon-wrapper">
          <IconContext.Provider value={iconStyles}>
            <div style={{marginTop : marginModifier.icon}}>
              { icon }
            </div>
          </IconContext.Provider>
        </div>
      )
    }
    return null;
  }

  function renderText(){
    if(hexType === 'both' || hexType === 'text'){
      return (
        <div className="text-wrapper">
          <div className="hex-text" style={{ ...textStyles, marginTop : marginModifier.text }} ref={ countUp ? powerUp : null } >
            {text}
          </div>
        </div>
      )
    }
    return null;
  }

  
  /*
  VISUAL COUNTER - Only used for Hexagons that have countUp = true, displays visual counter up to totals
  */

  function visualCounter(targetElement, startVal = 0, endVal = 0, duration){
    setClasses('upgrading');
    const counter = new CountUp(targetElement, endVal, { startVal, duration });
    counter.start();
    setTimeout(()=>{
      setClasses('');
    },(duration * 1000));
  }

  const prevText = usePrevious(text);
  useEffect(() =>{
    if(countUp && (prevText !== text)){
      let duration;

      // When prevText is undefined, the component is still being rerendered, so skip countup
      if(prevText === undefined){
        return;
      // If initial load, count up slower
      }else if(prevText === 0){
        duration = 7;
        visualCounter(powerUp.current, prevText, text, duration);
      // If after upgrade, count up faster
      }else if(prevText >= 0){
        duration = 5;
        visualCounter(powerUp.current, prevText, text, duration);
      }
 
    }
  }, [prevText, text]);

  /*
  END VISUAL COUNTER - Only used for Hexagons that have countUp = true, displays visual counter up to totals
  */

  return(
   <div className={`hexagon ${border ? 'hexagon-outside ' : 'hexagon-no-border' } ${ button ? 'hexagon-button' : ''}  ${ classes }`}>
        <div className="top" style={
          { 
            borderLeft : `${ (diameter/ 2).toFixed(2) }px solid transparent `,
            borderRight : `${ (diameter/ 2).toFixed(2) }px solid transparent ` ,
            borderBottom : `${ (diameter/ 3.1).toFixed(2) }px solid ${border ? borderColor : color} ` 
          }
        }></div>
        <div className="middle" style={
          { 
            width : diameter,
            height : parseInt((diameter * .5645).toFixed(2)),
            backgroundColor : border ? borderColor : color 
          }
        }>
          { 
          border ? null : <div><div>{renderIcon()}</div><div>{renderText()}</div></div>
          }
        </div>
        <div className="bottom" style={
          { 
            borderTop : `${ (diameter/ 3.1).toFixed(2) }px solid ${border ? borderColor : color} `,
            borderLeft: `${ (diameter/ 2).toFixed(2) }px solid transparent `,
            borderRight: `${ (diameter/ 2).toFixed(2) }px solid transparent `
          }
        }></div>
        
        


        {/* INNER HEXAGON */}
        { renderInnerHexagon() }
      </div>
  )
}

export default Hexagon;