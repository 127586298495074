import React from 'react';
import { Link } from 'react-router-dom';
// COMPONENTS
import Header from '../Header/Header';
import ITEMS from '../../common/includedItemImages';
import PhotonToken from '../../assets/images/misc/photon_stack.webp';
// STYLES
import './AboutPage.scss';

const SignupRewardsPage = function({ MainMenuButton, name}){
  return(
    <section id="signupRewardsPage" className="page">
      <Header title={`NEW REWARDS`} subtitle={"Sign-up & Referral bonuses"} MainMenuButton={MainMenuButton} />
      <div className="about-segment">
        <div className="segment-content">
          <div className="segment-header-wrap">
            <h2>Sign Up Soon</h2>
            <p>Signup within the first 90 days of launch & receive the Snowshow Hare Pet free. Befriending a Snowshow Hare will make you agile and powerful, but at the expense of armor.</p>
            <strong>Effects:</strong>
            <ul>
              <li>RABBIT TACTICS - Hero's Armor is reduced by two-thirds, but hero receives a significant bonus to Power and Air based on Armor and hero level.</li>
            </ul>
          </div>
          <div className="section-img">
            <img  className="pet" src={ITEMS.pets.snowshoe_hare} alt="Snowshoe Hare" />
          </div>
        </div>
      </div>
      <div className="about-segment">
        <div className="segment-content">
          <div className="segment-header-wrap">
            <h2>Refer a Friend</h2>
            <p>Receive a Powerful Ashborn Phoenix: a mythical pet rewarded with powerful fire attacks and a chance to avoid being Knocked Out from Battle!</p>
            <strong>Effects:</strong>
            <ul>
              <li>HEROES NEVER DIE - If hero loses a battle or DKO, even chance to revive Hero to 1 health immediately.</li>
              <li>+25 Damage Afterburn - Draws & losses will cause the whelp to deal a final 25 damage to the foe, possibly causing a win or a DKO</li>
              <li>Hero +5,+10% Fire - Hero receives a +5 and a +10% fire buff before battle.</li>
            </ul>
            
          </div>
          <div  className="section-img">
              <img className="pet" src={ITEMS.pets.ashborn_phoenix} alt="Ashborn Phoenix" />
            </div>
          <div id="ptSection" class="clearfix">
              <div>
              <h3>Wait, there's more!</h3>
              <strong>+500 Photon Tokens</strong>
              <br />
              <p>You and your friend will also receive 500 bonus Photon Tokens to spend on other items!</p>
              </div>
              <img  src={PhotonToken} alt="photon tokens" />
              <br />
              <h4>Make sure they mention your Hero name or email address at signup</h4>
            </div>

        </div>
      </div>
      <div className="about-segment about-segment-all-pets">
        <div className="segment-content">
          <h3>Pets are a big part of the game, see them all <Link to={`/items`}>here</Link></h3>
          <div id="petContainer">
            <img className="pet" src={ITEMS.pets.arizona_rattlesnake} alt="Arizona RattleSnake" />
            <img className="pet" src={ITEMS.pets.black_cat} alt="Black Cat" />
            <img className="pet" src={ITEMS.pets.raven_of_omens} alt="Raven of Oments" />
          </div>
        </div>

      </div>
      {/* <div className="about-segment about-segment-reddit">
        <span>Stay up-to-date with all the latest HeroFit news on <a href="https://reddit.com/r/herofit">our subreddit.</a></span>
      </div> */}
    </section>
  )
};

export default SignupRewardsPage;