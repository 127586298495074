import React, { useState, useEffect } from 'react';
// LIBRARIES
import PropTypes from 'prop-types';
// COMMON
import { debugErrors } from "../../../common/errorHandler";
import { convertItemArrayToCategories } from "../../../common/helperFunctions";
// COMPONENTS
import DialogTemplate from '../DialogTemplate';
import { Button } from '@material-ui/core';
// SERVICES
import { createRandomAvatar, createRandomActivities, getFakeActivities, setAvatarHealthAndStatus, hourlyAvatarUpdate, tryRecovery, runAllBattles, generateFoe, determinePBAs, runPBAsForBattleById, adminToggleFeedback, deleteOneAccount } from '../../../services/mockService';
import { fetchCurrentLevelByInput, fetchNextLevelXpByInput, checkLevelUpByInput } from '../../../services/calculateService';
import { insertItemByAvatarId, insertItemForAllAvatars } from '../../../services/inventoryService';
import { runSpecificBattle } from '../../../services/battleService';

const AdminPanel = function({ modalOpen, setModalOpen, admin, updateState, avatar, defaultItems }){
  const [xp, setXp] = useState(0);
  const [endingXp, setEndingXp] = useState(0);
  const [level, setLevel] = useState(0);
  const [activityNum, setActivityNum] = useState(3);
  const [health, setHealth] = useState(0);
  // Marketing
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(true);

  // Inventory
  const [ allItems, setAllItems ] = useState(null);
  const [ addItemToId, setAddItemToId ] = useState(0);

  // Battles
  const [ runBattleOnThisId, setRunBattleOnThisId ] = useState(0);

  // Account
  const [ username, setUsername ] = useState(null);
  const [ email, setEmail ] = useState(null);
  const [ avatarID, setAvatarID ] = useState(null);
  const [ userID, setUserID ] = useState(null);

  function createFakeAvatar(user){

    createRandomAvatar({ owner : user.email, username : user.username })
    .then((data) =>{
      if(data.error){
        return debugErrors(data.error);
      }
      console.log('Fake AVATAR created - ', data);
    });
  }

  function _runSpecificBattle(){
    return runSpecificBattle({ avatarID : runBattleOnThisId })
    .then((data) =>{
      if(data.error){
        return debugErrors(data.error, admin);
      }
      console.log('ran specific battle', data);
    });
  }


  function _runAllBattles(){
    return runAllBattles()
    .then((data) =>{
      if(data.error){
        return debugErrors(data.error, admin);
      }
      console.log('ran all battles', data);
    });
  }

  function _hourlyAvatarUpdate(){
    return hourlyAvatarUpdate()
    .then((data) =>{
      if(data.error){
        return debugErrors(data.error, admin);
      }
      console.log(data.meta);
    });
  }


  function tryRecoveryKO(){
    return tryRecovery()
    .then((data) =>{
      if(data.error){
        return debugErrors(data.error);
      }
      console.log(data.meta);
    });
  }

  function _generateFoe(avatar){
    return generateFoe({ avatar })
    .then((data) =>{
      if(data.error){
        return debugErrors(data.error);
      }
      console.log(data);
    });
  }

  function _determinePBAs(avatar){
    return determinePBAs({ avatar })
    .then((data) =>{
      if(data.error){
        return debugErrors(data.error);
      }
      console.log(data);
    });
  }

  function _runPBAsForBattleById(){
    return runPBAsForBattleById()
    .then((data) =>{
      if(data.error){
        return debugErrors(data.error);
      }
      console.log(data);
    });
  }

  function setAvHS(cred){
    setAvatarHealthAndStatus(cred)
    .then((data) =>{
      if(data.error){
        return debugErrors(data.error);
      }
      if(data.message === 'Update Successful'){
        return updateState({ avatar : Object.assign({}, avatar, { health : avatar.maxHealth, status : 'Rested', restedEnough : true } ) });
      }
    });
  }

  function currentLevelByInput(){
    // Get Current Level from Backend Algorithim using input, set it in local state
    return fetchCurrentLevelByInput({ xp })
    .then((data) =>{
      if(data.error){
        return debugErrors(data.error);
      }
      const { level, albedo, total } = data;
      console.log('Current Level - ', level, albedo, total);
    });
  }

  function nextLevelXpByInput(){
 
    return fetchNextLevelXpByInput({ level })
    .then((data) =>{
      if(data.error){
        return debugErrors(data.error);
      }
      const { nextLevelXp } = data;
      console.log('Next Level XP Required - ', nextLevelXp);
    });
  }

  function levelUpByInput(){

    return checkLevelUpByInput({ startingXP : xp, endingXP : endingXp })
    .then((data) =>{
      if(data.error){
        return debugErrors(data.error);
      }
      const { leveledUp, levels } = data;
      console.log('Check Level Up - ', leveledUp, levels);
    })
  }

  function toggleFeedbackForAll(){
    return adminToggleFeedback({ setToTrue : feedbackSubmitted })
    .then((data) =>{
      if(data.error){
        console.log('ERROR - ', data.error);
      }
      console.log('returned data ', data);
    });
  }


  function addItemToAv(selectedItem, avatarID){
    // FOR adding an item to all avatars
    if(parseInt(addItemToId) === 0){
      const conf = window.confirm(`Are you sure you cant to add ${selectedItem.name} to all avatars`);
      if(conf){
        return addItemToAllAvs({ item : { itemID : selectedItem.id }});
      }
      return;
    }

    console.log('!!', selectedItem);
    const avatar_item = { avatarID, itemID : selectedItem.id };

    console.log('ITEMTOINSERT - ',avatar_item);
    return insertItemByAvatarId({ item : avatar_item })
    .then((data) =>{
      if(data.error){
        return debugErrors(data.error);
      }
      console.log('DATA', data);
    });
  }

  function addItemToAllAvs(item){
    console.log('I',item);
    return insertItemForAllAvatars(item)
    .then((data) =>{
      if(data.error){
        return debugErrors(data.error);
      }
      console.log('DATA', data);
    });
  }

  function deleteSpecificAccount(){
    console.log(email);
    deleteOneAccount({ email, deleteFromAdminPanel : true})
    .then(async (data) =>{
      if(data.error){
        debugErrors(data.error, admin);
      }else{
       console.log(`Success! - ${email} has been deleted!`);
       setEmail(null);
      }
    });
  }

  // When avatar is available, set local state XP and Level
  useEffect(() =>{
    if(avatar && modalOpen){
      setXp(avatar.activityXP + avatar.battleXP);

      // Get Current Level from Backend Algorithim, set it in local state
      const xp = avatar.activityXP + avatar.battleXP;
      fetchCurrentLevelByInput({ xp })
      .then((data) =>{
        if(data.error){
          return debugErrors(data.error);
        }
        return setLevel(data.level);
      });

    }
  }, [avatar, modalOpen]);

  // Fetch the list of default items from the server to display them in a list
  useEffect(() =>{
    if(avatar?.id){
      setAddItemToId(avatar.id);
      setRunBattleOnThisId(avatar.id);
    }
  }, [avatar]);

  useEffect(() =>{
    if(defaultItems){
      setAllItems(convertItemArrayToCategories(defaultItems))
    }
  }, [defaultItems]);

  const content = <section id="adminPanel">
      {/* nextLevel - console.log */}
      <div className="admin-function">
        <strong>WARNING: Deletes a user's account:</strong>
        <label>
          <input placeholder="email" type="email" value={email} onChange={(e) =>{setEmail(e.target.value)}} />
        </label>
        <Button className="MuiButton-containedHighlight" onClick={() =>{deleteSpecificAccount()}} color="error" variant="contained">DELETE</Button>
      </div>

      {/* Runs fake Battle between logged in Avatar & random monster - console.log */}
      <div className="admin-function">
        <strong>Runs fake or Real Battles between logged in Avatar & random monster</strong>
        <label>
          <input type="number" value={runBattleOnThisId} onChange={(e) => setRunBattleOnThisId(e.target.value) } />
        </label>
        <Button className="MuiButton-containedHighlight" onClick={_runSpecificBattle} color="primary" variant="contained" disabled={ avatar ? false : true }>Run Specific Battle</Button>
        <Button className="MuiButton-containedHighlight" onClick={_runAllBattles} color="primary" variant="contained" disabled={ avatar ? false : true }>Run All Battles</Button>
        <Button className="MuiButton-containedHighlight" onClick={() => _generateFoe(avatar)} color="primary" variant="contained" disabled={ avatar ? false : true }>Generate Foe</Button>
        <Button className="MuiButton-containedHighlight" onClick={() => _determinePBAs(avatar)} color="primary" variant="contained" disabled={ avatar ? false : true }>Determine PBAs</Button>
        <Button className="MuiButton-containedHighlight" onClick={_runPBAsForBattleById} color="primary" variant="contained" disabled={ avatar ? false : true }>Run PBAs for TBD Battle</Button>
      </div>

      <div className="admin-function">
        <Button className="MuiButton-containedHighlight" onClick={() =>{tryRecoveryKO();}} color="primary" variant="contained" disabled={ avatar ? false : true }>Try KO Recovery</Button>
        <Button className="MuiButton-containedHighlight" onClick={() =>{_hourlyAvatarUpdate();}} color="primary" variant="contained" disabled={ avatar ? false : true }>Hourly Avatar Update</Button>
        <Button className="MuiButton-containedHighlight" onClick={() =>{setAvHS({ "status" : 'Knocked Out', health : 0, id : avatar.id });console.log(`Knocked out Hero`)}} color="primary" variant="contained" disabled={ avatar ? false : true }>Knock Out Hero</Button>
        <Button className="MuiButton-containedHighlight" onClick={() =>{setAvHS({ "status" : 'Rested', health : avatar.maxHealth, id : avatar.id });console.log(`Restored Hero`)}} color="primary" variant="contained" disabled={ avatar ? false : true }>Restore Hero</Button>
     </div>

      {/* nextLevel - console.log */}
      <div className="admin-function">
        <strong>Returns the XP required for the next level. (level)</strong>
        <label>
          currentLevel
          <input type="number" value={level} onChange={(e) =>{setLevel(e.target.value)}} />
        </label>
        <Button className="MuiButton-containedHighlight" onClick={() =>{nextLevelXpByInput(level)}} color="primary" variant="contained">nextLevel</Button>
      </div>

      {/* currentLevel - console.log */}
      <div className="admin-function">
        <strong>Returns the current level. (xp)</strong>
        <label>
          currentXP
          <input type="number" value={xp} onChange={(e) =>{setXp(e.target.value)}} />
        </label>
        <Button className="MuiButton-containedHighlight" onClick={() =>{currentLevelByInput(xp)}} color="primary" variant="contained">currentLevel</Button>
      </div>

      {/* checkLevelUp - console.log */}
      <div className="admin-function">
        <strong>Checks if there was a level up. (startingXP, endingXP)</strong>
        <label>
          startingXP
          <input type="number" value={xp} onChange={(e) =>{setXp(e.target.value)}} />
        </label>
        &nbsp;&nbsp;
        <label>
          endingXP
          <input type="number" value={endingXp} onChange={(e) =>{setEndingXp(e.target.value)}} />
        </label>
        <Button className="MuiButton-containedHighlight" onClick={() =>{levelUpByInput()}} color="primary" variant="contained">checkLevelUp</Button>
      </div>

      {/* herofit-avatar - removeItem from LS - console.log */}
      {/* herofit-stravaActivities - removeItem from LS - console.log */}
      {/* clear everything from LS - console.log */}
      <div className="admin-function">
        <Button className="MuiButton-containedHighlight" onClick={() =>{localStorage.removeItem('herofit-avatar');console.log(`Removed HERO from LS - ${localStorage.getItem('herofit-avatar')}`)}} color="primary" variant="contained" disabled={ localStorage.getItem('herofit-avatar') ? false : true }>Remove Hero LS</Button>
        <Button className="MuiButton-containedHighlight" onClick={() =>{localStorage.removeItem('herofit-stravaActivities');console.log(`Removed ACTIVITIES from LS - ${localStorage.getItem('herofit-stravaActivities')}`)}} color="primary" variant="contained" disabled={ localStorage.getItem('herofit-stravaActivities') ? false : true }>Remove Activities LS</Button>
        <Button className="MuiButton-containedHighlight" onClick={() =>{localStorage.clear();console.log(`Removed EVERYTHING from LS`)}} color="primary" variant="contained">Clear LS</Button>
      </div>

      <div className="admin-function">
        {/* Set health and Hero to Recoverying status */}
        <label>
          Set health and Hero to Recoverying status
          <input type="number" value={health} onChange={(e) =>{setHealth(e.target.value)}} />
        </label>
        <Button className="MuiButton-containedHighlight" onClick={() =>{setAvHS({ "status" : 'Recovering', health, id : avatar.id });console.log(`Set Hero to Recoverying`)}} color="primary" disabled={ avatar ? false : true } variant="contained">Set Hero to Recovering</Button>
      </div>

      <div className="admin-function">
        {/* Toggle the marketing modal to true or false for all users */}
        <label>
          Toggle the marketing modal to true or false for all users
          <input type="checkbox" defaultChecked={feedbackSubmitted} onChange={() => setFeedbackSubmitted(!feedbackSubmitted)}  /> SET TO: {feedbackSubmitted ? 'true' : 'false'}
        </label>
        <Button className="MuiButton-containedHighlight" onClick={() =>{toggleFeedbackForAll(feedbackSubmitted);console.log(`Setting all users 'featurePreferenceSubmitted' column to ${feedbackSubmitted} `)}} color="primary" disabled={ avatar ? false : true } variant="contained">set feedback submitted to {feedbackSubmitted ? 'true' : 'false'}</Button>
      </div>
    
    
      <div className="admin-function admin-function-add-items">
        {/* Add A Default Item to the avatar_equip table with a certain avatarID */}
        <strong>Add A Default Item to the avatar_equip table with a certain avatarID</strong><br />
        <strong>Or enter 0 for all avatars</strong>
        <label>
          Avatar ID:
          <input type="text" value={addItemToId} onChange={(e) =>{setAddItemToId(e.target.value)}} />
        </label>
        <br /><br />
        <strong>Skins</strong>
        <ul>
          { allItems?.skins.map((skin, i) =>{
            return <li key={i} onClick={(e) => addItemToAv(skin, addItemToId) }>{skin.name}</li>
          }) }
        </ul>

        <strong>Pets</strong>
        <ul>
          { allItems?.pets.map((pet, i) =>{
            return <li key={i} onClick={(e) => addItemToAv(pet, addItemToId) }>{pet.name}</li>
          }) }
        </ul>

        <strong>Titles</strong>
        <ul>
          { allItems?.titles.map((title, i) =>{
            return <li key={i} onClick={(e) => addItemToAv(title, addItemToId) }>{title.name}</li>
          }) }
        </ul>

        <strong>Consumables</strong>
        <ul>
          { allItems?.consumables.map((consumable, i) =>{
            return <li key={i} onClick={(e) => addItemToAv(consumable, addItemToId) }>{consumable.name}</li>
          }) }
        </ul>

        <strong>Codices</strong>
        <ul>
          { allItems?.codices.map((codex, i) =>{
            return <li key={i} onClick={(e) => addItemToAv(codex, addItemToId) }>{codex.name}</li>
          }) }
        </ul>
      </div>

    
    </section>

    
  ;

  return  <div>

    <DialogTemplate 
      type={'basic'}
      identifier={'admin-panel'}
      title={null}
      text={null}
      content={content} 
      cancelButton={true}
      confirmButton={null}
      isOpen={modalOpen} 
      handleClose={() => setModalOpen(false)} 
      disableBackdropClick={false} 
    /> 

  </div>
};

export default AdminPanel;

AdminPanel.propTypes = {
  modalOpen : PropTypes.bool.isRequired,
  setModalOpen : PropTypes.func.isRequired,
  updateState : PropTypes.func.isRequired,
  avatar : PropTypes.object,
  activityData : PropTypes.array,
  admin : PropTypes.object.isRequired,
  defaultItems : PropTypes.array.isRequired,
};
