import React from "react";
// LIBRARIES
import PropTypes from "prop-types";
// COMMON
// COMPONENTS
import DialogTemplate from "../../Dialog/DialogTemplate";
import { MdLinearScale as PageBreak } from "react-icons/md";
import { TiArrowRightOutline as ArrowRight } from "react-icons/ti";
import { GiStrong } from "react-icons/gi";
//import Hexagon from '../../Hexagon/Hexagon';

const UpgradeHero = function ({
  modalOpen,
  setModalOpen,
  handleModalAction,
  avatarName,
  isLoading,
}) {
  const messages = [
    "Ahhh, training I see... Your flesh may be weak but your dedication is promising! Keep it up my fledgling.",
    "Very nice work... Now do it again!",
    "Training like that make you strong like cow... I mean bull.",
    "Improving at such a rapid pace I see. Soon you will be teaching me. ",
    "Someday I'll show you the ultimate defensive technique. You can rotate your neck 180 degrees... right?",
  ];
  const message = messages[Math.floor(Math.random() * messages.length)];

  function handleModalSave() {
    handleModalAction();
  }

  const content = (
    <div>
      <div className="page-break">
        <PageBreak />
      </div>
      <div className="action-to-take">
        <ArrowRight />{" "}
        <strong id="secondaryMessage">
          Empower <span className="brand-highlight">{avatarName}</span> with your
          latest Strava activities!
        </strong>
      </div>
      <div className="more-content">
        <GiStrong style={{ fontSize: "7em", color: "#d4af37" }} />
      </div>
      {/* <Hexagon button={true} diameter={120} color={"#D4AF37"} border={6} borderColor="#F1C85B" icon={ <GiUpgrade  /> } iconStyles={{ color: '#fff', size: '2.4em' }} text={"Upgrade Hero"} textStyles={{color : '#fff', fontSize: '1.6em', fontWeight: 'bold' }}  /> */}
    </div>
  );

  return (
    <div>
      <DialogTemplate
        type={"speech"}
        identifier={"upgrade-hero"}
        character={"owl"}
        title={null}
        text={message}
        content={content}
        cancelButton={true}
        confirmButton="Upgrade Hero"
        isOpen={modalOpen}
        handleSave={handleModalSave}
        handleClose={() => setModalOpen(false)}
        // Not really needed, but might prevent double clicking.
        disableButton={!modalOpen}
        disableBackdropClick={false}
        isLoading={isLoading}
        closeOnSave={false}
      />
    </div>
  );
};

export default UpgradeHero;

UpgradeHero.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  handleModalAction: PropTypes.func.isRequired,
  avatarName: PropTypes.string.isRequired,
};
