import _axios from 'axios';
import axiosRetry from 'axios-retry';
import { axiosOptions } from '../common/axiosDefaults';
import { handleHttpError } from '../common/errorHandler';
const endpoint = process.env.REACT_APP_HF_ENDPOINT;

let axios = _axios.create();
axiosRetry(axios, {retries: 3});

const fetchAstrologySeason = function(body){
  return axios.post(`${endpoint}calculate/fetch-astrology-season`, body, axiosOptions())
  .then(({ data }) => {
      return data.data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};

const fetchCurrentLevelByInput = function(body){
  return axios.post(`${endpoint}calculate/fetch-current-level-by-input`, body, axiosOptions())
  .then(({ data }) => {
    return data.data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};

const fetchNextLevelXpByInput = function(body){
  return axios.post(`${endpoint}calculate/fetch-next-level-xp-by-input`, body, axiosOptions())
  .then(({ data }) => {
    return data.data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};

const checkLevelUpByInput = function(body){
  return axios.post(`${endpoint}calculate/check-level-up-by-input`, body, axiosOptions())
  .then(({ data }) => {
    return data.data;
  }).catch(({ request, response }) => {
    return handleHttpError(request, response);
  });
};

export { fetchAstrologySeason, fetchCurrentLevelByInput, fetchNextLevelXpByInput, checkLevelUpByInput }