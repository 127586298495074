import React, { useState, useEffect } from 'react';
// LIBRARIES
import PropTypes from 'prop-types';
import moment from 'moment';
import { FaSort } from 'react-icons/fa';
// COMMON
import * as Icons from '../../common/icons.js';
import af from '../../common/activityCalculations';
import { usePrevious } from '../../common/hookHelpers';
import {capitalize} from '../../common/helperFunctions';
// STYLES
import './ActivitiesTable.scss';
const TIME_AND_HALF_ACTIVITIES = ['Run', 'StairStepper', 'Crossfit', 'Elliptical', 'Swim'];


const ActivitiesTable = function({ activityData, tableType }){
  // Sort activities by most recent
  activityData.sort((act1, act2) =>{
    return moment(act1.activityDate).isBefore(moment(act2.activityDate)) ? 1 : -1;
  });

    // Figure out if there is data for each column, and conditionally show if so
    let includedColumns = { hasDistance : false, hasAverageSpeed : false, hasMaxSpeed : false, hasElevationGain : false};
    includedColumns = af.checkForDistanceColumns(activityData, includedColumns);
    let { hasDistance, hasAverageSpeed, hasMaxSpeed, hasElevationGain} = includedColumns;

  /* 
  
    AllActivities Table only 

  */
 
  const [activities, setActivities] = useState(activityData);
  const [column, setColumn] = useState('date');

  // Sort data by column on click
  function sortByColumn(selectedCol){
    // If clicked column is the same as the already selected column, reverse it
    if(column === selectedCol){
      setActivities([...activities.reverse()]);
      setColumn(selectedCol);
      return;
    }

    // Sort by selected column
    switch(selectedCol){
      
      case 'date' :
        setActivities([...activities].sort((act1, act2) =>{
          return moment(act1.activityDate).isBefore(moment(act2.activityDate)) ? -1 : 1;
        }));
        break;

      case 'type' :
        setActivities([...activities].sort((act1, act2) =>{
          let a = act1.type.toLowerCase();
          let b = act2.type.toLowerCase();
          return a < b ?  -1 : 1;
        }));
        break;

      case 'distance' :
        setActivities([...activities].sort((act1, act2) =>{
          let a = act1.distance;
          let b = act2.distance;
          return a < b ?  1 : -1;
        }));

        break;

      case 'duration' :
        
        setActivities([...activities].sort((act1, act2) =>{ 
          let a = act1.duration;
          let b = act2.duration;
          return a < b ?  1 : -1;
        }));
        break;
      
      case 'averageSpeed' :
        setActivities([...activities].sort((act1, act2) =>{ 
          let a = act1.averageSpeed;
          let b = act2.averageSpeed;
          return a < b ?  1 : -1;
        }));
        break;

      case 'maxSpeed' :
        setActivities([...activities].sort((act1, act2) =>{ 
          let a = act1.maxSpeed;
          let b = act2.maxSpeed;
          return a < b ?  1 : -1;
        }));
        break;
      
      case 'elevationGain' :
        setActivities([...activities].sort((act1, act2) =>{ 
          let a = act1.elevationGain;
          let b = act2.elevationGain;
          return a < b ?  1 : -1;
        }));
        break;
      
      default:
    }

    // Set column in state
    setColumn(selectedCol);
  } 

  const prevActivities = usePrevious(activityData);
  useEffect(() => {
    // Only use this hook for the AllActivities table on the training page
    if(tableType === 'all'){
      // If the pages has already loaded and activities are in state, sort column AND adjust activity order by the sort
      if(prevActivities && prevActivities.activityData !== activityData) {
        sortByColumn('date');
        setActivities([...activityData]);
      // Otherwise, don't sort the array by date again, it's being sorted depending on which column was clicked and then the activities state is being set below
      }else{
        setActivities([...activityData]);
      }
    // If Latest Activities, only display latest 10
    }else{
      sortByColumn('date');
      setActivities([...activityData].slice(0,10));
    }

  }, [activityData, prevActivities]);

  /* 
  
    END - AllActivities Table only 

  */
  
  function renderThead(){
    if(tableType === 'all'){
      return <thead>
          <tr>
            <th colSpan="2" onClick={(() =>{sortByColumn('date')})}><FaSort /> Date</th>
            <th onClick={(() =>{sortByColumn('duration')})}><FaSort /> Gains</th>
            <th onClick={(() =>{sortByColumn('type')})}><FaSort /> Activity</th>
            { hasDistance ? <th onClick={(() =>{sortByColumn('distance')})}><FaSort /> Distance</th> : null }
            <th onClick={(() =>{sortByColumn('duration')})}><FaSort /> Duration</th>
            { hasAverageSpeed ? <th onClick={(() =>{sortByColumn('averageSpeed')})}><FaSort /> Avg Speed</th> : null }
            { hasMaxSpeed ? <th onClick={(() =>{sortByColumn('maxSpeed')})}><FaSort /> Max Speed</th> : null }
            { hasElevationGain ? <th onClick={(() =>{sortByColumn('elevationGain')})}><FaSort /> Elevation Gain</th> : null }

          </tr>
        </thead>
    }else if(tableType === 'latest'){
      return <thead>
        <tr>
          <th colSpan="2">Latest Activities</th>
          <th>Gains</th>
          <th>Activity</th>
          { hasDistance ? <th>Distance</th> : null }
          <th>Duration</th>
          { hasAverageSpeed ? <th>Avg Speed</th> : null }
          { hasMaxSpeed ? <th>Max Speed</th> : null }
          { hasElevationGain ? <th>Elevation Gain</th> : null }
          <th>Source</th>
        </tr>
      </thead>
    }
    return null;
  }

  function renderTbody(){
    return(
      <tbody>
        {
          //console.log('AA - ', activities);
          activities.map((activity, i) => {
            const date = moment(activity.activityDate).format('MMM Do, h:mm a');
            const activityTypes = ["Crossfit","Rowing","Kayaking","StandUpPaddling","Yoga","WeightTraining","Swim","Elliptical","StairStepper","Walk","Ride","Hike","Run", "VirtualRide", "VirtualRun", "Snowshoe", "AlpineSki", "BackcountrySki", "NordicSki", "Snowboard"];
            // Divide element bonus by all 4 elements, only for unsupported activities
            const allElements = activityTypes.includes(activity.type) ? false : true;
            const isTimeAndHalfActivity = TIME_AND_HALF_ACTIVITIES.includes(activity.type);

            return (<tr key={i}>
              <td>{i + 1}</td>
              <td>{date}</td>
              <td>
            <span className="no-wrap">{af.calculateXPBonus(activity.duration, isTimeAndHalfActivity)}&nbsp;<strong>xp</strong></span><br />
                <span className="no-wrap">{af.calculateElementBonus(activity.duration, allElements, isTimeAndHalfActivity)}  {af.determineElementIcon(activity.type)}</span>
              </td>
              <td><span className="activity-icon-wrap">{af.determineActivityIcon(activity.type)}</span></td>
              { hasDistance ? <td>{af.convertMetersToMiles(activity.distance)}</td> : null }
              <td>{af.convertSecondsToReadableTime(activity.duration)}</td>
              { hasAverageSpeed ? <td>{af.convertMetersSecondsToMilesHours(activity.averageSpeed)}</td> : null }
              { hasMaxSpeed ? <td>{af.convertMetersSecondsToMilesHours(activity.maxSpeed)}</td> : null }
              { hasElevationGain ? <td>{af.convertMetersToFeet(activity.elevationGain)}</td> : null }
              <td><span>{capitalize(activity.source)}</span></td>
            </tr>)
          })
        }
    </tbody>
    )
  }

  // There are two different types of tables rendered depending on which page the user is on
  return(
    <div>
      <table id={tableType === 'all' ? 'allActivityTable' : 'latestActivityTable'} className={`table ${tableType === 'light' ? 'table-light' : tableType === 'dark' ?  'table-dark' : ''} table-striped table-bordered table-hover`}>
        { renderThead() }
        { renderTbody() }
      </table>
    </div>
  );
}

export default ActivitiesTable;

ActivitiesTable.propTypes = {
  activityData : PropTypes.arrayOf(PropTypes.object).isRequired,
  tableType : PropTypes.string.isRequired,
  user : PropTypes.string 
};