import React, { useState, useEffect } from "react";
import heroList from "../../common/allyList.json";
import HEROES from "../../common/includedHeroImages";
import { lowercaseSpace, cloneObj, determineSkinType, determineSkinName, } from "../../common/helperFunctions";
import { cookieConfirmPrompt } from "../../common/initFunctions";
import "./SelectAvatarPage.scss";
// LIBRARIES
import PropTypes from "prop-types";
import history from "../../common/history";

// COMPONENTS
import Header from "../Header/Header";
import SelectAvatarModal from "./Modals/SelectAvatarModal";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import SpendQP from "./Modals/SpendQP";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 21,
  },
  arrow: {
    color: theme.palette.common.white,
  },
}))(Tooltip);

const SelectAvatarPage = function ({ selectAvatar, allowSelection, MainMenuButton, updateState, finishSettingInitialStats }) {
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [selectAvatarModalOpen, setSelectAvatarModalOpen] = useState(false);
  const [spendQPModalOpen, setSpendQPModalOpen] = useState(false);

  // WHEN A USER CLICKS A HERO
  const handleClickOpen = (clickedAlly) => {
    const selected = cloneObj(
      heroList.find((ally) => {
        return ally.character.toLowerCase() === lowercaseSpace(clickedAlly);
      }),
      true
    );

    const avatar = {
      name: null,
      status: "New Recruit",
      character: selected.character,
      alias: selected.alias,
      history: selected.history,
      power: 100,
      health: 100,
      maxHealth: 100,
      skins: [],
      items: [],
      pets: [],
      titles: [],
      earth: selected.elms.earth,
      air: selected.elms.air,
      water: selected.elms.water,
      fire: selected.elms.fire,
      recovery: 5,
      armor: 0,
      activityXP: 0,
      battleXP: 0,
      qp: 10,
      hasBeenUpgraded : false,
      restedEnough: true,
      level: 1,
      qpRecovery: 0,
      qpArmor: 0,
      qpPower: 0,
      qpFire: 0,
      qpEarth: 0,
      qpWater: 0,
      qpAir: 0,
      qpHealth: 0,
      equipped : []
    };
    setSelectedAvatar(avatar);
    setSelectAvatarModalOpen(true);
  };
  const handleClose = () => {};

  // WHEN A USER NAMES AN AVATAR AND CLICKS SELECT
  const openSpendQP = function (avatarName) {
    const namedAvatar = Object.assign({}, selectedAvatar, { name: avatarName });
    setSelectedAvatar(namedAvatar);
    setSelectAvatarModalOpen(false);
    setSpendQPModalOpen(true);
  };

  function renderHeroes() {
    return heroList.map((hero, i) => {
      const alias = heroList.find((a) => {
        return a.character.toLowerCase() === lowercaseSpace(hero.character);
      }).alias;

      return (
        <div className="clipped-border" key={i}>
          <a onClick={handleClickOpen.bind(this, hero.character)}>
            <LightTooltip title={alias} arrow TransitionComponent={Zoom}>
              <img id="avatarImage" src={HEROES[determineSkinType('base')][determineSkinName('base', hero.character)]} alt={hero.alias} />
            </LightTooltip>
          </a>
        </div>
      );
    });
  }

  function renderHeader() {
    if (history.location.pathname === "/hero-gallery") {
      return (
        <Header
          title={`Heroes`}
          subtitle={`Tap for Descriptions`}
          MainMenuButton={MainMenuButton}
        />
      );
    } else if (history.location.pathname === "/select-hero") {
      return (
        <Header
          title={`Choose Hero`}
          subtitle={`Tap for Descriptions`}
          MainMenuButton={MainMenuButton}
        />
      );
    }
    return null;
  }

  function renderModals() {
    if (selectedAvatar) {
      return (
        <div>
          <SelectAvatarModal
            modalOpen={selectAvatarModalOpen}
            setModalOpen={setSelectAvatarModalOpen}
            handleModalAction={(name) => openSpendQP(name)}
            allowSelection={allowSelection}
            handleClose={handleClose}
            handleSelection={openSpendQP}
            selectedAvatar={selectedAvatar}
          />
          <SpendQP
            modalOpen={spendQPModalOpen}
            setModalOpen={setSpendQPModalOpen}
            handleModalAction={finishSettingInitialStats}
            selectedAvatar={selectedAvatar}
            setSelectedAvatar={setSelectedAvatar}
          />
        </div>
      );
    }
    return null;
  }

  useEffect(() =>{
    cookieConfirmPrompt(updateState);
  },[])

  return (
    <section id="selectAvatarPage" className="background-dusk page">
      {renderHeader()}
      <div className="gallery">{renderHeroes()}</div>
      {renderModals()}
    </section>
  );
};

export default SelectAvatarPage;

SelectAvatarPage.propTypes = {
  selectAvatar: PropTypes.func,
  allowSelection: PropTypes.bool,
  MainMenuButton: PropTypes.object,
  updateState: PropTypes.func,
  finishSettingInitialStats : PropTypes.func,
};
