import React from 'react';
import './assets/styles/styles.scss';
//import { Router,Route, Switch, Redirect } from 'react-router';
import { Router,Route, Switch, Redirect } from 'react-router-dom';
// LIBRARIES
import moment from 'moment';
// COMMON
import { getUrlParamsAfterHash, getLsWithExpiry, setLsWithExpiry, isPrivateRoute, isPublicRoute, alertAdder, alertRemover,  cloneObj, convertItemIdsToFullItems } from './common/helperFunctions';
import { fetchAllGameItems, cookieConfirmPrompt, checkLocalJwt, stravaFirstTimeUsers, adminPanelBinding, equipItemsOnAvatar } from './common/initFunctions';
import history from './common/history';
import { privateRoutes, publicRoutes } from './common/routes';
import { debugErrors } from './common/errorHandler';
// SERVICES (HTTP Requests)
import { insertAvatar, upgradeSequence, getAvatar } from './services/avatarService';
import { login, register, getStravaClientCredentials, insertStravaCredentials } from './services/authService';
import { exchangeStravaAuthCode, getStravaActivityData, getNewAccessToken, getStravaUserId } from './services/stravaService';
import { getUser, getSavedActivities, instantiateUserTotals, updateUserTotals } from './services/userService';
import { fetchBattleReport } from './services/battleService';
import { fetchAstrologySeason } from './services/calculateService';

// COMPONENTS
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import LoadingOverlay from './components/LoadingOverlay/LoadingOverlay';
import Sidebar from './components/Sidebar/Sidebar';
import MainMenuButton from './components/Header/MainMenuButton';
import AccountPage from './components/AccountPage/AccountPage';
import HomePage from './components/HomePage/HomePage';
import TrainingPage from './components/TrainingPage/TrainingPage';
import CampaignPage from './components/CampaignPage/CampaignPage';
import RankingPage from './components/RankingPage/RankingPage';
import ProfilePage from './components/ProfilePage/ProfilePage';
import InventoryPage from './components/InventoryPage/InventoryPage';
import ContactPage from './components/ContactPage/ContactPage';
import AboutPage from './components/AboutPage/AboutPage';
import BattlesPage from './components/AboutPage/BattlesPage';
import SignupRewardsPage from './components/AboutPage/SignupRewardsPage';
import ItemsPage from './components/AboutPage/ItemsPage';
import NotFoundPage from './components/NotFoundPage/NotFoundPage';
import SelectAvatarPage from './components/SelectAvatarPage/SelectAvatarPage';
import WelcomePage from './components/WelcomePage/WelcomePage';
import TermsOfServicePage from './components/LegalPages/TermsOfServicePage';
import PrivacyPolicyPage from './components/LegalPages/PrivacyPolicyPage';
import OAuthPage from './components/AppAuth/OAuthPage';
import Footer from './components/Footer/Footer';
import AdminPanel from './components/Dialog/Modals/AdminPanel';

class App extends React.Component {

  constructor(){
    super();
    this.state = {
      loggedIn : false,
      admin : {
        createdAt : ""
      },
      stravaAuthCode : null,
      urlParams : {},
      activityData : [],
      upgradeAvailable : false,
      appIsLoading : true,
      menuOpen : false,
      adminPanelOpen : false,
      alerts : [],
      defaultItems : [],
      battleReport : null
    };
    // functions passed to child components
    this.buffAvatar = this.buffAvatar.bind(this);
    this.clearAlert = this.clearAlert.bind(this);
    this.updateState = this.updateState.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.finishSettingInitialStats = this.finishSettingInitialStats.bind(this);
    this.handlePostRegister = this.handlePostRegister.bind(this);
    this.handlePostLogin = this.handlePostLogin.bind(this);
    this.equipItemsOnAvatar = this.equipItemsOnAvatar.bind(this);
  }

  clearAlert(index){
    const filtered = this.state.alerts.filter((alert) => alert.index !== index);
    this.setState({ alerts : filtered });
  }

  // EXPECTS object of any new pieces of state to update
  updateState(newState, cb){
    // If alerts exists in newState, add them to existing using 'alertAdder, then merge new state into application state
    if(newState.alerts && newState.alerts.length){
      // Alerts are NEW and need to be added with new indicies, then added to state with any other state
      const { newAlertArray, fadeOutAlerts }  = alertAdder(this.state.alerts, newState.alerts);
      const updatedState = Object.assign({}, this.state, newState, { alerts : newAlertArray });
      this.setState( updatedState , cb);
      setTimeout(() =>{ 
        this.setState({ alerts : alertRemover(this.state.alerts, fadeOutAlerts) });
      }, 9800);
    // If no alerts or an array of zero alerts are present, just update state
    }else{
      const updatedState = Object.assign({}, this.state, newState);
      this.setState(updatedState, cb);
    }  
  }

  componentDidMount(){
    const { pathname } = history.location;
    fetchAllGameItems(this.updateState);

    // PRIVATE ROUTES
    if(isPrivateRoute(privateRoutes, pathname)){
      // Prompt users about cookies if they haven't confirmed
      cookieConfirmPrompt(this.updateState);
      
      // Check for local JWT
      const jwtToken = checkLocalJwt(this.updateState);

      // Admin Panel Key Binding
      adminPanelBinding(this.updateState, jwtToken);

      // handle StravaCode for First Time Users
      const stravaParam = stravaFirstTimeUsers(this.updateState, pathname);    
      
      // Run functions that fetch user, hero, and game data
      this.initializeGame(stravaParam);

    // PUBLIC ROUTES
    }else if( isPublicRoute(publicRoutes, pathname) ){
      const stateUpdate = { appIsLoading : false };
      // Not sure if I need this... why would the avatar need to be added when users are visiting a public route
      // const lsAvatar = getLsWithExpiry('herofit-avatar');
      // if(lsAvatar){
      //   stateUpdate.avatar = lsAvatar;
      // }

      this.setState(stateUpdate);
 
    }else{
      // If neither a public nor private route, send them to the 404 page
      this.setState({appIsLoading : false});
      history.push(`/404-page-not-found`);
    }
    
  } // END OF COMPONENTDIDMOUNT

  // first time signup, need to insert avinsertAvatarIntoDb
  handlePostRegister(user){
    //console.log('HERE in post register', user);
    this.updateState({ admin : user, loggedIn : true });
    new Promise(() =>{
      return this.insertAvatarIntoDb(this.state.avatar);
    }).then((data) =>{
      //console.log('data from inserting av into db', data);
    }).catch((error) =>{
      // Error getting Avatar, should only happen if DB connection issues
      debugErrors(error, this.state.admin);
      this.updateState({alerts : [{type : 'error', message :`${error.status}: ${error.message}`}], appIsLoading : false});
    });
  }

  handlePostLogin(user, avatar){
    this.updateState({ admin : user, avatar, loggedIn : true });
    history.push('/');

    // Takes item instance IDs and assigns full items to the hero under 'equipped' property
    this.equipItemsOnAvatar(avatar);

    // Fetch battle report from previous battle if unseen
    fetchBattleReport({ avatarID : avatar.id })
    .then((data) =>{
      if(data.error){
        const error = data.error;
        error.message = `${error.status}: Unable to fetch latest Battle Report.`;
        this.updateState({ alerts : [{ type : 'error', message : error.message }] });
        return debugErrors(error, this.state.admin);
      }
      const result = data.result;
      if(result){
        const stateUpdate = {battleReport : result};
        if(result.itemsAcquired){
          const alerts = result.itemsAcquired.map((item) =>{
            let itemType = item.type === 'skin' ? 'costume' : item.type;
            return { type : 'success', message : `New ${itemType} - ${item.name}!!!` };
          });
          stateUpdate.alerts = alerts;
        }

        this.updateState(stateUpdate);
      }
    });
  }

  // FOR ONE TIME STRAVA PERMISSIONS...
  urlReturnStravaCode(){
    // First, get saved Strava App client credentials from my server  
    // Then use that info along with the auth code retrieved from the strava redirect
    return getStravaClientCredentials()
    .then((data) =>{
      if(data.error){
        // Couldn't retrieve Strava Client credentials from our DB
        const error = data.error;
        debugErrors(error, this.state.admin);
        return this.updateState({appIsLoading : false, alerts : [{type : 'error', message :`${error.status}: ${error.message}`}]});
      }

      let credentialsForDB;
      new Promise((resolve, reject) =>{
        // Exchange auth code for access and refresh tokens
        return exchangeStravaAuthCode({clientId : data.clientId, clientSecret: data.clientSecret, code: this.state.stravaAuthCode}, resolve, reject);
      }).then((data) =>{
        credentialsForDB = {
          stravaAccessToken : data.access_token,
          stravaAccessTokenExpiration : data.expires_at,
          stravaRefreshToken : data.refresh_token,
        };

        // NEW - FETCH USER INFO TO GET STRAVA ID, WHICH WILL ALSO BE SAVED TO DB
        // IF ID IS ALREADY IN USE, DISPLAY PERSISTENT ALERT AND EXIT THIS FUNCTION
        
     
        return new Promise((resolve, reject) =>{
          return getStravaUserId(credentialsForDB.stravaAccessToken, resolve, reject);
        })
      }).then((data) =>{
        // Insert user-specific Strava credentials into our db
        return insertStravaCredentials({...credentialsForDB, dataSrcId : data.id, email : this.state.admin.email})
        .then((data) =>{
          if(data.error){
            // Couldn't retrieve new Strava activities
            const error = data.error;
            throw error;
          }

          const admin = Object.assign({}, this.state.admin, {...data.user, ...credentialsForDB });
          this.setState({admin, appIsLoading : false});
          // Fetch old activity data from DB & new Strava data, then route user home
          this.fetchActivityData();
          history.push('/');
        }).catch((error) =>{
          throw error;
        });
      }).catch((error) =>{
        if(error.status === 400){
          error.message = error.meta;
        }
        // Error Inserting with getting or updating strava credentials
        debugErrors(error, this.state.admin);
        return this.updateState({appIsLoading : false, alerts : [{type : 'error', message : `${error.status}: ${error.message}`}]});
      });

    });

  }

  initializeGame(stravaParam){
    const { code = null, route = '/' } = stravaParam;
    getUser({ initMessage : true })
    .then((data) =>{
      if(data.error){
        const error = data.error;
        // Down for maintenance
        if(error.status === 503){
          error.message = error.debug[0].msg;
        }
        // JWT expired!
        debugErrors(data.error, this.state.admin);
        return this.updateState({appIsLoading : false, alerts : [{ type : 'error', message : error.message }]}, () =>{
          this.handleLogout();
        },750);
      }
      
      const { user } = data;
      const admin = Object.assign({}, this.state.admin, { ...user });
      const stateObj = { admin, loggedIn : true };
      
      // Messages for the users from HeroFit about the game
      if(data.globalMessage){
        stateObj.alerts = [...data.globalMessage];
      }
      // Update admin, loggedIn, and globalMessage Alerts in state
      this.updateState(stateObj);

      // Need to check if avatar exists before proceeding
      getAvatar({ email : admin.email })
      .then((data) =>{
        if(data.error){
          throw data.error;
        }
        const { avatar } = data;
        //console.log('AVATAR', avatar);
        // New users will have an avatar saved in LS
        const lsAvatar = getLsWithExpiry('herofit-avatar');

        // HAS AVATAR IN DB, fetch activity data and proceed as normal          
        if(avatar){
          this.updateState({avatar : cloneObj(avatar, true)});      
          
          // FOR ONE TIME STRAVA PERMISSIONS, need to exchange Strava auth code for refresh token & access token before fetching data from strava
          if(code && (code !== 'noReadAll')){
            this.urlReturnStravaCode();
          // User didn't give read all permission, redirect back to home page
          }else if(code === 'noReadAll'){
            history.push(route);
          }else{
            // Last step, IF STRAVA IS ALREADY CONNECTED, fetch activity data from strava
            if(this.state.admin.stravaRefreshToken){
              this.fetchActivityData();
            }
            // Navigate to specified route
            history.push(route);
            this.setState({ appIsLoading : false });

            // Takes item instance IDs and assigns full items to the hero under 'equipped' property
            this.equipItemsOnAvatar(avatar);

            // Fetch battle report from previous battle if unseen
            fetchBattleReport({ avatarID : avatar.id })
            .then((data) =>{
              if(data.error){
                const error = data.error;
                error.message = `${error.status}: Unable to fetch latest Battle Report.`;
                this.updateState({ alerts : [{ type : 'error', message : error.message }] });
                return debugErrors(error, this.state.admin);
              }
              const result = data.result;
              if(result){
                const stateUpdate = {battleReport : result};
                if(result.itemsAcquired){
                  const alerts = result.itemsAcquired.map((item) =>{
                    let itemType = item.type === 'skin' ? 'costume' : item.type;
                    return { type : 'success', message : `New ${itemType} - ${item.name}!!!` };
                  });
                  stateUpdate.alerts = alerts;
                }
    
                this.updateState(stateUpdate);
              }
            });
          }

        // HAS AVATAR IN LS, new user needs avatar saved to db
        }else if(lsAvatar && this.state.admin.email){
          // EDGE CASE - where user makes an account before selecting avatar.
          // FOR ONE TIME STRAVA PERMISSIONS, need to exchange Strava auth code for refresh token & access token before fetching data from strava
          if(code && (code !== 'noReadAll')){
            this.urlReturnStravaCode();
          }
          // Regardless of if a user is coming back from strava or not, the Avatar needs to be saved to DB
          new Promise((resolve, reject) =>{
            this.insertAvatarIntoDb(lsAvatar);
          }).then((data) =>{
            history.push(route);
          }).catch((error) =>{
            debugErrors(error, this.state.admin);
          });
          
        // No Avatar Exists
        }else{
          // If no avatar exists and the user has already signed in, they are routed to /select-hero page to pick one
          // Can happen if user doesn't pick an avatar right away
          history.push(`/select-hero`);
          setTimeout(()=>{this.setState({ appIsLoading : false });},750);
        }

      }).catch((error) =>{
        const lsAvatar = getLsWithExpiry('herofit-avatar');

        // first time signup, need to insert avinsertAvatarIntoDb
        if(lsAvatar){
          new Promise(() =>{
            return this.insertAvatarIntoDb(lsAvatar);
          }).then((data) =>{
            history.push(route);
          }).catch((error) =>{
            // Error getting Avatar, should only happen if DB connection issues
            debugErrors(error, this.state.admin);
            localStorage.clear();
            history.push('/welcome');
            this.updateState({alerts : [{type : 'error', message :`${error.status}: ${error.message}`}], appIsLoading : false});
          });
        // User likely signed up before selecting hero, need to redirect them
        }else{
          history.push('/select-hero');
        }


      });
    });
  }

  // First, retrieve OLD activity Data from DB, then fetch new data from strava
  fetchActivityData(){
    getSavedActivities({ email : this.state.admin.email })
    .then((data) =>{
      if(data.error){
        const error = data.error;
        error.message = "Couldn't retrieve saved activities, please try again later";
        this.updateState({ alerts : [{ type : 'error', message : error.message }] });
        return debugErrors(error, this.state.admin);
      }

      const activityData = data;
      const dateOfLatestSaved = data.latestActivityDate ? moment.utc(data.latestActivityDate, 'YYYY-MM-DD[T]HH:mm[Z]') : null;
      this.setState({ activityData : activityData.activities });
      // GET STRAVA ACTIVITIES TO FIGURE OUT IF THE USER HAS NEW UPGRADES TO ADD
      const lsSavedStravaActivities = getLsWithExpiry('herofit-stravaActivities');

      // Only try fetching new activities if user is connected to Strava
      if(this.state.admin.dataSrcId){
        if(lsSavedStravaActivities){
          this.handleStravaActivities(lsSavedStravaActivities, dateOfLatestSaved);
        }else{
          this.getNewActivities(dateOfLatestSaved);
        }
      }
    });
  }

  checkStravaToken(){
    const accessTokenExpiration = this.state.admin.stravaAccessTokenExpiration;
    const nowEpoch = moment().valueOf() / 1000;
    // Check if access token is valid, if so, just resolve the promise and move on
    if(accessTokenExpiration > nowEpoch && this.state.admin.stravaAccessToken){
      //console.log('TOKEN VALID, using existing access token');
      return Promise.resolve(this.state.admin.stravaAccessToken);
    }
    //console.log('TOKEN INVALID, refreshing access token');
    
    // otherwise, we need to run the sequence to update the access token
      // Get Strava client Credentials from my server
    return getStravaClientCredentials()
      
    .then((data) =>{
      if(data.error){
        const error = data.error;
        // Couldn't retrieve strava credentials
        debugErrors(data.error, this.state.admin);
        return this.updateState({appIsLoading : false, alerts : [{type : 'error', message : `${error.status}: ${error.message}`}]});
      }
      const authData = {clientId : data.clientId, clientSecret : data.clientSecret, refreshToken : this.state.admin.stravaRefreshToken};
      return new Promise((resolve, reject) =>{
        return getNewAccessToken(authData, resolve, reject);
      });
    }).then((data) =>{
       let credentialsForDB = { 
          email : this.state.admin.email,
          stravaAccessToken : data.access_token,
          stravaAccessTokenExpiration : data.expires_at,
          stravaRefreshToken : data.refresh_token
        }
        const user = this.state.admin
        // If we are already saving the dataSrcId, then just return the credentialsObj without fetching the strava account data
        if(user.dataSrcId){
          //If dataSrcId exists, just return access token so app can fetch activity data
          return credentialsForDB;
        }

        // NEW - IF USER DATA DOES NOT INCLUDE A DATA SRC ID...
        // FETCH USER INFO TO GET STRAVA ID, WHICH WILL ALSO BE SAVED TO DB
        // Since this is just to get active users strava id, and it's not necessary for functionality right now,
        // I'm letting this run without blocking the rest of the game
        
        // Get Strava account info
        return new Promise((resolve, reject) =>{
          return getStravaUserId(credentialsForDB.stravaAccessToken, resolve, reject);
        }).then((data) =>{
          return Object.assign({}, credentialsForDB, { dataSrcId : data.id });
        }).catch((error) =>{
          // Couldn't retrieve strava account details
          debugErrors(error, this.state.admin);
          this.updateState({appIsLoading : false, alerts : [{type : 'error', message : `${error.status}: ${error.message}`}]});
          return error;
        });









    }).then((credentialsForDB) =>{
      // UPDATE DB with new strava credentials
      return insertStravaCredentials(credentialsForDB);
    }).then((data) =>{
      const { user, error } = data;
      if(data.error){
        // Couldn't retrieve strava credentials
        debugErrors(data.error, this.state.admin);
        //console.log('Couldnt retrieve strava credentials', error);
        return this.updateState({appIsLoading : false, alerts : [{type : 'error', message : `${error.status}: ${error.message}`}]});
      }
      const updatedCredentials = { 
        stravaAccessToken : user.stravaAccessToken,
        stravaAccessTokenExpiration : user.stravaAccessTokenExpiration,
        stravaRefreshToken : user.stravaRefreshToken  
      }
      // UPDATE STATE with new strava credentials, and check to see if we need to call
      // checkNewActivities() again with a new access token
      const admin = Object.assign({}, this.state.admin, updatedCredentials);
      this.setState({admin});
      return updatedCredentials.stravaAccessToken;
    }).catch((error) =>{
      if(error.status === 400){
        error.message = error.meta;
      }
      debugErrors(error, this.state.admin);
      this.updateState({alerts : [{type : 'error', message :`${error.status}: ${error.message}`}]});
    });
  }

  getNewActivities(dateOfLatestSaved = null){
    //console.log('getting new acts');

    this.checkStravaToken()
    .then((accessToken) => {
      // GET NEW ACTIVITIES FROM STRAVA
      return new Promise((resolve, reject) =>{
        return getStravaActivityData(accessToken, resolve, reject);
      });
    }).then((data) =>{
      return this.handleStravaActivities(data, dateOfLatestSaved);
    }).catch((error) =>{
      debugErrors(error, this.state.admin);
      this.updateState({alerts : [{type : 'error', message :`${error.status}: ${error.message}`}]});
    });
  }



  // Combines the Data from the DB and Strava, and updates state and DB with combined data
  handleStravaActivities(stravaActivities, dateOfLatestSaved){
      // Setting LS to prevent repeated calls to strava server
      setLsWithExpiry('herofit-stravaActivities', stravaActivities, 1800000);

      let newStravaActivities;

      // IF Existing User, only use activities after latest saved, or if none are saved, use it
      if(this.state.avatar.hasBeenUpgraded){
         newStravaActivities = stravaActivities.filter((activity) => {
          let activityDate = moment.utc(activity.start_date, 'YYYY-MM-DD[T]HH:mm[Z]');
          //console.log('is after latest saved', activityDate.isAfter(dateOfLatestSaved), 'ID - ', activity.id);
          return activityDate.isAfter(dateOfLatestSaved) || dateOfLatestSaved === null;
        });
      // If not a new user, but there is no saved activities, get everything after account creation AND 5 before.
      // This is for users who dont buff avatar right away.
      }else{
        let accountDate = moment.utc(this.state.admin.createdAt, 'YYYY-MM-DD[T]HH:mm[Z]');
        const afterArray = stravaActivities.filter((activity) => {
          let activityDate = moment.utc(activity.start_date, 'YYYY-MM-DD[T]HH:mm[Z]');
          return activityDate.isAfter(accountDate);
        });
        const beforeArray = stravaActivities.filter((activity) => {
          let activityDate = moment.utc(activity.start_date, 'YYYY-MM-DD[T]HH:mm[Z]');
          return activityDate.isBefore(accountDate);
        });
        newStravaActivities = [...afterArray, ...beforeArray.slice(0,5)];
      }
      
      // If there are any new activities, present user option to upgrade
      if(newStravaActivities.length){
        // As of 09/28/21, we're not saving strava activities differently
        const newFormatActivities = newStravaActivities.map((act) =>{
          return {
            id: act.id,
            activityDate: act.start_date,
            type: act.type,
            averageSpeed: act.average_speed,
            maxSpeed: act.max_speed,
            distance: act.distance,
            duration: act.moving_time,
            elevationGain: act.total_elevation_gain,
            source: "strava"
          }
        });

        this.setState({upgradeAvailable : true, newStravaActivities : newFormatActivities});
      }
  }


  buffAvatar(newStravaActivities = this.state.newStravaActivities){
    // Hide buff Avatar window
    this.setState({ upgradeAvailable : false });
    // INSERT ACTIVITIES, UPDATE USER TOTALS, BUF AVATAR
    return upgradeSequence({ email : this.state.admin.email, activities : newStravaActivities, accountDate : this.state.admin.createdAt, hasBeenUpgraded : this.state.avatar.hasBeenUpgraded })
    .then((data) =>{
      if(data.error){
        const error = data.error;
        error.message = "Couldn't upgrade hero, please try again later."
        this.updateState({appIsLoading : false, alerts : [{ type : 'error', message : error.message }] });
        return debugErrors(error, this.state.admin);
      }
      const { activities, avatar, improvements, reachedLevel, xpGain, qpEarned, items, rewards } = data;

      // combine returned avatar with existing equipped items... backend not fetching equipment here
      const avEquipped = Object.assign({}, this.state.avatar, avatar, { equipped : this.state.avatar.equipped });
      this.updateState({ activities, avatar : avEquipped,activityData : [...this.state.activityData, ...data.activities] });
      // For displaying Gains messages only
      let messageArray = [];
        
      // Message for level up and QP earned if new level reached
      if(reachedLevel){
        if(avatar.albedo){
          messageArray.push(`LEVEL UP! Earned ${qpEarned} QP for reaching Albedo Level ${reachedLevel}!`);
        }else{
          messageArray.push(`LEVEL UP! Earned ${qpEarned} QP for reaching ${reachedLevel}!`);
        }
      }

      // Message for XP gain
      messageArray.push(`${ xpGain } XP earned!`);
      // Messages for stat improvements
      for(let attribute in improvements){
        if(improvements[attribute] >= 1){
          let improvement = improvements[attribute];
          messageArray.push(`Gained ${improvement} ${attribute.toUpperCase()}!`);
        }
      }

      // message for found items
      messageArray.push(...items.map((item) =>{ return `Found item: ${item}!`; }));
      messageArray.push(...rewards.map((reward) =>{ return `Earned a new ${reward.type}: ${reward.name}, ${reward.description}!`; }));


      setTimeout(() =>{
        messageArray.forEach((message, i) =>{
          setTimeout(() =>{
            this.updateState({alerts : [{type : 'success', message }]})
          },1500 * i);
        });
      }, 2000);

      return { data : { leveledUp : reachedLevel ? true : false }};
    }).catch((error) =>{
      console.log('Shouldnt ever be here', error);
    });
  }

  // Needed to display the correct items on heroes. Used within App.js & AwaitingBattle.js
  equipItemsOnAvatar(avatar){
    // Takes item instance IDs and assigns full items to the hero under 'equipped' property
    equipItemsOnAvatar(avatar, this.state.defaultItems, this.updateState);
  }

  finishSettingInitialStats(avatar){
    // In case any leftover info from a previous login session, not going to be common.
    //localStorage.clear();
    this.updateState({ appIsLoading : true });
    
    return fetchAstrologySeason({ date : null })
    .then((data) =>{
      if(data.error){
        return debugErrors(data.error, this.state.admin);
      }
      const { sign, element } = data;
      avatar[element.toLowerCase()] += 5;

      history.push('/home');
      const lsAvatar = getLsWithExpiry('herofit-avatar');
      if(!lsAvatar && this.state.admin.email){
        this.insertAvatarIntoDb(avatar);
      }
      setLsWithExpiry('herofit-avatar', avatar, 86400000);
      

      const initialAlerts = [{ type : 'info', message : `+5 ${element} bonus for ${sign} season!` }];
    
      // Update state with initial alerts
      this.updateState({ alerts : initialAlerts, avatar, appIsLoading : false });
      
    });
 
  }

  insertAvatarIntoDb(avatar){
    //console.log('inserting - ', avatar);
    // Save Avatar to DB

    insertAvatar({ avatar, email : this.state.admin.email, userId : this.state.admin.id })
    .then((data) =>{
      if(data.error){
        const error = data.error;
        debugErrors(error, this.state.admin);
        return this.updateState({appIsLoading : false, alerts : [{type : 'error', message : `${error.status}: ${error.message}`}]});
      }
      this.updateState({ avatar : data.avatar });
      // Need to instantiate user_totals for new user
      return instantiateUserTotals({email : this.state.admin.email, avatarId : data.avatarId })
    }).then((data) =>{
      if(data.error){
        const error = data.error;
        this.updateState({ appIsLoading : false, alerts : [{ type : 'error', message : error.message }] });
        
        //debugErrors(error, this.state.admin);
        return history.push('/select-hero');
      }

      // No longer need localStorage to hold avatar
      localStorage.removeItem('herofit-avatar');

      //return this.updateState({ appIsLoading : false, alerts : rewardAlerts });
      return this.updateState({ appIsLoading : false });
    }).catch((error) =>{
      debugErrors(error, this.state.admin);
      //console.log('TEST', error);  
    });
  }

  // USER CLICKS LOGOUT OR NAVIGATES TO /logout
  handleLogout(path = '/welcome', deleted){
    this.setState({ loggedIn : false, menuOpen : false, alerts : [] });
    localStorage.removeItem('herofit-avatar');
    localStorage.removeItem('herofit-jwt');
    localStorage.removeItem('herofit-stravaActivities');
    //Page refresh to reset app if user deletes account
    deleted ? document.location = "/welcome"  : history.push(path);
  }
  
  render(){
    return (
      <div className="App">
         <Router history={history} >
          <ScrollToTop />
          <LoadingOverlay loading={this.state.appIsLoading} loggedIn={this.state.loggedIn} />
          <Sidebar menuOpen={ this.state.menuOpen } updateState={ this.updateState} handleLogout={ this.handleLogout } loggedIn={this.state.loggedIn} hasAvatar={this.state.avatar ? true : false}  />
          <Switch>
            <Route exact path={['/', `/home`]}>
              {
                this.state.avatar ? 
                  <HomePage handlePostRegister={this.handlePostRegister} redirectUri={this.state.admin.redirectUri} admin={this.state.admin} avatar={this.state.avatar} buffAvatar={this.buffAvatar} upgradeAvailable={this.state.upgradeAvailable}  MainMenuButton={ <MainMenuButton menuOpen={this.state.menuOpen} updateState={this.updateState} /> } updateState={this.updateState} getStravaClientCredentials={getStravaClientCredentials} battleReport={this.state.battleReport ? this.state.battleReport : null } defaultItems={this.state.defaultItems} equipItemsOnAvatar={this.equipItemsOnAvatar}  />
                :
                  null
              }
            </Route>
            <Route path={[`/account`]}>
              { this.state.avatar ? 
                <AccountPage admin={this.state.admin} avatar={this.state.avatar} updateState={this.updateState} handleLogout={ this.handleLogout } MainMenuButton={ <MainMenuButton menuOpen={this.state.menuOpen} updateState={this.updateState} />  }  getStravaClientCredentials={getStravaClientCredentials}  />
              : null }
            </Route>
            <Route path={`/inventory`}>
              { this.state.avatar ? 
                <InventoryPage avatar={this.state.avatar} updateState={this.updateState} MainMenuButton={<MainMenuButton menuOpen={this.state.menuOpen} updateState={this.updateState} />} admin={this.state.admin} defaultItems={this.state.defaultItems} />
              : null }
            </Route>
            <Route path={[`/training`]}>
              <TrainingPage updateState={this.updateState} accessToken={this.state.admin.stravaAccessToken} activityData={this.state.activityData} MainMenuButton={ <MainMenuButton menuOpen={this.state.menuOpen} updateState={this.updateState} />  } />
            </Route>
            <Route path={[`/select-hero`]}>
              <SelectAvatarPage allowSelection={true} updateState={this.updateState} MainMenuButton={ <MainMenuButton menuOpen={this.state.menuOpen} updateState={this.updateState}  /> } finishSettingInitialStats={this.finishSettingInitialStats} avatar={this.state.initialQPModal ? this.state.avatar : null }  />
            </Route>
            <Route path={`/ranking`}>
              <RankingPage  updateState={this.updateState}  MainMenuButton={ <MainMenuButton menuOpen={this.state.menuOpen} updateState={this.updateState} />  } defaultItems={this.state.defaultItems} />
            </Route>
            <Route exact path={`/users`}>
              <ProfilePage username={this.state.admin} MainMenuButton={ <MainMenuButton menuOpen={this.state.menuOpen} updateState={this.updateState} />  } defaultItems={this.state.defaultItems} />
            </Route>
            <Route path={`/users/:heroName`}>
              <ProfilePage MainMenuButton={ <MainMenuButton menuOpen={this.state.menuOpen} updateState={this.updateState} />  } defaultItems={this.state.defaultItems} />
            </Route>
            <Route path={[`/campaign`]}>
              <CampaignPage avatar={this.state.avatar ? { id : this.state.avatar.id, character : this.state.avatar.character, name : this.state.avatar.name } : null} MainMenuButton={ <MainMenuButton menuOpen={this.state.menuOpen} updateState={this.updateState} />  } updateState={this.updateState}  defaultItems={this.state.defaultItems} />
            </Route>
            <Route path={[`/about`]}>
              <AboutPage name={this.state.avatar ? this.state.avatar.name : null} MainMenuButton={<MainMenuButton menuOpen={this.state.menuOpen} updateState={this.updateState} />} />
            </Route>
            <Route path={[`/battles`]}>
              <BattlesPage MainMenuButton={<MainMenuButton menuOpen={this.state.menuOpen} updateState={this.updateState} />} />
            </Route>
            <Route path={[`/signup-rewards`]}>
              <SignupRewardsPage MainMenuButton={<MainMenuButton menuOpen={this.state.menuOpen} updateState={this.updateState} />} />
            </Route>
            <Route path={[`/items`]}>
              <ItemsPage MainMenuButton={<MainMenuButton menuOpen={this.state.menuOpen} updateState={this.updateState} />}  defaultItems={this.state.defaultItems} avatarCharacter={this.state.avatar?.character} />
            </Route>
            {/* <Route path={`/contact`}>
              <ContactPage admin={this.state.admin} loggedIn={this.state.loggedIn} updateState={this.updateState} MainMenuButton={<MainMenuButton menuOpen={this.state.menuOpen} updateState={this.updateState} />}  />
            </Route> */}
            <Route path={[`/welcome`, `/login`, '/logout']}>
              <WelcomePage updateState={this.updateState} redirectUri={this.state.admin.redirectUri}  MainMenuButton={<MainMenuButton menuOpen={this.state.menuOpen} updateState={this.updateState} />} handlePostLogin={this.handlePostLogin} />
            </Route>
            <Route path={`/privacy-policy`}>
              <PrivacyPolicyPage  updateState={this.updateState} MainMenuButton={<MainMenuButton menuOpen={this.state.menuOpen} updateState={this.updateState} />}  />
            </Route>
            <Route path={`/terms-of-service`}>
              <TermsOfServicePage MainMenuButton={<MainMenuButton menuOpen={this.state.menuOpen} updateState={this.updateState} />}  />
            </Route>
            <Route path={[`/oauth`]}>
              <OAuthPage updateState={this.updateState}  />
            </Route>
            <Route component={NotFoundPage} />
          </Switch>
          <Footer alerts={this.state.alerts} clearAlert={this.clearAlert} />
        </Router>
        <AdminPanel modalOpen={this.state.adminPanelOpen} setModalOpen={(val) => this.setState({ adminPanelOpen : val })} admin={this.state.admin} updateState={this.updateState} activityData={this.state.activityData} avatar={this.state.avatar} defaultItems={this.state.defaultItems} />
      </div>
    );
  }
}

export default App;


