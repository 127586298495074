import React from "react";
import { 
  GiWingedEmblem, GiBlackBelt, GiCrossMark, GiBeltBuckles,
  GiFlowerStar as streakThree, GiBarbedStar as streakFive, GiMoebiusStar as streakTen, GiStarFormation as streakFifteen, GiJusticeStar as streakTwentyFive, GiStarSwirl as streakFifty,
  GiCavalry,GiArrowWings,GiZigArrow,GiSpineArrow,GiFlamingArrow,
  GiPillDrop, GiHealthPotion, GiStandingPotion, GiBrokenBone, GiBurningSkull, GiSpiralShell, GiTotemMask, GiCakeSlice, GiAngelOutfit, GiPlagueDoctorProfile, GiMirrorMirror, GiSecretBook, GiSniffingDog, GiWisdom, GiHotSpices
} from "react-icons/gi";
import { AiOutlineQuestion } from "react-icons/ai";
import { lowercaseUnderscore, lowercaseDash } from "./helperFunctions";

const components = {
  title: {
    "White Light Warrior": GiWingedEmblem,
    "The Renowned": streakThree,
    "The Acclaimed": streakFive,
    "The Untouchable": streakTen,
    "The Valorous": streakFifteen,
    "The Legendary": streakTwentyFive,
    "The Mythical" : streakFifty,
    "White Belt" : GiBlackBelt,
    "Yellow Belt" : GiBlackBelt,
    "Orange Belt" : GiBlackBelt,
    "Blue Belt" : GiBlackBelt,
    "Purple Belt" : GiBlackBelt,
    "Green Belt" : GiBlackBelt,
    "Red Belt" : GiBlackBelt,
    "Brown Belt" : GiBlackBelt,
    "Black Belt" : GiCrossMark,
    "Black Belt Sensei" : GiBeltBuckles,
    "Elemental Outrider" : GiCavalry,
    "Air Stalker" : GiArrowWings,
    "Water Predator" : GiZigArrow,
    "Earth Trapper" : GiSpineArrow,
    "Fire Hunter" : GiFlamingArrow,
    "Ascended" : GiAngelOutfit,
    "Hellhound Whisperer" : GiSniffingDog,
    "Wise Elder" : GiWisdom
  },
  consumable : {
    "Retrocausal Capsule" : GiPillDrop,
    "Health Potion" : GiHealthPotion,
    "Antidote Potion" : GiStandingPotion,
    "Storm Crow Bone Chimes" : GiBrokenBone,
    "Smoldering Skull Torch" : GiBurningSkull,
    "Wave-Swept Battle Conch" : GiSpiralShell,
    "Petrified Power Totem" : GiTotemMask,
    "Birthday Cake" : GiCakeSlice,
    "Plague Token" : GiPlagueDoctorProfile,
    "Obsidian Mirror" : GiMirrorMirror,
    "Smelling Salts" : GiHotSpices,
  },
  codex : {
    "Timber Terror Origin" : GiSecretBook,
    "Filtron Five Origin" : GiSecretBook,
    "Repete Origin" : GiSecretBook,
    "Wildspeaker Origin" : GiSecretBook,
    "Solar Warrior Origin" : GiSecretBook,
    "Chrono Guy Origin" : GiSecretBook,
    "Compost Creature Origin" : GiSecretBook,
    "Empath Origin" : GiSecretBook,
    "Boulder Bro Origin" : GiSecretBook,
    "Natural Ninja Origin" : GiSecretBook
  }
};

function dynamicComponent({ type, name, style, cssClass }) {
  const DynamicComponent = components[type][name] ? components[type][name] : AiOutlineQuestion;
  return <DynamicComponent className={`${type}-${lowercaseDash(name)} ${cssClass ? cssClass : ''}`} style={style} />;
}

export default dynamicComponent;
