import React from 'react';
// LIBRARIES
import PropTypes from 'prop-types';
// COMPONENTS
import DialogTemplate from '../../Dialog/DialogTemplate';
import { IoMdCheckmarkCircleOutline as Check } from 'react-icons/io';
import { MdLinearScale as PageBreak } from 'react-icons/md';
import { TiArrowRightOutline as ArrowRight } from 'react-icons/ti';
// STYLES
import ConnectStravaButton from '../../../assets/images/misc/connect_strava.png';
import appStore from '../../../assets/images/misc/app-store.webp';
import playStore from '../../../assets/images/misc/play-store.webp';

const ConnectStrava = function({modalOpen, setModalOpen, handleModalAction}){

    function handleModalSave(){
      setModalOpen(false);
      handleModalAction();
    }


  const content = <div>
    <div className="page-break"><PageBreak /></div>
    <div className="action-to-take">
      <ArrowRight /> <strong>Connect / Create a Strava account.</strong>
    </div>

    <section id="onboardSteps">
      <div>
        <strong className="cta-initiation-title brand-highlight">The Hero's Initiation</strong><br />
        <ol>
          <li className="muted"><span className="checked"><Check /></span>Choose your Hero</li>
          <li className="muted"><span className="checked"><Check /></span>Create a HeroFit Account</li>
          <li className="bold"><strong>Connect/Create your Strava Account</strong></li>
        </ol>
      </div>
    </section>
    <div id="appStoreSection">
      <p>Download the Strava app below (optional):</p>
      <div id="storeImgContainer">
        <a href="https://apps.apple.com/us/app/strava-run-ride-swim/id426826309" target="_blank" >
          <img src={appStore} alt="Google Play Store" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.strava&hl=en_US" target="_blank" >
          <img id="playStoreImg" src={playStore} alt="App Store" />
        </a>
      </div>
    </div>
  </div>

  return  <div>

    <DialogTemplate 
      type={'speech'}
      identifier={'strava-connect'}
      character={'owl'} 
      title={null}
      text="It's almost time to begin training, but fresh recruits always lack discipline! We need some way to hold you accountable." 
      content={content} 
      cancelButton={true} 
      confirmButton={<img src={ConnectStravaButton} alt="Connect Strava" />}
      isOpen={modalOpen} 
      handleSave={handleModalSave} 
      handleClose={() => setModalOpen(false)} 
      disableButton={false} 
      disableBackdropClick={false} 
    /> 

  </div>

}

export default ConnectStrava;

ConnectStrava.propTypes = {
  modalOpen : PropTypes.bool.isRequired,
  setModalOpen : PropTypes.func.isRequired,
  handleModalAction : PropTypes.func.isRequired,
};