import React, { memo } from 'react';
// LIBRARIES
import PropTypes from 'prop-types';
// Styles
import './Header.scss';

const Header = memo(function({ title = process.env.REACT_APP_NAME, subtitle, MainMenuButton }){
  function renderSubtitle(){
    if(subtitle){
      return <strong>{ subtitle }</strong>
    }
    return null;
  }

  function renderMenu(){
    if(MainMenuButton){
      return MainMenuButton 
    }
    return null;
  }

  

 return(
  <header data-testid="header">
    <h1>{ title }</h1>
    { renderSubtitle() }
    { renderMenu() }
  </header>
 )
});

export default Header;

Header.propTypes = {
  title : PropTypes.string,
  subtitle : PropTypes.string,
  MainMenuButton : PropTypes.object,
};
