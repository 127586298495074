import React from 'react';
// LIBRARIES
import PropTypes from 'prop-types';
// COMPONENTS
import Header from '../Header/Header';
import ActivitiesTable from '../ActivitiesTable/ActivitiesTable';
// COMMON
import { debugErrors } from "../../common/errorHandler";
// SERVICES
import { getStravaClientCredentials } from '../../services/authService';

// STYLES
import './TrainingPage.scss';

const TrainingPage = function({ activityData, MainMenuButton, accessToken, updateState }){

  function connectStrava(){
    getStravaClientCredentials()
    .then((data) =>{
      if(data.error){
        // Couldn't retrieve strava credentials
        const error = data.error;
        debugErrors(data.error, this.state.admin);
        this.updateState({appIsLoading : false, alerts : [{type : 'error', message : `${error.status}: ${error.message}`}]});
      }
      updateState({ modal : {open : true, config : 'stravaConnect', redirectUri : data.redirectUri, clientId : data.clientId  } });
    });
  }

  function trainingContent(){
    if(accessToken && activityData.length){
      return (
        <div className="table-container">
          <ActivitiesTable activityData={activityData} tableType={'all'}  theme={'light'}  />
          <div id="viewOnStravaLink">
            <a href={`https://www.strava.com/api/v3/athlete/activities?access_token=${accessToken}`} >View on Strava</a>
          </div>
        </div>
      )
    }else if(accessToken){
      return (
        <div className="need-content">
          <div>
            <span>Record your activities with <a href="https://www.strava.com/" target="_blank" className="strava-highlight">Strava</a> to see your progress.</span>
          </div>
        </div>
      )
    }
    return (
      <div className="need-content">
        <div>
          <span><strong className="strava-highlight" onClick={connectStrava}>Connect Strava</strong> to view your activities</span>
        </div>
      </div>
    )
    
  }
  return(
    <section id="trainingPage" className="page">
      <Header title={`Training`} subtitle={`Physical Training / Heroic Gains`} MainMenuButton={MainMenuButton} />
      { trainingContent() }
    </section>
  );
};

export default TrainingPage;

TrainingPage.propTypes = {
  accessToken : PropTypes.string,
  activityData : PropTypes.arrayOf(PropTypes.object).isRequired,
  MainMenuButton : PropTypes.object.isRequired,
};