import React from 'react';
import { Link } from 'react-router-dom';
// COMPONENTS
import Header from '../Header/Header';
import InfoAboutActivities from './InfoAboutActivities/InfoAboutActivities';
import HEROES from '../../common/includedHeroImages';
import * as Icons from '../../common/icons';
// STYLES
import './AboutPage.scss';
import Ranking from '../../assets/images/misc/ranking-screen-shot.webp';

const AboutPage = function({ MainMenuButton, name}){
  return(
    <section id="aboutPage" className="page">
      <Header title={`About`} subtitle={process.env.REACT_APP_NAME} MainMenuButton={MainMenuButton} />
      <div className="about-segment">
        <div className="segment-content">
          <div>
            <h2>The Dark Forces...</h2>
            <p>have broken the Twilight Seal, and escaped from their Netherworld prison. Running rampant across Earth Realm, they now seek to possess, torment , and inflict pain on the unsuspecting. Sometimes they operate covertly. Othertimes obviously and indescriminantly. Traditional authority has been infiltrated. It’s up to Heros like you to grow stronger and face ever-more-dangerous foes and seal them back into the Netherealm once and for all!</p>
            <div id="villainWrapper">
              <div className={`villain-background Shade-background`}></div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-segment">
        <div className="segment-content">
          <div id="segmentHeaderWrap">
            <h2>HeroFit is an app to help you grow stronger</h2>
            <p>by manually adding your exercise activites after a workout or automatically from <a href="https://strava.com" target="_blank">Strava</a>.</p>
          </div>
          <div>
            <div id="logoAbout">

            </div>
          </div>
        </div>
      </div>
      <div id="workoutBreakdown" className="about-segment">
        <div className="segment-content">
          <h2>The Workouts You Do Matter</h2>
          <p>Are you primarily a runner? You'll become a Fire Specialist strong against Air and Water. Do you like a variety of activities? You'll be well rounded with the elements, with offense and defense against all. Make your Hero unique with <strong className="highlight">Quantum Points</strong> earned from leveling up to fine-tune even more!</p>
          <InfoAboutActivities name={name} />
          <div id="activityIncrementMessage">
            <em>Activities are recorded by the minute. For most activities, 1 XP = 1 minute of exercise. Partial elements and power are saved as well; no need to workout in exact half-hour increments.</em><br />
            <em>* As shown above, Running, Crossfit, Stair Stepper, Swimming, and Elliptical exercises earn xp and elemental power 50% faster</em>
          </div>
        </div>
      </div>
      <div className="about-segment">
        <div className="segment-content">
          <div>
            <h2>Battle The Dark Forces</h2>
            <p>Engage in nightly matchups against unique Foes! Your Hero will be paired up against a generated foe comparable in power and each possessing special abilities. The new <Link to="/battles">turn-based battle system</Link> allowes for different strategies in building your Hero. Defensive fighter with lots of health and armor? Draw your Foe into deep water in the later rounds for a win. Glass cannon? Take them out before they can deal critical damage.</p>
          </div>
          <div id="vsContent">

            <div className="vs-item">
              <img src={HEROES.base.natural_ninja} alt="Natural Ninja" />
              <strong className="hero-name">Ninjoe</strong>
            </div>

            <div className="vs-item">
              <div id="vs" className="primary-row">
                <strong>- VS -</strong>
              </div>
            </div>

            <div className="vs-item">
              <img src={HEROES.shadow.shadow_natural_ninja} alt="Shadow Natural Ninja" />
              <strong className="hero-name">Shadow Ninjoe</strong>
            </div>
            


          </div>
            
        </div>
      </div>
      <div className="about-segment">
        <div className="segment-content">
          <div>
            <h2>EARTH, WATER, AIR, FIRE...</h2>
            <p>NEW Elemental Traits! The more you've developed your Heroes' fire proficiency, water prowess, air attunment & earth expertise, the bigger impact the elemental traits will have in battles.</p>
            <br />
          </div>
          <div id="traitBreakdown">
            <div className="element-container">
              <h3 className="fire-highlight">FIRE:&nbsp;&nbsp;Critical Strike</h3>
              <em>Running, CrossFit, & Stair Stepper activities, or via quantum points</em><br />
              <p className="muted">Critial Strike is an attack that has a percent chance to deal bonus damage. In HeroFit, if the critical strike lands, the bonus damage equals 2X the element damage totals.</p>
              <div className="proc fire-highlight">
                <Icons.CriticalStrike />
              </div>
              <br />
            </div>
            <div className="element-container">
              <h3 className="earth-highlight">EARTH:&nbsp;&nbsp;Thorns Damage</h3>
              <em>Cycling, Hiking, & Weight Lifting activities, or via quantum points</em><br />
              <p className="muted">Thorns Damage reflects a portion of incoming damage back to the attacker. The more points in Earth, the more damage the attacker will receive from their own attack. In HeroFit, The thorns damage is applied each round and includes physical + elemental damage.</p>
              <div className="proc earth-highlight">
                <Icons.ThornsDamage />
              </div>
              <br />
            </div>
            <div className="element-container">
              <h3 className="air-highlight">AIR:&nbsp;&nbsp;Evasion</h3>
              <em>Walking, Yoga, & Elliptical activities, or via quantum points</em><br />
              <p className="muted">Evasion is a defensive trait that has a percent chance to dodge the enemy's incoming attack. In HeroFit, Evasion will only dodge the elemental damage, physical damage still applies.</p>
              <div className="proc air-highlight">
                <Icons.Evasion />
              </div>
              <br />
            </div>
            <div className="element-container">
              <h3 className="water-highlight">WATER:&nbsp;&nbsp;Vampiric Touch</h3>
              <em>Swimming, Rowing, Paddleboarding, Sking, Snowboarding, Snowshoeing & Kayaking activities, or via quantum points</em><br />
              <p className="muted">Vampiric Touch is calculated on attack, and heals for a percentage of the total elemental attacking damage each round. Healing is calculated after any thorns damage is returned, and may prevent a KO.</p>
              <div className="proc water-highlight">
                <Icons.VampiricTouch />
              </div>
              <br />
            </div>
            <div className="element-container">
              <h3 className="aether-highlight">Aether:&nbsp;&nbsp;Temporal Distortion</h3>
              <em>Earned by achieving <strong className="aether-highlight">Albedo Levels</strong>, reached after level 100</em><br />
              <p className="muted">Temporal Distortion has a chance to repeat an aggressor's attacking round.</p>
              <p className="muted">Aether recovers a percentage of health from physical activities.</p>
              <div className="proc aether-highlight">
                <Icons.TemporalDistortion />
              </div>
              <br />
            </div>
          </div>  
          <div id="percentTable">
            <table className="table">
              <thead>
                <tr>
                  <th colSpan="7"><strong>Stat Points & Percent Effectiveness</strong></th>
                </tr>
                <tr>
                  <th><strong>Element</strong></th>
                  <th><strong>3%</strong></th>
                  <th><strong>5%</strong></th>
                  <th><strong>10%</strong></th>
                  <th><strong>15%</strong></th>
                  <th><strong>30%</strong></th>
                  <th><strong>45% (max)</strong></th>  
                </tr>
              </thead>
              <tbody>
                <tr className="earth-highlight">
                  <td>
                    <strong>Earth</strong><br />
                    <span>pts. required for x% of damage returned to attacker</span>
                  </td>
                  <td>34</td>
                  <td>58</td>
                  <td>123</td>
                  <td>194</td>
                  <td>472</td>
                  <td>900</td>
                </tr>
                <tr className="water-highlight">
                  <td>
                    <strong>Water</strong><br />
                    <span>pts. required for x% of damage healed</span>
                  </td>
                  <td>32</td>
                  <td>53</td>
                  <td>112</td>
                  <td>178</td>
                  <td>432</td>
                  <td>825</td>
                </tr>
                <tr className="air-highlight">
                  <td>
                    <strong>Air</strong><br />
                    <span>pts. required for x% chance to Evade</span>
                  </td>
                  <td>34</td>
                  <td>58</td>
                  <td>123</td>
                  <td>194</td>
                  <td>472</td>
                  <td>900</td>
                </tr>
                <tr>
                  <th><strong></strong></th>
                  <th><strong>3%</strong></th>
                  <th><strong>5%</strong></th>
                  <th><strong>10%</strong></th>
                  <th><strong>30%</strong></th>
                  <th><strong>45%</strong></th>
                  <th><strong>60% (max)</strong></th>  
                </tr>
                <tr className="fire-highlight">
                  <td>
                    <strong>Fire</strong><br />
                    <span>pts. required for x% chance to Crit</span>
                  </td>
                  <td>17</td>
                  <td>29</td>
                  <td>61</td>
                  <td>236</td>
                  <td>450</td>
                  <td>825</td>
                </tr>
                <tr className="aether-highlight">
                  <td>
                    <strong>Aether</strong><br />
                    <span>pts. required for x% chance to repeat attacking round</span>
                  </td>
                  <td>17</td>
                  <td>29</td>
                  <td>61</td>
                  <td>236</td>
                  <td>450</td>
                  <td>825</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="about-segment about-segment-reddit">
        <div className="segment-content">
          <span>Stay up-to-date with all the latest HeroFit news on <a href="https://reddit.com/r/herofit">our subreddit.</a></span>
        </div>
      </div>
    </section>
  )
};

export default AboutPage;