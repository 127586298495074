/*
 Hero Image
*/

import filtron_five from '../assets/images/avatars/filtron_five/filtron_five.webp';
import solar_celeste from '../assets/images/avatars/solar_celeste/solar_celeste.webp';
import boulder_bro from '../assets/images/avatars/boulder_bro/boulder_bro.webp';
import compost_creature from '../assets/images/avatars/compost_creature/compost_creature.webp';
import chrono_guy from '../assets/images/avatars/chrono_guy/chrono_guy.webp';
import natural_ninja from '../assets/images/avatars/natural_ninja/natural_ninja.webp';
import timber_terror from '../assets/images/avatars/timber_terror/timber_terror.webp';
import wilhelm_the_wild from '../assets/images/avatars/wilhelm_the_wild/wilhelm_the_wild.webp';
import empath_aurelia from '../assets/images/avatars/empath_aurelia/empath_aurelia.webp';
import repete from '../assets/images/avatars/repete/repete.webp';

/*
 SHADOW-SELF
*/

import shadow_filtron_five from '../assets/images/avatars/filtron_five/shadow_filtron_five.webp';
import shadow_solar_celeste from '../assets/images/avatars/solar_celeste/shadow_solar_celeste.webp';
import shadow_boulder_bro from '../assets/images/avatars/boulder_bro/shadow_boulder_bro.webp';
import shadow_compost_creature from '../assets/images/avatars/compost_creature/shadow_compost_creature.webp';
import shadow_chrono_guy from '../assets/images/avatars/chrono_guy/shadow_chrono_guy.webp';
import shadow_natural_ninja from '../assets/images/avatars/natural_ninja/shadow_natural_ninja.webp';
import shadow_timber_terror from '../assets/images/avatars/timber_terror/shadow_timber_terror.webp';
import shadow_wilhelm_the_wild from '../assets/images/avatars/wilhelm_the_wild/shadow_wilhelm_the_wild.webp';
import shadow_empath_aurelia from '../assets/images/avatars/empath_aurelia/shadow_empath_aurelia.webp';
import shadow_repete from '../assets/images/avatars/repete/shadow_repete.webp';


/*
 ASCENDED-SELF
*/

import ascended_solar_celeste from '../assets/images/avatars/solar_celeste/ascended_solar_celeste.webp';
import ascended_compost_creature from '../assets/images/avatars/compost_creature/ascended_compost_creature.webp';
import ascended_chrono_guy from '../assets/images/avatars/chrono_guy/ascended_chrono_guy.webp';
import ascended_natural_ninja from '../assets/images/avatars/natural_ninja/ascended_natural_ninja.webp';
import ascended_timber_terror from '../assets/images/avatars/timber_terror/ascended_timber_terror.webp';
import ascended_wilhelm_the_wild from '../assets/images/avatars/wilhelm_the_wild/ascended_wilhelm_the_wild.webp';
import ascended_empath_aurelia from '../assets/images/avatars/empath_aurelia/ascended_empath_aurelia.webp';
import ascended_boulder_bro from '../assets/images/avatars/boulder_bro/ascended_boulder_bro.webp';
import ascended_repete from '../assets/images/avatars/repete/ascended_repete.webp';
import ascended_filtron_five from '../assets/images/avatars/filtron_five/ascended_filtron_five.webp';

/*
 FIRE BRAND
*/
import fire_brand_solar_celeste from '../assets/images/avatars/solar_celeste/fire_brand_solar_celeste.webp';
import fire_brand_compost_creature from '../assets/images/avatars/compost_creature/fire_brand_compost_creature.webp';
import fire_brand_chrono_guy from '../assets/images/avatars/chrono_guy/fire_brand_chrono_guy.webp';
import fire_brand_natural_ninja from '../assets/images/avatars/natural_ninja/fire_brand_natural_ninja.webp';
import fire_brand_timber_terror from '../assets/images/avatars/timber_terror/fire_brand_timber_terror.webp';
import fire_brand_wilhelm_the_wild from '../assets/images/avatars/wilhelm_the_wild/fire_brand_wilhelm_the_wild.webp';
import fire_brand_empath_aurelia from '../assets/images/avatars/empath_aurelia/fire_brand_empath_aurelia.webp';
import fire_brand_boulder_bro from '../assets/images/avatars/boulder_bro/fire_brand_boulder_bro.webp';
import fire_brand_filtron_five from '../assets/images/avatars/filtron_five/fire_brand_filtron_five.webp';
import fire_brand_repete from '../assets/images/avatars/repete/fire_brand_repete.webp';

/*
 TIDE CALLER
*/
import tide_caller_solar_celeste from '../assets/images/avatars/solar_celeste/tide_caller_solar_celeste.webp';
import tide_caller_compost_creature from '../assets/images/avatars/compost_creature/tide_caller_compost_creature.webp';
import tide_caller_chrono_guy from '../assets/images/avatars/chrono_guy/tide_caller_chrono_guy.webp';
import tide_caller_natural_ninja from '../assets/images/avatars/natural_ninja/tide_caller_natural_ninja.webp';
import tide_caller_timber_terror from '../assets/images/avatars/timber_terror/tide_caller_timber_terror.webp';
import tide_caller_wilhelm_the_wild from '../assets/images/avatars/wilhelm_the_wild/tide_caller_wilhelm_the_wild.webp';
import tide_caller_empath_aurelia from '../assets/images/avatars/empath_aurelia/tide_caller_empath_aurelia.webp';
import tide_caller_boulder_bro from '../assets/images/avatars/boulder_bro/tide_caller_boulder_bro.webp';
import tide_caller_filtron_five from '../assets/images/avatars/filtron_five/tide_caller_filtron_five.webp';
import tide_caller_repete from '../assets/images/avatars/repete/tide_caller_repete.webp';

/*
 EARTH SHAKER
*/
import earth_shaker_solar_celeste from '../assets/images/avatars/solar_celeste/earth_shaker_solar_celeste.webp';
import earth_shaker_compost_creature from '../assets/images/avatars/compost_creature/earth_shaker_compost_creature.webp';
import earth_shaker_chrono_guy from '../assets/images/avatars/chrono_guy/earth_shaker_chrono_guy.webp';
import earth_shaker_natural_ninja from '../assets/images/avatars/natural_ninja/earth_shaker_natural_ninja.webp';
import earth_shaker_timber_terror from '../assets/images/avatars/timber_terror/earth_shaker_timber_terror.webp';
import earth_shaker_wilhelm_the_wild from '../assets/images/avatars/wilhelm_the_wild/earth_shaker_wilhelm_the_wild.webp';
import earth_shaker_empath_aurelia from '../assets/images/avatars/empath_aurelia/earth_shaker_empath_aurelia.webp';
import earth_shaker_boulder_bro from '../assets/images/avatars/boulder_bro/earth_shaker_boulder_bro.webp';
import earth_shaker_filtron_five from '../assets/images/avatars/filtron_five/earth_shaker_filtron_five.webp';
import earth_shaker_repete from '../assets/images/avatars/repete/earth_shaker_repete.webp';

/*
 GALE FORCE
*/
import gale_force_solar_celeste from '../assets/images/avatars/solar_celeste/gale_force_solar_celeste.webp';
import gale_force_compost_creature from '../assets/images/avatars/compost_creature/gale_force_compost_creature.webp';
import gale_force_chrono_guy from '../assets/images/avatars/chrono_guy/gale_force_chrono_guy.webp';
import gale_force_natural_ninja from '../assets/images/avatars/natural_ninja/gale_force_natural_ninja.webp';
import gale_force_timber_terror from '../assets/images/avatars/timber_terror/gale_force_timber_terror.webp';
import gale_force_wilhelm_the_wild from '../assets/images/avatars/wilhelm_the_wild/gale_force_wilhelm_the_wild.webp';
import gale_force_empath_aurelia from '../assets/images/avatars/empath_aurelia/gale_force_empath_aurelia.webp';
import gale_force_boulder_bro from '../assets/images/avatars/boulder_bro/gale_force_boulder_bro.webp';
import gale_force_filtron_five from '../assets/images/avatars/filtron_five/gale_force_filtron_five.webp';
import gale_force_repete from '../assets/images/avatars/repete/gale_force_repete.webp';

const HEROES = {
  base : { 
    filtron_five, solar_celeste, boulder_bro, compost_creature, chrono_guy, natural_ninja, timber_terror, wilhelm_the_wild, empath_aurelia, repete,
  },

  shadow : { 
    shadow_filtron_five, shadow_solar_celeste, shadow_boulder_bro, shadow_compost_creature, shadow_chrono_guy, shadow_natural_ninja, shadow_timber_terror, shadow_wilhelm_the_wild, shadow_empath_aurelia, shadow_repete,
  },

  ascended : { 
    ascended_chrono_guy, ascended_empath_aurelia, ascended_wilhelm_the_wild, ascended_natural_ninja, ascended_timber_terror, ascended_compost_creature, ascended_solar_celeste, ascended_boulder_bro, ascended_filtron_five, ascended_repete,
  },

  fire_brand : { 
    fire_brand_chrono_guy, fire_brand_empath_aurelia, fire_brand_wilhelm_the_wild, fire_brand_natural_ninja, fire_brand_timber_terror, fire_brand_compost_creature, fire_brand_solar_celeste, fire_brand_boulder_bro, fire_brand_filtron_five, fire_brand_repete,
  },

  tide_caller : { 
    tide_caller_chrono_guy, tide_caller_empath_aurelia, tide_caller_wilhelm_the_wild, tide_caller_natural_ninja, tide_caller_timber_terror, tide_caller_compost_creature, tide_caller_solar_celeste, tide_caller_boulder_bro, tide_caller_filtron_five, tide_caller_repete,
  },

  earth_shaker : { 
    earth_shaker_chrono_guy, earth_shaker_empath_aurelia, earth_shaker_wilhelm_the_wild, earth_shaker_natural_ninja, earth_shaker_timber_terror, earth_shaker_compost_creature, earth_shaker_solar_celeste, earth_shaker_boulder_bro, earth_shaker_filtron_five, earth_shaker_repete,
  },

  gale_force : { 
    gale_force_chrono_guy, gale_force_empath_aurelia, gale_force_wilhelm_the_wild, gale_force_natural_ninja, gale_force_timber_terror, gale_force_compost_creature, gale_force_solar_celeste, gale_force_boulder_bro, gale_force_filtron_five, gale_force_repete,
  },
  
};

export default HEROES;