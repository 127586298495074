import React, { useState, useEffect } from 'react';
// LIBRARIES
import PropTypes from 'prop-types';
// COMPONENTS
import DialogTemplate from '../../Dialog/DialogTemplate';
import { MdLinearScale as PageBreak } from 'react-icons/md';
import { GiUpgrade, GiStarsStack } from 'react-icons/gi';

const NewLevelReached = function({modalOpen, setModalOpen, handleModalAction, avatarName, avatarLevel = 1, albedoLevel}){
  const [ message, setMessage ] = useState(`Masterful form, young ${avatarName}! I see you've been working out. Keep it up and the Dark Forces will be no match for you.`);


  function handleModalSave(){
    handleModalAction();
    setModalOpen(false);
  }

  const content = <div>
    <div className="page-break"><PageBreak /></div>
    <div className="action-to-take">
      <GiStarsStack  style={ { fontSize:'1.5em', color: '#F1C85B' }} />&nbsp; 
      <strong id="secondaryMessage">LEVEL UP! <span className="brand-highlight highlight-name">&nbsp;{avatarName}&nbsp;</span> has reached 
        {
          albedoLevel ? <span className="albedo-highlight">&nbsp;Albedo Level {albedoLevel}!!!</span>
          : <span className="brand-highlight highlight-level">&nbsp;Level {avatarLevel}!!!</span>
        }
        
      </strong>
    </div>
    <div className="more-content">
      <GiUpgrade style={ { fontSize:'7em', color: '#d4af37' }} />
    </div>
  </div>

  useEffect(() =>{
    const differentMessages = [
      `Wow that was like out of Rocky! Keep going you mighty Italian Stallion you!`,
      `You must have a good mentor; maybe it's time to start charging you! I accept payment in Tootsie Pops.`,
      `Another level up!!?? Such strength, much impressed.`,
      `Congrats, my tadpole! Remember your health and power when spending QP; they scale well against foes. `,
      `I'm a proud owl, a PROWL! Don't spend too much QP on recovery; it isn't effective in battle.`,
      `Masterful form, young ${avatarName}! I see you've been working out. Keep it up and the Dark Forces will be no match for you.`
    ];

    if(avatarLevel === 15){
      const newMessage = `${avatarName}, you are no longer the low level caterpillar you once were! A warning my butterfly, the foes ahead only get stronger!`;
      setMessage(newMessage);
    }else if(avatarLevel === 100){
      const newMessage = `I'm impressed, my butterfly. A word of warning, the foe difficulty is raised even higher from here on out!`;
      setMessage(newMessage);
    }else{
      setMessage(differentMessages[Math.floor(Math.random() * differentMessages.length)]);
    }
  }, [avatarLevel, avatarName]);

  return  <div>

    <DialogTemplate 
      type={'speech'}
      identifier={'new-level-reached'}
      character={'owl'} 
      title={null}
      text={message}
      content={content} 
      cancelButton={true} 
      confirmButton="View QP" 
      isOpen={modalOpen} 
      handleSave={handleModalSave} 
      handleClose={() => setModalOpen(false)} 
      disableButton={false} 
      disableBackdropClick={false} 
    /> 

  </div>

}

export default NewLevelReached;

NewLevelReached.propTypes = {
  modalOpen : PropTypes.bool.isRequired,
  setModalOpen : PropTypes.func.isRequired,
  handleModalAction : PropTypes.func.isRequired,
  avatarName : PropTypes.string.isRequired,
  avatarLevel : PropTypes.number,
  albedoLevel : PropTypes.number,
};