import React, { useState, useEffect } from 'react';
// LIBRARIES
import PropTypes from 'prop-types';
import moment from 'moment';
// COMMON
import HEROES from '../../common/includedHeroImages';
import ITEMS from '../../common/includedItemImages';
import { lowercaseUnderscore, thousandsFormat, rankingSuffix, determineSkinType, determineSkinName } from '../../common/helperFunctions';
import dynamicComponent from "../../common/dynamicComponent";
import { useDidMount } from "../../common/hookHelpers";
// SERVICES
import { fetchAvatarsByPage, fetchAvatarsByPageAndRankingType, fetchAvatarsByPageAndRankingTypeBattle } from '../../services/avatarService';
//COMPONENTS
import Header from '../Header/Header';
import { BsSearch } from 'react-icons/bs';
import { Fire, Earth, Water, Air, Power, Health, Recovery, Armor, Aether } from '../../common/icons.js';
import Hexagon from '../Hexagon/Hexagon';
import CircularProgress from '@material-ui/core/CircularProgress';
import Pagination from '@material-ui/lab/Pagination';
import { DebounceInput } from 'react-debounce-input';
import { Select, InputLabel, MenuItem, FormHelperText, FormControl } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';

// STYLES
import './RankingPage.scss';
import { debugErrors } from '../../common/errorHandler';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));



const RankingPage = function({ MainMenuButton, updateState, defaultItems }){
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [search, setSearch] = useState("");
  const [avatars, setAvatars] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  // For Ranking Input
  const classes = useStyles();
  const [rankingType, setRankingType] = useState('');

  function renderPet(activePet){
    return <img className="item-pet" src={`${ITEMS.pets[lowercaseUnderscore(activePet.name)]}`} alt="Active Pet" />;
  }
  function renderBattlesOrDuration(avatar){
    const disableInputIfSelected = ['', 'battle', 'overall'];

    if(disableInputIfSelected.includes(rankingType)){
      return <span>Battles:  <strong>{avatar.battleWins} - {avatar.battleLosses} - {avatar.battleDraws + avatar.battleDkos}</strong></span>
    }
    return <span>{rankingType.toUpperCase()} Time:  <strong>{moment.duration(avatar.activityDuration, 'seconds').humanize()}</strong></span>
  }

  function renderAvatars(){

    if(avatars && avatars.length && defaultItems){

      return avatars.map((avatar, i )=>{
        //console.log('avatar,', avatar);
        const characterBasics = avatars.find((av) =>{
          return av.character === avatar.character;
        });
        
        avatar  = Object.assign({}, avatar, {lcusName : lowercaseUnderscore(characterBasics.character)});

        let activeSkin = { name : 'base' }, activeTitle = null, activePet = null;
        const e = avatar.equipped;
        // Now setting items on the client side, based on itemIDs passed from the main query
        if(e.length){
          e.forEach((equippedItemId) =>{
            const matchingItem = defaultItems.find(item => item.id === equippedItemId);
            
            if(matchingItem?.type === 'skin'){
              activeSkin = matchingItem;
            }else if(matchingItem?.type === 'pet'){
              activePet = matchingItem;
            }else if(matchingItem?.type === 'title'){
              activeTitle = matchingItem;
            }
          });

        }

        return (
          <div className="avatar-container" key={i+1}>
            <div className="col rank-col">
              <div className="rank-col-content">
                <strong className="rank">{avatar.rank}</strong><span className="suffix">{rankingSuffix(i+1)}</span>
              </div>
            </div>
          
            <a className="col main-col" href={`/users/${encodeURI(avatar.name)}`}>
                <div className="subcol name-subcol">
                    <div className="avatar-description">
                      {
                        activeTitle ? <div className={`avatar-title ${activeTitle.class || ''}`}>{ dynamicComponent({ type: activeTitle.type, name: activeTitle.name, style : null, cssClass: activeTitle.class }) } {activeTitle.name}</div> : null
                      }
                      <strong className="avatar-name">{avatar.name}</strong>
                      <div className="level-row">
                        <span className="avatar-level">Level <strong>{avatar.level}</strong>
                        {
                          avatar.albedo ? <span>, Albedo <strong> {avatar.albedo}</strong></span> : null
                        }
                        <span className="total-xp muted">&nbsp;&nbsp;&nbsp;XP:{thousandsFormat(avatar.activityXP + avatar.battleXP)} ({thousandsFormat(avatar.activityXP)} + {thousandsFormat(avatar.battleXP)})</span></span><br />
                      </div>

                      <div className="extra-stats">
                        { renderBattlesOrDuration(avatar) }
                      </div>
                     

                      <span className="player-name">{avatar.user ? avatar.user.username : null}</span>
                    </div>
                </div>
                <div className="subcol stats-subcol">
                  <div className="attributes">
                    <div>
                      <Hexagon diameter={42} color={"#333533"} borderColor="#F1C85B" icon={ <Power /> } iconStyles={{ color: '#E8EDDF', size: '1.2em' }} text={Math.floor(avatar.power) || '0'} textStyles={{color : '#E8EDDF', fontSize: '.9em', fontWeight: 'bold' }}  modifier={{ text:'-3px', icon:'-3px' }} />
                      <Hexagon diameter={42} color={"#333533"} borderColor="#F1C85B" icon={ <Health /> } iconStyles={{ color: '#E8EDDF', size: '1.2em' }} text={Math.floor(avatar.maxHealth)} textStyles={{color : '#E8EDDF', fontSize: '.9em', fontWeight: 'bold' }}  modifier={{ text:'-3px', icon:'-3px' }} />
                      <Hexagon diameter={42} color={"#333533"} borderColor="#F1C85B" icon={ <Recovery /> } iconStyles={{ color: '#E8EDDF', size: '1.2em' }} text={Math.floor(avatar.recovery) || '0'} textStyles={{color : '#E8EDDF', fontSize: '.9em', fontWeight: 'bold' }}  modifier={{ text:'-3px', icon:'-3px' }} />
                      <Hexagon diameter={42} color={"#333533"} borderColor="#F1C85B" icon={ <Armor /> } iconStyles={{ color: '#E8EDDF', size: '1em' }} text={Math.floor(avatar.armor) || '0'} textStyles={{color : '#E8EDDF', fontSize: '.9em', fontWeight: 'bold' }}  modifier={{ text:'-3px', icon:'-3px' }} />
                    </div>
                    <div className={ avatar.aether ? 'aether-row' : '' }>
                      <Hexagon diameter={42} color={"#cfdbd5"} borderColor="#333533" icon={ <Fire /> } iconStyles={{ color: '#333533', size: '1.2em' }} text={Math.floor(avatar.fire) || '0'} textStyles={{color : '#333533', fontSize: '.9em', fontWeight: 'bold' }}  modifier={{ text:'-2px' }} />
                      <Hexagon diameter={42} color={"#cfdbd5"} borderColor="#333533" icon={ <Earth /> } iconStyles={{ color: '#333533', size: '1em' }} text={Math.floor(avatar.earth) || '0'} textStyles={{color : '#333533', fontSize: '.9em', fontWeight: 'bold' }}  modifier={{ text:'-2px' }} />
                      <Hexagon diameter={42} color={"#cfdbd5"} borderColor="#333533" icon={ <Air /> } iconStyles={{ color: '#333533', size: '1em' }} text={Math.floor(avatar.air) || '0'} textStyles={{color : '#333533', fontSize: '.9em', fontWeight: 'bold' }}  modifier={{ text:'-2px' }} />
                      <Hexagon diameter={42} color={"#cfdbd5"} borderColor="#333533" icon={ <Water /> } iconStyles={{ color: '#333533', size: '1.2em' }} text={Math.floor(avatar.water) || '0'} textStyles={{color : '#333533', fontSize: '.9em', fontWeight: 'bold' }}  modifier={{ text:'-2px' }} />
                      {
                      avatar.aether ? 
                        <Hexagon diameter={42} color={"#cfdbd5"} borderColor="#333533" icon={ <Aether /> } iconStyles={{ color: '#333533', size: '1.2em' }} text={Math.floor(avatar.aether) || '0'} textStyles={{color : '#333533', fontSize: '.9em', fontWeight: 'bold' }}  modifier={{ text:'-2px' }} />
                        : null
                      }
                    </div>
                  </div>
                </div>
                <div className="subcol img-subcol"> 
                { activePet ? renderPet(activePet) : null }
                <div className="avatar-img-container ">
                  <img className={activeSkin?.class} src={HEROES[determineSkinType(activeSkin)][`${determineSkinName(activeSkin, avatar.character)}`]} alt={`${avatar.name} Skin`} />
                </div>
              </div>
              </a>
      
          </div>
        )
      });

    }else if(initialLoadComplete){
      return(
        <div className="no-heroes-indicator">
          <div className="loading-message">No Heroes by that Name</div>
        </div>
      )
    }else{
      return(
        <div className="page-loading-indicator">
          <CircularProgress style={{width:'100%', height: '100%'}} />
          <div className="loading-message">Assembling Heroes...</div>
        </div>
      )
    }
  }
  function checkIfNeedsDisabled(){
    const disableInputIfSelected = ['', 'battle', 'overall'];
    if(!disableInputIfSelected.includes(rankingType)){
      return true;
    }
    return false;
  }

  function renderSubheader(){
    if(initialLoadComplete){
      return(
        <div className="subheader">
          <div className="pagination-section">
            {
              avatars.length ? <Pagination hidePrevButton={true} count={pageCount} page={activePage} siblingCount={1} boundaryCount={1} onChange={handlePageChange} variant="outlined" />
              : null
            }
          </div>
          <div id="searchContainer">
            <DebounceInput
              name="search"
              type="text"
              placeholder="Search Hero Name"
              minLength={2}
              debounceTimeout={400}
              onChange={e => setSearch(e.target.value)}
              disabled={ checkIfNeedsDisabled()} />
            <span><BsSearch size={24} /></span>

          </div>
          <div id="formSelection">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-filled-label">Rankings</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={rankingType}
                  onChange={(e) => setRankingType(e.target.value)}
                >
                  <MenuItem value="overall"><em>Overall XP</em></MenuItem>
                  <MenuItem value={'battle'}>Battle XP</MenuItem>
                  <MenuItem value={'run'}>Running</MenuItem>
                  <MenuItem value={'ride'}>Cycling</MenuItem>
                  <MenuItem value={'hike'}>Hiking</MenuItem>
                  <MenuItem value={'walk'}>Walking</MenuItem>
                  <MenuItem value={'swim'}>Swimming</MenuItem>
                  <MenuItem value={'yoga'}>Yoga</MenuItem>
                  <MenuItem value={'elliptical'}>Elliptical</MenuItem>
                  <MenuItem value={'stairStepper'}>Stair Stepper</MenuItem>
                  <MenuItem value={'crossfit'}>CrossFit</MenuItem>
                  <MenuItem value={'weightTraining'}>Weight Training</MenuItem>
                  <MenuItem value={'rowing'}>Rowing</MenuItem>
                  <MenuItem value={'kayaking'}>Kayaking</MenuItem>
                  {/* <MenuItem value={'Skiing'}>Skiing</MenuItem> */}
                  {/* <MenuItem value={'Snowboard'}>Snowboarding</MenuItem> */}
                </Select>
              </FormControl>
            </div>
        </div>
      )
    }else{
      return (
        null
      )
    }
  }

  function handlePageChange(e){
    let selectedPage = parseInt(e.target.innerText);
    
    // user clicked on the next arrow
    if(isNaN(selectedPage)){
      selectedPage = activePage + 1;
    }

    //subtract 1 to match zero-based index of search results
    const searchPage = selectedPage - 1;
    _fetchAvatarsByPage(searchPage);
  }

  function _fetchAvatarsByPage(page = 0){

    // Fetch by battleXP
    if( rankingType && rankingType === 'battle'){
      return fetchAvatarsByPageAndRankingTypeBattle({ page , searchInput : search, rankingType })
      .then((data) =>{
        if(data.error){
          return debugErrors(data.error);
        }
        const {currentPage, pageCount, avatars } = data;
        setActivePage(currentPage);
        setPageCount(pageCount);
        setAvatars(avatars);
        return;
      });
    // Fetch by Activity
    }else if( rankingType && rankingType !== 'overall'){
      return fetchAvatarsByPageAndRankingType({ page , searchInput : search, rankingType })
      .then((data) =>{
        if(data.error){
          return debugErrors(data.error);
        }
        const {currentPage, pageCount, avatars } = data;
        setActivePage(currentPage);
        setPageCount(pageCount);
        setAvatars(avatars);
        return;
      });
    }

    // Fetch by Overall Ranking
    return fetchAvatarsByPage({ page , searchInput : search })
    .then((data) =>{
      if(data.error){
        return debugErrors(data.error);
      }
      const {currentPage, pageCount, avatars } = data;
      setActivePage(currentPage);
      setPageCount(pageCount);
      setAvatars(avatars);
      return;
    });

  }

  // Once the component is mounted,fetch avatars by overall ranking
  const didMount = useDidMount();
  useEffect(() =>{
    if(didMount){
      _fetchAvatarsByPage()
      .then(() =>{
        setInitialLoadComplete(true);
        updateState({appIsLoading : false});
      });
    }
  }, [didMount]);

  useEffect(() =>{
    _fetchAvatarsByPage();
  }, [rankingType, search]);


  return(
    <section id="rankingPage" className="page">
      <Header title={`Champion's Hall`} subtitle={`The ${process.env.REACT_APP_NAME} Ladder`} MainMenuButton={MainMenuButton} />
      { renderSubheader() }
      <div id="allAvatars">
        { renderAvatars() }
      </div>
      {
        initialLoadComplete && avatars.length ?
        <div className="pagination-section">
        <Pagination hidePrevButton={true} count={pageCount} page={activePage} siblingCount={2} boundaryCount={1} onChange={handlePageChange} variant="outlined" />
        </div>
        :
        null
      }
    </section>
  );
};

export default RankingPage;

RankingPage.propTypes = {
  MainMenuButton : PropTypes.object.isRequired,
  updateState : PropTypes.func.isRequired,
  defaultItems : PropTypes.array.isRequired,
};