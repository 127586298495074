import React from "react";
import { NavLink, Link } from "react-router-dom";
// LIBRARIES
import PropTypes from "prop-types";
import { bubble as Menu } from "react-burger-menu";
// Styles
import "./Sidebar.scss";

const Sidebar = function ({ menuOpen, updateState, handleLogout, loggedIn, hasAvatar}) {
  function handleOnClose(event, logout, loading) {
    if (logout) {
      handleLogout();
    } else if (loading) {
      updateState({ menuOpen: false, alerts: [], appIsLoading: true });
    } else {
      updateState({ menuOpen: false, alerts: [] });
    }
  }

  function handleLoginModalOpen() {
    updateState({
      menuOpen: false,
      alerts: [],
    });
  }

  function renderMenu() {
    // Logged in routes
    if (loggedIn) {
      return (
        <Menu isOpen={menuOpen} onClose={handleOnClose} right stack>
          <NavLink to={`/`} onClick={handleOnClose} activeClassName="active" exact>Home</NavLink>
          <NavLink to={`/ranking`} onClick={(e) => { handleOnClose(e, false, true);}} activeClassName="active" exact>Ranking</NavLink>
          <NavLink to={`/account`} onClick={handleOnClose} activeClassName="active" exact>Account</NavLink>
          <NavLink to={`/about`} onClick={handleOnClose} activeClassName="active" exact >About</NavLink>
          <NavLink to={`/items`} onClick={handleOnClose} activeClassName="active" exact>Item Wiki</NavLink>
          <a href={`https://herofitgame.com/#contact`}>Contact</a>
          <NavLink to={`/welcome`} onClick={(e) => {handleOnClose(e, true);}} activeClassName="active"exact>Log Out</NavLink>
        </Menu>
      );
    }
    // User isn't logged in
    return (
      <Menu isOpen={menuOpen} onClose={handleOnClose} right stack>
        <NavLink to={`/welcome`} onClick={handleOnClose} activeClassName="active"exact >Get Started</NavLink>
        <NavLink to={`/about`} onClick={handleOnClose} activeClassName="active" exact>About </NavLink>
        <NavLink to={`/items`} onClick={handleOnClose} activeClassName="active" exact>Item Wiki</NavLink>
        <NavLink to={`/ranking`} onClick={(e) => { handleOnClose(e, false, true); }} activeClassName="active" exact>Ranking</NavLink>
        <NavLink to={`/users`} onClick={handleOnClose} activeClassName="active" exact>Users</NavLink>
        <a href={`https://herofitgame.com/#contact`}>Contact</a>
        <Link to={`/welcome?login=true`} onClick={ () => handleLoginModalOpen() }>Log In</Link>
      </Menu>
    );
  }

  return renderMenu();
};

export default Sidebar;

Sidebar.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  updateState: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
};
