import React, { useState, useEffect } from 'react';
// LIBRARIES
import PropTypes from 'prop-types';
// COMPONENTS
import DialogTemplate from '../../Dialog/DialogTemplate';
// COMMOMN
import HEROES from '../../../common/includedHeroImages';
import VILLAINS from '../../../common/includedVillainImages';
import ITEMS from '../../../common/includedItemImages';
import dynamicComponent from "../../../common/dynamicComponent";
import { debugErrors } from "../../../common/errorHandler";
import { lowercaseUnderscore, determineSkinType, determineSkinName, thousandsFormat } from '../../../common/helperFunctions';
import { fetchAllItemsOfTypeForSale } from '../../../services/inventoryService';
// ASSETS
import './ShopItems.scss';
import PhotonToken from '../../../assets/images/misc/photon_stack.webp';

const ShopItems = function({ modalOpen, setModalOpen, avatar, buyItem, itemType }){
  const [ selectedItem, setSelectedItem] = useState(null);
  const [ forSaleItems, setForSaleItems] = useState(null);

  // No Save, just close modal
  function handleModalSave(){
    buyItem(selectedItem);
    setSelectedItem(null);
  }

  function handleCloseWithoutSave(){
    setSelectedItem(null);
    setModalOpen(false);
  }

  function determineImage(item){

    if(item.type === 'skin'){
      // Return avatar
      if(determineSkinType(item) === 'base'){
        return HEROES[determineSkinType(item)][determineSkinName(item, avatar.character)];
      // Return shadow self
      }else if(determineSkinType(item) === 'shadow'){
        return VILLAINS.spirits['shadow_'+lowercaseUnderscore(avatar.character)];
      }
      return null;
    }else if(item.type === 'title'){

    }else{
      return ITEMS[item.type+'s'][lowercaseUnderscore(item.name)] || ITEMS[item.type][lowercaseUnderscore(item.name)];
    }
    return null;
  }

  function renderEffects(item){
    if(item.effects?.length){
      const mappedItems = item.effects.map((effect,i) => {
          return (
            <li key={i}>
              <h5>{ effect.name }</h5>
              <strong>{ effect.type }</strong><br />
              <p>{ effect.description }</p>
            </li>
          )
      });

      return <div className="effect-properties">
        <ul>
          { mappedItems }
        </ul>
      </div>

    }
    return null;
  }

  function renderItemList(){
    return forSaleItems.map((item, i) => {
      const notForSale = !item.ptCost;
      return <div key={i} onClick={() => notForSale ? null : setSelectedItem(item)} className={`${selectedItem?.name === item.name ? 'selected-item item-row' : 'item-row'} ${notForSale ? 'not-for-sale' : ''}`}>
          <div className={`primary-details`}>
            <div className="item-col item-image-wrapper">
              {
                item.icon ?
                dynamicComponent({type : item.type, name : item.name, style: { fontSize: "50px" }, cssClass: item.class})
                : <img  className={`item-image  ${item.class}` } src={determineImage(item)} alt={`Buy ${item.name}`} />
              }
              <br />
              { notForSale ? 
                <strong>{ item.description }</strong>
              : <strong className="brand-highlight">{ thousandsFormat(item.ptCost) }</strong> }
            </div>
            <div className="item-col item-name">
              <h4>{ item.name }</h4>
              <span>{ item.type }</span>
            </div>
            <div className="item-col item-description">
              { renderEffects(item) }
            </div>
          </div>
      </div>
    });
  }

  const content = <div id="content">
    { forSaleItems ? renderItemList() : null }
  </div>

  const title = <div>
    <span>Shop Items</span>
    
    <span className="pt-count">{ thousandsFormat(avatar.photonTokens) } <img src={PhotonToken} alt="Photon Tokens" /></span>
  </div>


  useEffect(() =>{
    if(itemType !== null){
      fetchAllItemsOfTypeForSale({ type : itemType })
      .then((data) =>{
        if(data.error){
          return debugErrors(data.error);
        }
        const { items } = data;

        setForSaleItems(items.sort((a,b) => (a.ptCost < b.ptCost) ? 1 : -1));
      });
    }

  }, [itemType]);

  return  <div>
    
    <DialogTemplate 
      type={'basic'}
      identifier={'shop-items'}
      title={title}
      text={null}
      content={content} 
      cancelButton={true} 
      confirmButton={'Buy Item'} 
      isOpen={modalOpen} 
      handleSave={handleModalSave} 
      handleClose={() => handleCloseWithoutSave()} 
      disableButton={selectedItem && (selectedItem.ptCost <= avatar.photonTokens) ? false : true} 
      disableBackdropClick={false} 
      
    /> 
    

  </div>

}

export default ShopItems;



ShopItems.propTypes = {
  modalOpen : PropTypes.bool.isRequired,
  setModalOpen : PropTypes.func.isRequired,
  avatar : PropTypes.object.isRequired,
  buyItem : PropTypes.func.isRequired,
  itemType : PropTypes.string.isRequired
};