import alpha_dog from '../assets/images/items/pets/alpha-dog.webp';
import betta_fish from '../assets/images/items/pets/betta-fish.webp';
import plagueling from '../assets/images/items/pets/plagueling.webp';
import spirit_hawk from '../assets/images/items/pets/spirit_hawk.webp';
import raven_of_omens from '../assets/images/items/pets/raven_of_omens.webp';
import black_cat from '../assets/images/items/pets/black_cat.webp';
import arizona_rattlesnake from '../assets/images/items/pets/arizona_rattlesnake.webp';
import octopus_of_cunning from '../assets/images/items/pets/octopus_of_cunning.webp';
import feisty_coon from '../assets/images/items/pets/feisty_coon.webp';
import orchid_mantis from '../assets/images/items/pets/orchid_mantis.webp';
import mystical_unicorn from '../assets/images/items/pets/mystical_unicorn.webp';
import covert_chameleon from '../assets/images/items/pets/covert_chameleon.webp';
import hearty_llama_of_wellbeing from '../assets/images/items/pets/hearty_llama_of_wellbeing.webp';
import dubious_decoy from '../assets/images/items/pets/dubious_decoy.webp';
import fire_breathing_whelp from '../assets/images/items/pets/fire_breathing_whelp.webp';
import gusty_rascal from '../assets/images/foes/elementals/gusty_rascal.webp';
import rock_skipper from '../assets/images/foes/elementals/rock_skipper.webp';
import flame_fiend from '../assets/images/foes/elementals/flame_fiend.webp';
import splash_artist from '../assets/images/foes/elementals/splash_artist.webp';
import snowshoe_hare from '../assets/images/items/pets/snowshoe_hare.webp';
import ashborn_phoenix from '../assets/images/items/pets/ashborn_phoenix.webp';
import galapagos_sea_turtle from '../assets/images/items/pets/galapagos_sea_turtle.webp';
import sure_footed_billy_goat from '../assets/images/items/pets/sure-footed_billy_goat.webp';
import skydiving_osprey from '../assets/images/items/pets/skydiving_osprey.webp';
import fiery_salamander from '../assets/images/items/pets/fiery_salamander.webp';

const ITEMS = {
  pets : { 
    alpha_dog,
    betta_fish,
    plagueling,
    spirit_hawk,
    raven_of_omens,
    black_cat,
    arizona_rattlesnake,
    octopus_of_cunning,
    feisty_coon,
    orchid_mantis,
    mystical_unicorn,
    covert_chameleon,
    hearty_llama_of_wellbeing,
    dubious_decoy,
    fire_breathing_whelp,
    gusty_rascal,
    rock_skipper,
    flame_fiend,
    splash_artist,
    snowshoe_hare,
    ashborn_phoenix,
    galapagos_sea_turtle,
    "sure-footed_billy_goat" : sure_footed_billy_goat,
    skydiving_osprey,
    fiery_salamander
  }
}
export default ITEMS;