import React from "react";
// LIBRARIES
import PropTypes from "prop-types";
// STYLES
import borderArrow from "../../assets/images/misc/arrow-left.svg";

const BorderArrow = function ({ direction, scale }) {
  return <img src={borderArrow} alt="border arrow" />;
};

export default BorderArrow;

BorderArrow.propTypes = {};
