import React, { useState, useEffect, createRef } from "react";

// LIBRARIES
import PropTypes from "prop-types";
import { CountUp } from "countup.js";
// COMMON
import { thousandsFormat, cloneObj, lowercaseUnderscore, determineSkinType, determineSkinName, getUrlParams, getLsWithExpiry, setLsWithExpiry } from "../../common/helperFunctions";
import dynamicComponent from "../../common/dynamicComponent";
import history from "../../common/history";
import { debugErrors } from "../../common/errorHandler";
import { useDidMount, usePrevious } from "../../common/hookHelpers";
import { Fire, Earth, Water, Air, Power, Armor, Recovery, Health, Aether } from "../../common/icons.js";
import { emailFeedbackData } from "../../services/emailService";
import HEROES from '../../common/includedHeroImages';
import ITEMS from "../../common/includedItemImages";
// SERVICES
import { updateAvatarStats } from "../../services/avatarService";
import { goToBattle, fetchUpcomingFoeAndRewards, updateBattleReportSeen } from "../../services/battleService";
import { fetchLatestPatchDetails, updateSeenLatestPatch } from '../../services/authService';
// COMPONENTS
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import CountdownTimer from "./CountdownTimer";
import { FaRegNewspaper } from "react-icons/fa";
// - modals
import { Login, Register, ForgotPassword } from '../Dialog/Modals/Auth/AllAuthModals';
import CreateAccount from "./Modals/CreateAccount";
import ConnectStrava from "./Modals/ConnectStrava";
import UpgradeHero from "./Modals/UpgradeHero";
import NewLevelReached from "./Modals/NewLevelReached";
import GoToBattle from "./Modals/GoToBattle";
import AwaitingBattle from "./Modals/AwaitingBattle";
import TheHerosPath from "./Modals/TheHerosPath";
import SpendQP from "./Modals/SpendQP/SpendQP";
import Feedback from "./Modals/Marketing/Feedback";
import FeaturePreference from "./Modals/Marketing/FeaturePreference";
import BattleReport from "./Modals/BattleReport/BattleReport";
import BattleReportDetail from "./Modals/BattleReport/BattleReportDetail";
import NewPatch from "./Modals/NewPatch";
import ItemDetail from "../Dialog/Modals/ItemDetail";
// STYLES
import "./HomePage.scss";
import logoBall from "../../assets/images/misc/logo-ball.webp";

const HealthBar = withStyles((theme) => ({
  root: {
    width: "70%",
    height: "5vh",
    borderRadius: 20,
    border: "solid 2px #242423",
    boxShadow: "2px 2px 5px #242423",
  },
  colorPrimary: {
    backgroundColor: "transparent",
  },
  bar: {
    borderRadius: 8,
    backgroundColor: "#A30216",
  },
}))(LinearProgress);

const ExperienceBar = withStyles((theme) => ({
  root: {
    width: "50%",
    height: "4vh",
    borderRadius: 16,
    border: "solid 2px #242423",
    boxShadow: "2px 2px 5px #242423",
  },
  colorPrimary: {
    backgroundColor: "transparent",
  },
  bar: {
    borderRadius: 8,
    backgroundColor: "#242423",
  },
}))(LinearProgress);

// BACKGROUND GRADIENT -  Only should be determined once, so it's outside the component
const backgrounds = [ "background-dawn", "background-day", "background-dusk", "background-night", "background-elemental-event" ];
const background = backgrounds[Math.floor(Math.random() * backgrounds.length)];
//const background = "background-elemental-event";
const HomePage = function ({ avatar, buffAvatar, upgradeAvailable, MainMenuButton, admin, updateState, getStravaClientCredentials, battleReport, defaultItems, handlePostRegister, equipItemsOnAvatar }) {
  //const [classes, setClasses] = useState('');
  const [avatarClassName, setAvatarClassName] = useState('skin-base');
  const [statsHidden, setStatsHidden] = useState("hidden");
  const [displayHPProg, setDisplayHPProg] = useState(0);
  const [displayXPProg, setDisplayXPProg] = useState(0);
  const [xpForLevel, setXpForLevel] = useState(0);
  const [xpDiff, setXpDiff] = useState(0);
  const [upcomingFoe, setUpcomingFoe] = useState(null);
  const [upcomingRewards, setUpcomingRewards] = useState(null);
  // Auth Modals State
  const [LoginOpen, setLoginOpen] = useState(false);
  const [RegisterOpen, setRegisterOpen] = useState(false);
  const [ForgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  // Modal State
  const [createAccountModalOpen, setCreateAccountModalOpen] = useState(false);
  const [connectStravaModalOpen, setConnectStravaModalOpen] = useState(false);
  const [upgradeHeroModalOpen, setUpgradeHeroModalOpen] = useState(false);
  const [newLevelReachedModalOpen, setNewLevelReachedModalOpen] = useState(false);
  const [goToBattleModalOpen, setGoToBattleModalOpen] = useState(false);
  const [awaitingBattleModalOpen, setAwaitingBattleModalOpen] = useState(false);
  const [herosPathModalOpen, setHerosPathModalOpen] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [featurePreferenceModalOpen, setFeaturePreferenceModalOpen] = useState(false);
  const [spendQPModalOpen, setSpendQPModalOpen] = useState(false);
  const [enableMarketingModal, setEnableMarketingModal] = useState(admin.latestActivityUpdate && !admin.featurePreferenceSubmitted);
  const [battleReportModalOpen, setBattleReportModalOpen] = useState(false);
  const [battleReportDetailModalOpen,setBattleReportDetailModalOpen] = useState(false);
  const [newPatchModalOpen, setNewPatchModalOpen] = useState(false);
  const [patchDetails, setPatchDetails] = useState(null);
  const [modalIsLoading, setModalIsLoading] = useState(false);
  const [itemModalOpen, setItemModalOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [runBattleNow, setRunBattleNow] = useState(false);

  const xpCounterRef = createRef();
  const totalXpCounterRef = createRef();
  const didMount = useDidMount();

  
  function toggleLoginForgotPassword(destination){
    if(destination === 'Login'){
      setForgotPasswordOpen(false);
      setLoginOpen(true);
    }else if(destination === 'Forgot Password'){
      setLoginOpen(false);
      setForgotPasswordOpen(true);
    }
  }

  function toggleLoginRegister(destination){
    if(destination === 'Login'){
      setRegisterOpen(false);
      setLoginOpen(true);
    }else if(destination === 'Register'){
      setLoginOpen(false);
      setRegisterOpen(true);
    }
  }

  function handleOpenRegister() {
    setCreateAccountModalOpen(false);
    setRegisterOpen(true);
  }

  function handleUpgradeClick() {
    setModalIsLoading(true);

    buffAvatar()
    .then((data) => {
      const { leveledUp } = data.data;
      setUpgradeHeroModalOpen(false);
      if (leveledUp) {
        setTimeout(() => {
          setModalIsLoading(false);
          setNewLevelReachedModalOpen(true);
        }, 1000);
      }
      setModalIsLoading(false);
    }).catch((error) =>{
      // Error already being handled in App.js, just set modal to false.
      setModalIsLoading(false);
    });
  }

  // On click of item, close open modal then load new item into item modal
  function handleItemClick(item){
    setAwaitingBattleModalOpen(false);
    setBattleReportModalOpen(false);
    setActiveItem(item);
    setItemModalOpen(true);
  }

  function renderTopHud() {

    // xp display calculations
    const currentXP = avatar.activityXP + avatar.battleXP;
    const thisLevel = avatar.level;
    const albedoLevel = avatar.albedo;
    const title = avatar.equipped.find( item => item.type === 'title' );

    return (
      <div id="topConsole">
        <img src={logoBall} alt="logo" />
        <div id="progressBars">
          <HealthBar variant="determinate" value={displayHPProg} />
          <span id="healthBarText" className="hud-bar-text">
            {" "}
            <Health /> <strong>{avatar.health}</strong> / {avatar.maxHealth}
          </span>
          <ExperienceBar variant="determinate" value={displayXPProg} />
          <span id="experienceBarText" className="hud-bar-text">
            <span className="xp-label">Level XP&nbsp;&nbsp;</span>
            <strong ref={xpCounterRef}>{xpDiff}</strong> /{" "}
            {thousandsFormat(xpForLevel)}
          </span>
        </div>
        <div id="avatarQuickFacts">
          {
            title ? 
              <div className={`avatar-title ${title.class || ''}`}>
                { dynamicComponent({ type: title.type, name: title.name, style : null, cssClass: title.class }) }
                { title.name }
              </div>
            : null
          }
          <div className="avatar-name">{avatar.name}</div>
          <div className="avatar-status">
            <span>Level:&nbsp;&nbsp;</span>
            <strong className="highlight">{thisLevel}</strong>
            {
              albedoLevel ? <span>, <strong className="albedo-level">{albedoLevel} Albedo</strong></span> : null
            }
            <span className="muted">
              &nbsp;&nbsp;XP: <span ref={totalXpCounterRef}>{currentXP}</span>
            </span>
          </div>
          <div className="avatar-status">
            <span>Status:&nbsp;&nbsp;</span>
            <strong
              className={
                avatar.status === "Recovering"
                  ? "warning"
                  : avatar.status === "Knocked Out"
                  ? "disabled"
                  : avatar.status === "Infected"
                  ? "infected"
                  : "brand-highlight"
              }
            >
              {avatar.status}
            </strong>
          </div>
          {avatar.status === "Knocked Out" ? (
            <div className="countdown avatar-ko-countdown">
              <CountdownTimer countdownType={"ko"} />
            </div>
          ) : avatar.goToBattle ? (
            <div className="countdown avatar-battle-countdown">
              <CountdownTimer countdownType={"battle"} />
            </div>
          ) : null}
          <div className="avatar-photon-tokens">
            <span>Photon Tokens:&nbsp;&nbsp;</span>
            <strong className="brand-highlight">
              {thousandsFormat(avatar.photonTokens)}
            </strong>
          </div>
        </div>
      </div>
    );
  }

  function renderAvatar(){
    const skin = avatar.equipped.find(item => item.type === 'skin');
    return <div id="avatarContainer">
      <div id="avatar" className={ avatar.status === "Knocked Out" ? "knocked-out" : avatar.status === "Infected" ? 'infected' : avatar.status === "Recovering" ? "recovering" : "" } >
        <img id="avatarImage" className={skin?.class} src={HEROES[determineSkinType(skin)][determineSkinName(skin, avatar.character)]} alt={avatar.name} />
      </div>
    </div>
  }

  function renderPet(){
    const pet = avatar.equipped.find((item) => item.type  === 'pet');
    if(pet){
      return <div id="petContainer">
        <img src={`${ITEMS.pets[lowercaseUnderscore(pet.name)]}`} className="item-pet" alt={`Equipped Pet`} />
      </div>
    }
    return null;
  }

  function visualCounter(targetElement, startVal = 0, endVal = 0, duration) {
    const counter = new CountUp(targetElement, endVal, { startVal, duration });
    counter.start();
  }

  function handleShowStats() {
    if (statsHidden === "") {
      setStatsHidden("hidden");
    } else {
      setStatsHidden("");
    }
  }

  function handleGoToBattle() {
    // CREATE BATTLE AND UPDATE STATUS OF AVATAR
    if (avatar.goToBattle === false) {
      setModalIsLoading(true);
      //console.log('AVATARR', avatar);
      goToBattle({ email: admin.email, avatar })
      .then(({ data, error }) => {
        if(error){
          error.message = "Unable to go to Battle, please try again later.";
          updateState({ alerts : [{ type : 'error', message : error.message }] });
          setModalIsLoading(false);
          return debugErrors(error, admin);
        }
        const { goToBattle } = data;

        setUpcomingFoe(data.foe);
        updateState({ avatar: Object.assign({}, avatar, { goToBattle }), });
        setGoToBattleModalOpen(false);
        if(data.rewards){
          setUpcomingRewards(data.rewards);
        }
  
        setTimeout(() => {
          setModalIsLoading(false);
          setAwaitingBattleModalOpen(true);
        }, 500);
      });
    }
  }

  function handleShowBattleReport() {
    updateBattleReportSeen({ id: battleReport.id });
    setBattleReportModalOpen(true);
  }

  function renderBattleReportButton() {
    if (battleReport /*&& !seenLatestReport*/) {
      return (
        <div id="battleReportLink" onClick={handleShowBattleReport}>
          <FaRegNewspaper className="shake" />
        </div>
      );
    }
    return null;
  }

  function renderTheHerosPath() {
    if (!avatar.hasBeenUpgraded) {
      return (
        <div id="theHerosPath">
          <Button
            className={"MuiButton-containedHighlight"}
            variant="contained"
            color="primary"
            onClick={() => setHerosPathModalOpen(true)}
          >
            The Hero's Path
          </Button>
        </div>
      );
    }
    return null;
  }

  function handleConnectStrava() {
    getStravaClientCredentials()
    .then((data) => {
      if(data.error){
        const error = data.error;
        // Couldn't retrieve strava credentials
        debugErrors(data.error, admin);
        return updateState({appIsLoading : false, alerts : [{type : 'error', message : `${error.status}: ${error.message}`}]});
      }
      document.location = `https://www.strava.com/oauth/authorize?client_id=${data.clientId}&redirect_uri=${data.redirectUri}&response_type=code&scope=activity:read_all`;
    });
  }

  function handleOpenQP() {
    setNewLevelReachedModalOpen(false);
    setSpendQPModalOpen(true);
  }

  function handleDecideBattleModals() {
    if (avatar.goToBattle) {
      return fetchUpcomingFoeAndRewards({ avatarID: avatar.id })
      .then((data) => {
        if(data.error){
          const error = data.error;
          // If no battles, usually just means the window was left open while the battles ran, so just open the goToBattle modal 
          if(error.debug[0].msg === 'Error: No battles on record'){
            return setGoToBattleModalOpen(true);
          }else{
            updateState({ alerts : [{ type : 'error', message :`${error.status}: ${error.message}` }] });
            return debugErrors(data.error, admin);
          }
        }
        const { foe, rewards } = data;
        //console.log('FOOO', foe, 'REWWWW', rewards);
        setUpcomingFoe(foe);
        if(rewards){
          setUpcomingRewards(rewards);
        }
        setAwaitingBattleModalOpen(true);
      });
    } else {
      setGoToBattleModalOpen(true);
    }
  }

  // SUBMIT FEATURE PREFERENCE MODAL
  function submitFeaturePreferenceData(dataToSave) {
    // Check to see if survey results have changed at all
    const hasNotChanged = dataToSave.data.every((item, i) => {
      return item.id === i + 1;
    });

    // If the order of the survey hasn't changed, don't email data results
    if (hasNotChanged) {
      return;
    }

    // Otherwise, send results of survey to server to be emailed to info@herofit.io
    const emailData = {
      email: admin.email,
      username: admin.username,
      surveyTitle: dataToSave.title,
      surveyData: dataToSave.data,
      accountInfo: admin,
    };

    console.error("no longer using this endpoint");
  }

  // SUBMIT FEEDBACK MODAL
  function submitFeedbackData(dataToSave) {
    // Otherwise, send results of survey to server to be emailed to info@herofit.io
    const emailData = {
      email: admin.email,
      username: admin.username,
      title: dataToSave.title,
      opinion: dataToSave.data.opinion,
      openResponse: dataToSave.data.openResponse,
      accountInfo: admin,
    };
    emailFeedbackData(emailData)
    .then((data) => {
      
      if(data.error){
        setFeaturePreferenceModalOpen(false);
        const errorMessage = debugErrors(data.error, admin);
        return updateState({
          alerts: [
            { type: "error", message: `${errorMessage}` },
          ],
        });
      }
      setFeaturePreferenceModalOpen(false);
      updateState({
        alerts: [{ type: "success", message: `Feedback Submitted!` }],
      });
    });
  }

  // Increase Stats
  function increaseStats(stat, amount, cb = null) {
    const av = cloneObj(avatar, true);

    // Bolster stat on avatar
    av[stat] += amount;
    // Remove spent qp from avatar
    av.qp -= 1;

    // keeping track of points spent
    if (stat === "recovery") {
      av.qpRecovery += 1;
    } else if (stat === "power") {
      av.qpPower += 1;
    } else if (stat === "armor") {
      av.qpArmor += 1;
    } else if (stat === "fire") {
      av.qpFire += 1;
    } else if (stat === "earth") {
      av.qpEarth += 1;
    } else if (stat === "water") {
      av.qpWater += 1;
    } else if (stat === "air") {
      av.qpAir += 1;
    } else if (stat === "maxHealth") {
      av.qpHealth += 1;
      // Need to increase Heroe's health along with maxHealth other wise they wont regen
      av.health += 1;
    }

    // If not a first time user, update DB
    if (admin.email) {

      updateAvatarStats({ avatar: av, email: admin.email, id: avatar.id })
      .then((data) => {
        if(data.error){
          const error = data.error;
          debugErrors(error, admin);
          return updateState({ alerts : [{type : 'error', message : error.message}]});
        }
        // Only used for epic elemnetal skins at the moment
        let awardAlerts = [];
        if(data.length){
          awardAlerts = data.map(reward => {
            return { type : 'success', message : `EARNED ITEM: ${reward.name} ${reward.type}, ${reward.description}`}
          });
        }

        // Nopt using returned avatar, since it would require extra work fetching Avatar Items again at the server level
        updateState({ avatar : av, alerts : awardAlerts }, cb);
      });

      // Otherwise update react state
    } else {
      updateState({ avatar: av }, cb);
    }
  }

  // A wrapper for Increase Stats
  function handleStatChange(stat, amount, done) {
    increaseStats(stat, amount, () => {
      // If no more qp to spend close modal
      if (done) {
        setTimeout(() => {
          setSpendQPModalOpen(false);
        }, 1750);
      }
    });
  }

  function renderBattleReportModals() {
    if (battleReport) {
      // I think this is only used for Profile page BR views
      // let avatarSkin = avatar.equipped.find((item) => item.type === 'skin');
      // battleReport.avatarSkin = avatarSkin ? avatarSkin : { name : 'base' };
      return (
        <div>
          <BattleReport
            modalOpen={battleReportModalOpen}
            setModalOpen={setBattleReportModalOpen}
            handleModalAction={() => {
              setBattleReportModalOpen(false);
              setBattleReportDetailModalOpen(true);
            }}
            battleReport={battleReport}
            avatarEquip={avatar.equipped}
            handleItemClick={handleItemClick}
            defaultItems={defaultItems}
          />
          <BattleReportDetail
            modalOpen={battleReportDetailModalOpen}
            setModalOpen={setBattleReportDetailModalOpen}
            battleReport={battleReport}
          />
        </div>
      );
    }
    return null;
  }

  useEffect(() => {
    if (enableMarketingModal) {
      const timeout = setTimeout(() => {
        // If no modals are open, launch marketing modal
        if (
          !upgradeHeroModalOpen && !newLevelReachedModalOpen && !goToBattleModalOpen && !awaitingBattleModalOpen &&
          !herosPathModalOpen && !spendQPModalOpen && !battleReportModalOpen && !newPatchModalOpen
        ) {
          
          //FEATURE PREFERENCE MODAL
          //setFeaturePreferenceModalOpen(true);
          
          // FEEDBACK MODAL
          setFeedbackModalOpen(true);


          setEnableMarketingModal(false);
        }
      }, 7000);
      return () => clearTimeout(timeout);
    }
  }, [ enableMarketingModal, upgradeHeroModalOpen, newLevelReachedModalOpen, goToBattleModalOpen, awaitingBattleModalOpen, herosPathModalOpen, spendQPModalOpen, battleReportModalOpen, newPatchModalOpen ]);

  const currentXP = avatar.activityXP + avatar.battleXP;
  const thisLevelStartXP = avatar.thisLevelStartXp;
  const nextLevelStartXP = avatar.nextLevelStartXp;
  const xpGainedThisLevel = currentXP - thisLevelStartXP;
  const thisLevelXPRequired = nextLevelStartXP - thisLevelStartXP;
  const xpProg = (xpGainedThisLevel / thisLevelXPRequired) * 100;

  // PREV RENDER VALUES
  const prevNextLevelXP = usePrevious(nextLevelStartXP);
  const prevXPGainedThisLevel = usePrevious(currentXP - thisLevelStartXP);
  const prevCurrentXP = usePrevious(currentXP);

  // XP, HP, LEVEL
  useEffect(() => {
    setXpForLevel(thisLevelXPRequired || 99);
    if (currentXP > 0) {
      setXpDiff(currentXP - thisLevelStartXP);
    }
    const hpProg =
      ((avatar.maxHealth - (avatar.maxHealth - avatar.health)) /
        avatar.maxHealth) *
      100;
    setTimeout(() => {
      setDisplayHPProg(hpProg);
      setDisplayXPProg(xpProg);
    }, 750);

    if (didMount && prevNextLevelXP > 1 && currentXP >= prevNextLevelXP) {
      setXpForLevel(thisLevelXPRequired);
      // On level up is off here.
      visualCounter(xpCounterRef.current, prevXPGainedThisLevel, xpProg, 3.5);
      visualCounter(totalXpCounterRef.current, prevCurrentXP, currentXP, 3);
      setDisplayXPProg(100);
      setTimeout(() => {
        setDisplayXPProg(xpProg);
      }, 1500);
    }
  }, [avatar, prevNextLevelXP, prevXPGainedThisLevel]);


  // Handles visual counting
  useEffect(() => {
    if (didMount) {
      if (currentXP > 0) {
        visualCounter(xpCounterRef.current, 0, xpGainedThisLevel, 3.5);
      } else {
        setXpDiff(0);
      }
      visualCounter(totalXpCounterRef.current, 0, currentXP, 5);
    }

  }, [didMount, admin.seenLatestPatch]);

  useEffect(() =>{
    // Only fetch the latest patch details if the user hasn't seen the latest patch
    // seenLatestPatch is a saved field in the user table in the DB

    if(admin.seenLatestPatch === false){
      const timeout = setTimeout(() =>{
        return fetchLatestPatchDetails()
        .then((data) =>{
          if(data.error){
            throw debugErrors(data.error, admin);
          }
          setPatchDetails(data);
          setNewPatchModalOpen(true);
          // Set the user's seenLatestPatch to true in the DB so they don't get prompted next time
          return updateSeenLatestPatch({ id : admin.id });
        }).then((data) =>{
          if(data.error){
            throw debugErrors(data.error, admin);
          }
        }).catch((error) =>{
          updateState({ alerts : [{ type : 'error', message : error }] });
        });

      },5000);

      return () => clearTimeout(timeout);
    }
  }, [didMount, admin.seenLatestPatch]);

  useEffect(() => {
    if (avatar) {
      if (upgradeAvailable) {
        setUpgradeHeroModalOpen(true);
      } else if (!admin.email) {
        setCreateAccountModalOpen(true);
      }
    }
  }, [avatar, upgradeAvailable, admin.email]);

  useEffect(() =>{
    
    // Only show Strava connect modal if the user has already registered and there is no Strava Refresh Token
    if(!admin.stravaRefreshToken && admin.email && avatar.status === 'New Recruit') {
      return setConnectStravaModalOpen(true);
    }else if(!admin.stravaRefreshToken && admin.email){
      const seenStrava = getLsWithExpiry('herofit-already-seen-strava');
      if(!seenStrava){
        setLsWithExpiry('herofit-already-seen-strava', true, 180000000);
        return updateState({ alerts : [{ type : 'info', cb : handleConnectStrava, message : `Connect your Strava Account to begin leveling your ${avatar.name} `, confirm : 'Connect' }] });  
      }
    }else{
      // Needed because of initial renders are null,
      return setConnectStravaModalOpen(false);
    }
  }, [admin.stravaRefreshToken, avatar.status]);

  // USED FOR INSTANTBATTLES - URL PARAM  - ?awaitingBattle=true
  const { awaitingBattle } = getUrlParams(history.location);
  useEffect(() =>{
    if(awaitingBattle){
      handleDecideBattleModals(true);
      setRunBattleNow(true);
      // reset url params so instant battles can't happen again the following time a user goes to battle
      // (odd bug that only happens for some)
      history.replace('');
    }

  }, [awaitingBattle]);

  return (
    <div id="home" className={`page ${background}`}>
      <div id="avatarSection">
        {MainMenuButton}

        { renderTopHud() }
        { renderAvatar() }
        { renderPet() }
      </div>
      <div id="consoleSection">
        { renderTheHerosPath() }
        { renderBattleReportButton() }
        <div id="versionNumber">version - { process.env.REACT_APP_VERSION }</div>
        <div className="arrow-up" onClick={ handleShowStats }>
          <div className="inner-arrow">
            <div className="innermost-arrow"></div>
          </div>
        </div>
        <div id="actionButtons">
          <div id="avatarQpButton" className="action-button">
            <Button
              id="qpButton"
              onClick={() => setSpendQPModalOpen(true)}
              className={
                avatar.qp ? "MuiButton-containedHighlight block" : "block"
              }
              color={`primary`}
              variant="contained"
            >
              Quantum
            </Button>
          </div>
          <div id="battleButton" className="action-button">
            <Button
              id="battleButton"
              onClick={handleDecideBattleModals}
              disabled={
                avatar.status === "Knocked Out" ||
                avatar.status === "Infected" ||
                avatar.status === "New Recruit"
              }
              className={`MuiButton-contained block ${
                avatar.goToBattle || !avatar.restedEnough
                  ? "MuiButton-containedPrimary"
                  : "MuiButton-containedHighlight"
              }`}
              color={`primary`}
              variant="contained"
            >
              Battle
            </Button>
          </div>
        </div>
        <div id="secondaryButtons" className={statsHidden}>
          <div id="avatarItemsButton" className="action-button">
            <Button
              id="inventoryButton"
              color={`primary`}
              variant="contained"
              onClick={() => history.push(`/inventory`)}
            >
              {" "}
              Inventory{" "}
            </Button>
          </div>
          <div id="avatarProfileButton" className="action-button">
            <Button
              id="profileButton"
              color={`primary`}
              variant="contained"
              onClick={() =>
                history.push(`/users/${encodeURI(avatar.name)}`)
              }
            >
              {" "}
              Profile{" "}
            </Button>
          </div>
          <div id="avatarCampaignButton" className="action-button">
            <Button
              id="campaignButton"
              color={`primary`}
              variant="contained"
              onClick={() => history.push("/campaign")}
            >
              {" "}
              Campaign{" "}
            </Button>
          </div>
        </div>
        <div id="stats" className={statsHidden}>
          <div id="avatarPrimaryAttributes" className="primary-attributes">
            <div>
              <Power />
              <div>
                <span>Power</span>
                <strong>{avatar.power}</strong>
              </div>
            </div>
            <div>
              <Recovery />
              <div>
                <span>Recovery</span>
                <strong>{avatar.recovery}</strong>
              </div>
            </div>
            <div id="armorDiv">
              <Armor style={{ fontSize: "1.8em" }} />
              <div>
                <span>Armor</span>
                <strong>{avatar.armor}</strong>
              </div>
            </div>
            {/* Late Game only (After Level 100) */}
            {
              avatar.aether ? 
              <div id="aetherDiv">
                <Aether style={{ fontSize: "2.2em" }} />
                <div>
                  <span>Aether</span>
                  <strong>{avatar.aether}</strong>
                </div>
              </div>
              : null
            }
          </div>
          <div id="avatarElementalAttributes" className="elemental-attributes">
            <div>
              <Fire />
              <div>
                <span>Fire</span>
                <strong>{Math.floor(avatar.fire)}</strong>
              </div>
            </div>
            <div>
              <Earth />
              <div>
                <span>Earth</span>
                <strong>{Math.floor(avatar.earth)}</strong>
              </div>
            </div>
            <div>
              <Water />
              <div>
                <span>Water</span>
                <strong>{Math.floor(avatar.water)}</strong>
              </div>
            </div>
            <div>
              <Air />
              <div>
                <span>Air</span>
                <strong>{Math.floor(avatar.air)}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateAccount
        modalOpen={createAccountModalOpen}
        setModalOpen={setCreateAccountModalOpen}
        handleModalAction={handleOpenRegister}
      />
      <ConnectStrava
        modalOpen={connectStravaModalOpen}
        setModalOpen={setConnectStravaModalOpen}
        handleModalAction={() => handleConnectStrava()}
      />
      <UpgradeHero
        modalOpen={upgradeHeroModalOpen}
        setModalOpen={setUpgradeHeroModalOpen}
        handleModalAction={() => handleUpgradeClick()}
        avatarName={avatar.name}
        isLoading={modalIsLoading}
      />
      <NewLevelReached
        modalOpen={newLevelReachedModalOpen}
        setModalOpen={setNewLevelReachedModalOpen}
        handleModalAction={() => handleOpenQP()}
        avatarName={avatar.name}
        avatarLevel={avatar.level}
        albedoLevel={avatar.albedo}
      />
      <GoToBattle
        modalOpen={goToBattleModalOpen}
        setModalOpen={setGoToBattleModalOpen}
        handleModalAction={handleGoToBattle}
        avatarName={avatar.name}
        avatarRecovery={avatar.healthRegenRate}
        avatarRested={avatar.restedEnough}
        avatarRestedTarget={Math.ceil(avatar.maxHealth * .8)}
        isLoading={modalIsLoading}
        verifiedAccount={admin.active}
      />
      <AwaitingBattle
        modalOpen={awaitingBattleModalOpen}
        setModalOpen={setAwaitingBattleModalOpen}
        // If an instant battle ran, openBattleReport will be true
        handleModalAction={(openBattleReport) => {
          setAwaitingBattleModalOpen(false);
          setRunBattleNow(false);
          if(openBattleReport){
            setTimeout(() =>{
              return setBattleReportModalOpen(true);
            }, 500);
          }
        }}
        avatar={avatar}
        upcomingFoe={upcomingFoe}
        upcomingRewards={upcomingRewards}
        handleItemClick={handleItemClick}
        runBattleNow={runBattleNow}
        updateState={ runBattleNow ? updateState : null }
        equipItemsOnAvatar={equipItemsOnAvatar}
      />
      <TheHerosPath
        modalOpen={herosPathModalOpen}
        setModalOpen={setHerosPathModalOpen}
        avatarName={avatar.name}
      />
      <SpendQP
        modalOpen={spendQPModalOpen}
        setModalOpen={setSpendQPModalOpen}
        avatar={avatar}
        handleStatChange={handleStatChange}
        goToItems={() => {
          setSpendQPModalOpen(false);
        }}
      />

      {
        activeItem ? 
          <ItemDetail modalOpen={itemModalOpen} setModalOpen={setItemModalOpen} item={activeItem} avatar={avatar} />
        : null
      }


      {renderBattleReportModals()}

      <Feedback
        modalOpen={feedbackModalOpen}
        setModalOpen={setFeedbackModalOpen}
        handleModalAction={submitFeedbackData}
      />
      <FeaturePreference
        modalOpen={featurePreferenceModalOpen}
        setModalOpen={setFeaturePreferenceModalOpen}
        handleModalAction={submitFeaturePreferenceData}
      />
      {
        //Only load the modal if there are new patchDetails and the user hasn't seen the latest patch
        patchDetails && !admin.seenLatestPatch ? 
        <NewPatch
          modalOpen={newPatchModalOpen}
          setModalOpen={setNewPatchModalOpen}
          patchDetails={patchDetails}
          skipRefresh={avatar.status === 'New Recruit'}
        /> : null
      }

      {/* Authentication Modals */}
      <Login
        modalOpen={LoginOpen}
        setModalOpen={setLoginOpen}
        toggleLoginRegister={() => toggleLoginRegister('Register')}
        toggleLoginForgotPassword={() => toggleLoginForgotPassword('Forgot Password')}
        updateState={updateState}
        signupEmail={admin.email}
      />
      <Register
        modalOpen={RegisterOpen}
        setModalOpen={setRegisterOpen}
        toggleLoginRegister={() => toggleLoginRegister('Login')}
        updateState={updateState}
        handlePostRegister={handlePostRegister}
      />
      {/* <ForgotPassword
        modalOpen={ForgotPasswordOpen}
        setModalOpen={setForgotPasswordOpen}
        toggleLoginForgotPassword={() => toggleLoginForgotPassword('Login')}
        updateState={updateState}
      /> */}
    </div>
  );
};

export default HomePage;

HomePage.propTypes = {
  avatar: PropTypes.shape({
    character: PropTypes.string.isRequired,
    air: PropTypes.number.isRequired,
    earth: PropTypes.number.isRequired,
    water: PropTypes.number.isRequired,
    fire: PropTypes.number.isRequired,
    health: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    power: PropTypes.number.isRequired,
    equipped: PropTypes.arrayOf(PropTypes.object).isRequired,
  }),
  admin: PropTypes.shape({
    clientId: PropTypes.string,
    stravaRefreshToken: PropTypes.string,
  }).isRequired,
  buffAvatar: PropTypes.func.isRequired,
  upgradeAvailable: PropTypes.bool.isRequired,
  updateState: PropTypes.func.isRequired,
  MenuButton: PropTypes.object,
  returnedFromStrava: PropTypes.bool,
  defaultItems : PropTypes.array.isRequired,
  handlePostRegister : PropTypes.func.isRequired,
  getStravaClientCredentials : PropTypes.func.isRequired,
  equipItemsOnAvatar : PropTypes.func.isRequired
};
