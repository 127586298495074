import React, { useState, useEffect } from 'react';
// LIBRARIES
import PropTypes from "prop-types";
// COMMON
import { lowercaseUnderscore, determineSkinType, determineSkinName, thousandsFormat } from '../../common/helperFunctions';
import HEROES from '../../common/includedHeroImages';
import VILLAINS from '../../common/includedVillainImages';
import ITEMS from '../../common/includedItemImages';
// COMPONENTS
import Header from '../Header/Header';
import dynamicComponent from '../../common/dynamicComponent';
import ItemDetail from "../Dialog/Modals/ItemDetail";
import './ItemsPage.scss';


const ItemsPage = function({ MainMenuButton, defaultItems, avatarCharacter = "Empath Aurelia" }){
  const [activeItem, setActiveItem] = useState(null);
  const [itemModalOpen, setItemModalOpen] = useState(false);
    
  // On click of item, close open modal then load new item into item modal
  function handleItemClick(item){
    if(item.type === 'codex'){
      return;
    }
    setActiveItem(item);
    setItemModalOpen(true);
  }
   

  function determineImage(item){

    if(item.type === 'skin'){
      return HEROES[determineSkinType(item)][determineSkinName(item, avatarCharacter)];
    }else if(item.type === 'title'){
  
    }else{
      return ITEMS[item.type+'s'][lowercaseUnderscore(item.name)] || ITEMS[item.type][lowercaseUnderscore(item.name)];
    }
    return null;
  }

  function formatRows(categoryRows){
    if(!defaultItems.length){
      return;
    }
    const rows = categoryRows.map((item, i) =>{
      return (
        <tr key={i} onClick={() => handleItemClick(item)}>
          <td className="item-image">
            {
              item.icon ?
              dynamicComponent({type : item.type, name : item.name, style: { fontSize: "75px" }, cssClass: item.class})
              : <img id="itemImage" className={`${item.class}` } src={determineImage(item)} alt="Item Details" />
            }
          </td>
          <td><h3>{item.name}</h3></td>
          <td>{item.description}</td>
          <td>{item.levelRestriction || "n/a"}</td>
          <td><strong className="brand-highlight">{thousandsFormat(item.ptCost) || "n/a"}</strong></td>
          <td>
            {item.dropRate ? `${item.dropRate}%` : "n/a"}
            
            { item.dropRate && item.activityRestriction ? <span><hr />{item.activityRestriction?.join()}</span> : null }
          </td>
          <td className="item-lore">{<p className="light-link-text">{item.lore}</p> || ""}</td>
        </tr>
      )
    });
    return <tbody id={`category-${categoryRows[0].type}`}>
      <tr key={categoryRows[0].type} className="divider-row">
        <td colSpan={7}>
          <h2>
            { categoryRows[0].type === 'skin' ? 'COSTUMES' : categoryRows[0].type === 'codex' ? 'Codex' : categoryRows[0].type.toUpperCase() + 'S' }
          </h2>
        </td>
      </tr>
      { rows }
    </tbody>
  }

  
  function renderTableBodies(){
    const itemsInCategories = []
    itemsInCategories.push(defaultItems.filter(item => item.type === 'pet').sort((a, b) => a.createdAt < b.createdAt ? 1 : -1));
    itemsInCategories.push(defaultItems.filter(item => item.type === 'consumable').sort((a, b) => a.createdAt < b.createdAt  ? 1 : -1));
    itemsInCategories.push(defaultItems.filter(item => item.type === 'skin').sort((a, b) => a.createdAt < b.createdAt  ? 1 : -1));
    itemsInCategories.push(defaultItems.filter(item => item.type === 'title').sort((a, b) => a.createdAt < b.createdAt  ? 1 : -1));
    itemsInCategories.push(defaultItems.filter(item => item.type === 'codex').sort((a, b) => a.createdAt < b.createdAt  ? 1 : -1));
    return itemsInCategories.map((category) => formatRows(category));
  }
  
  return(
    <section id="itemsPage" className="page">
      <Header title={`Items`} subtitle={"All Items in HeroFit"} MainMenuButton={MainMenuButton} />
      <div id="tableContainer">
        <table className="table table-striped">
          <thead>
            <tr>
              <th colSpan={2}>Item</th>
              <th>Description</th>
              <th>Level Restriction</th>
              <th className="brand-highlight">PT Cost</th>
              <th>
                Drop Rate % per 60XP<hr />
                Activity Restrictions
              </th>
              <th>Lore</th>
            </tr>
          </thead>
          { renderTableBodies() }
        </table>
      </div>
      {
        activeItem ? 
          <ItemDetail modalOpen={itemModalOpen} setModalOpen={setItemModalOpen} item={activeItem} avatar={{character : avatarCharacter}} />
        : null
      }
    </section>
  )
};

export default ItemsPage;

ItemsPage.propTypes = {
  avatarCharacter : PropTypes.string,
  defaultItems : PropTypes.array.isRequired,
};