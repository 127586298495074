import React from 'react';
// COMPONENTS
import { Link } from 'react-router-dom';
// STYLES
import './InfoAboutBattles.scss';

const InfoAboutBattles = function(){
  return(
    <div className="info-about-battles">
      <h2>HeroFit Daily Battles</h2>
      <div className="about-row">
        <p>As you know, you're training to make your hero more powerful. Your hero will need their powers for the battles with the dark forces that have escaped from their Netherworld prison. A list of these villains can be found <Link to="/campaign">here</Link>. Each day, your hero can choose to go into battle that night, and will then be randomly matched against one of the villains. You must be at or over 80% of your total health to enter the battle queue for the next battle session, happening around 2 AM MST (for Europeans, that's 10 am CET). What happened in your battle can be reviewed the next day via the battle report, which will stay visible until viewed as a newspaper icon in the bottom right of the home screen. Note: If you have gone into battle, but do not see the battle report button, make sure you're on the latest release of the game by clearing out your browser's cache and logging back in.</p>
        <em>Note: If you have gone into battle, but do not see the battle report button, make sure you're on the latest release of the game by clearing out your browser's cache and logging back in.</em>
      </div>
      <div className="about-row">
        <p>Skins, titles, and pets, can be earned by doing battles. Defeating every phantom will give you the phantom skin, every spectre gives the spectre skin etc. Winning X battles will grant titles, as will winning X battles in a row. </p>
        <p>Winning battles means looking at your stats in the battle report and fixing your weaknesses; did you lose often because your armour is weak (i.e. you took a LOT of damage at once)? Did you lose because you have little power (i.e. you hardly made a dent in the foe's health)? You get general power through leveling up and activities give you extra elemental power.</p>
      </div>
      <div className="about-row">
        <p>The battle report is a summary of what transpired during the battle. When you click the battle report icon, you will first see the battle's outcome- did your hero thrive or were they mercilessly beat unconscious by the villain? Depending on that outcome, it also shows how much XP you gained from the battle:</p>
        <ul>
          <li>Hero wins: Starts with a base of 20xp, but depending on Hero level and the difficulty of the randomized foe, the amount will go up.</li>
          <li>Hero loss: XP earned is 25% of the win amount</li>
          <li>Draw: XP earned is 50% of the win amount</li>
          <li>Double KO: XP earned is 70% of the win amount</li>
        </ul>
        <strong>Note: In addition, certain foes will also grant bonus XP, such as the Shadow-Self boss.</strong><br />
        <strong>You can now view more details about the battle in the report.</strong>
      </div>
      <div className="about-row">
        <h3>Scenario</h3>
        <p>This tells you how the battle started and what result that had (Did the villain sneak up to your hero? Was your hero prepared and waiting?). Usually something like foe or hero has bonus health or power, armor is reduced, that sort of thing. To summarise, it influences hero or foe stats.</p>
      </div>
      <div className="about-row">
        <h3>Foe Battle Prep</h3>
        <p>What specialty does the foe have (e.g. power drain, armor trade) and what effect does it have on their and your stats.</p>
      </div>
      <div className="about-row">
        <h3>Base and battle-time stats</h3>
        <p>This section gives an overview of both your base stats (before the battle) and those after battle scenario/foe prep (with the aforementioned scenario and foe battle prep effects visible), and the base stats/battle stats of the foe.</p>
      </div>
      <div className="about-row">
        <h3>Round-by-Round</h3>
        <p>A battle can last up to 12 rounds, for 6 of those your hero will attack, and for 6 the villain will attack. Who gets to go first is either determined by the battle scenario (e.g. one sneaks up to the other) otherwise it's randomly decided. In each round, the attacker tries to inflict damage to the other, the defender. The round-by-round table shows for each round, who is the attacker, how much damage the attacker did and how much was prevented by the defender. To conclude each row, the defenders resulting health stat is shown. When the defender's health stat drops to or below 0, the battle is over and the winner is declared. Further rounds are not fought. Dealing damage There are two types of damage, physical (fists, swords, that sort of thing) and elemental (each hero is a master of the elements and can use their respective elemental powers). The turns will rotate between physical damage and elemental damage phases, unless a foe is non-elemental, then each of their rounds are physical.</p>
        <em>Note: as of release 1.0, none of the villains have elemental powers yet.</em>
        <p>Physical damage rounds use 20% of the attacker's overall power stat, with a random variance of +/- 7.5%. So If you have 100 Power, you will do somewhere between 12 and 27 Damage, randomly for each Physical Damage Round.</p>
        <p>Elemental damage rounds use 30% of the attacker's elemental power stat, with a random variance of +/- 10%. So if you have 100 Earth, you will do somewhere between 20 and 40 Damage, randomly for each Elemental Damage Round. That damage is calculated FOR EACH element, and this is visible in the damage dealt column as [Fire] + [Earth] + [Water] + [Air]. There are extra calculations that alter elemental damage based on the defender's elemental stats, as defence against elemental power is dependent on the type of element - e.g. fire is weak towards earth, deals bonus damage to air & water, and is resistant against fire attacks. These effects can be found on the Quantum Points page at each element's stat.</p>
        <em>Note: The exact details of elemental damage will be tweaked in future releases.</em>
        <p>Armor / Elemental Resistance Armor and elemental resistance are always reduced to 1/3rd of the base stat, for each round.</p>
      </div>
      <div className="about-row">
        <h3>After The Battle</h3>
        <p>Recovery works as 1pt recovered per hour on top of the base of 5 (5 + RECOVERY_POINTS), but there's a secondary effect. If your status is KNOCKED OUT, points in recovery will also give you a chance to come out of the 'knocked out' status early. How it works is every hour, a script runs that will randomly revive you based on this formula: ((RECOVERY_POINTS/100)/2).</p>
      </div>
    </div>
  )
};

export default InfoAboutBattles;